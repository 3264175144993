import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import QRCode from "qrcode.react";
import Charttb from "./Charttb";
import Charttc from "./Charttc";
import Chartte from "./Chartte";
import Charttf from "./Charttf";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Updatedcss/UpdatedDashboardstyle.css";
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import { businessObjContext } from "../../context/businessObjContext";

function Dashbord() {
  const navigate = useNavigate();
  const [cardsData, setCardsData] = useState(null);
  const [planExpireDays, setPlanExpireDays] = useState(0);
  const [showPlanExpireBanner, setShowPlanExpireBanner] = useState(false);
  const { userData } = useContext(userContext);
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const httpProtocol = environmentVariables?.httpProtocol;
  const fronturl = environmentVariables?.frntUrl;
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  let busiObj = localStorage.getItem("businessObj");
  const [appointments, setAppointments] = useState([]);
  // console.log(appointments,"appointments!@#$")
  const [appData, setAppData] = useState([]);
  const [contData, setContData] = useState([]);
  const [chartsRs, setChartsRs] = useState([]);
  const [businessLink, setBusinessLink] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [sevdays, setSevdays] = useState({});
  const [busData, setBusData] = useState({});
  const [graphData, setGraphData] = useState({
    transformedData: [],
    gData2: [],
  });
  const [GreatingMsg, setGreatingMsg] = useState("");
  async function getGreatingMsg() {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/admin/setting/fetchConfig`,
        {
          params: {
            cnf_type: "Web",
            name: ["Greeting"],
          },
        }
      );
      if (response.status === 200) {
        setGreatingMsg(response?.data?.result?.Greeting);
      }
    } catch (error) {
      console.error({ "Error:": error, msg: "ErrorLanding" });
    }
  }
  const baseUrl = environmentVariables?.apiUrl;
  const getGraphData = () => {
    axios
      .get(`${baseUrl}api/v1/business/fetchGraphData`, {
        params: { UserId: userData?.id },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          // console.log(res?.data,"res?.datares?.data")
          setSevdays(res?.data);
          setBusData(res?.data?.totalBusiness?.rs)
          if (res?.data?.resultA?.sum_of_appointments) {
            setAppData(res.data.resultA.sum_of_appointments);
          }
          if (res?.data?.resultC?.sum_of_contacts) {
            setContData(res.data.resultC.sum_of_contacts);
          }
          if (res?.data?.resultViewr) {
            setChartsRs(res.data?.resultViewr);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };

  let data1 = graphData?.transformedData;
  let data2 = [["Language", "Speakers (in millions)"]];
  data2.push(...graphData.gData2);
  const setBusinessDataLink = (buzzData)=>{
    const businessData = JSON.parse(buzzData);
    let redirectUrl = (businessData?.slug_subdomain != null)? httpProtocol + businessData?.slug_subdomain:(businessData?.slug !=null)? fronturl+ businessData?.slug:"/";
    setBusinessLink(`${redirectUrl}`);
    setBusinessName(businessData?.title);
  }
  useEffect(() => {
    getGraphData();
    getGreatingMsg();
    if (storedData != "undefined" && storedData != null) {
      setBusinessDataLink(storedData);
    }else{      
      setBusinessDataLink(busiObj);
    }
  }, [storedData, busiObj]);

  const handleCopyClick = () => {
    const linkToCopy = window.location.href;
    navigator.clipboard
      .writeText(businessLink)
      .then((res) => {
        // setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info("Link Copy on Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const apiUrl = environmentVariables?.apiUrl; 

  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/user/getDashboardData`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCardsData(response?.data?.data);
      })
      .catch((error) => {
        if (error?.response?.status) {
          localStorage.clear();
          window.location.href = "/";
          window.location.reload();
        }
      });
  }, []);

  const fetchApppointent = (page=1) => {
    // console.log("in fetch appoitment")
    try {
      let obj = {
        business_id: businessObjData?.id,
      };
      axios
        .post(`${baseUrl}api/v1/appointment/FetchAppointments_withpagination?page=${page}&limit=${rowsPerPage}&showcount=${userData?.appointments}`,{ ...obj}, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          let arr = response?.data?.data?.records;
          arr = arr.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );
          // console.log(arr,"@#$arr")
          setAppointments(arr);
        })
        .catch((error) => {
        });
    } catch (err) {
      console.log(err, "erroror");
    }
  };


  useEffect(() => {
    fetchApppointent();
  }, [rowsPerPage]);


  // const CalendarComponent = ({ appointments }) => {
    // Transform appointment data into the format expected by FullCalendar
    const events = appointments.map(appointment => ({
      title: appointment.businessTitle, // Use businessTitle as the event title
      start: appointment.date, // Use date as the event start time
      // You can optionally include other properties such as 'end' here if needed
      extendedProps: {
        // Pass the entire appointment object as extendedProps
        appointment,
      },
  
    }));

    const eventContent = ({ event }) => {
      const { businessTitle, time, name, } = event.extendedProps.appointment;
      return (
        <div style={{padding:"5px 0"}}>
          <p style={{fontSize:"14px", fontWeight:"bold"}}>{businessTitle}</p>
            <p style={{fontSize:"13px"}}> {time}</p>
            <p style={{fontSize:"13px"}}>{name}</p>
        </div>
      );
    };
  
    const downloadQR = () => {
      const canvas = document.getElementById("businessLinkQr");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = businessName + ".png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    useEffect(() => {
      getExpirationDetails();
    }, []);

    const getExpirationDetails = ()=>{
      let config = {
        method: "get",
        url: `${apiUrl}api/v1/user/getPlanExpirationDetails`,
        headers: {
          _token: userData?.token,
        },
      };
  
      axios
        .request(config)
        .then((response) => {
          if(response?.data?.success){
            setPlanExpireDays(response?.data?.planExpiryDays);
            setShowPlanExpireBanner(response?.data?.allowToShowExpiryBanner);
          }
        })
        .catch((error) => {
          console.log("error====>>>", error.message);
          
        });
    }
    const handlePlanClick = ()=>{
      navigate('/plans');
    }

    const renderEnquiryComponent = () => {
      if (!busData || !Array.isArray(busData) || busData.length === 0) {
        return null; // Return nothing if busData is null, undefined, or not an array
      }
    
      const firstMatchingBusiness = busData.find((business) => 
        business.theme_color === 'theme14' || 
        business.theme_color === 'theme20' || 
        business.theme_color === 'theme22'
      );
    
      if (!firstMatchingBusiness) return null;
    
      switch (firstMatchingBusiness.theme_color) {
        case 'theme14':
          return (
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Restaurant Enquiry</h3>
                <h4>{sevdays?.totalReastaurantEnquiry || 0}</h4>
              </div>
            </div>
          );
    
        case 'theme20':
          return (
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Mini Marketplace Enquiry</h3>
                <h4>{sevdays?.totalMiniMarketplaceEnquiry || 0}</h4>
              </div>
            </div>
          );
    
        case 'theme22':
          return (
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Cab Enquiry</h3>
                <h4>{sevdays?.totalCabEnquiry || 0}</h4>
              </div>
            </div>
          );
    
        default:
          return null;
      }
    };
    


  return (
    <>
      <PageName PageName="Dashboard" />
      {!!showPlanExpireBanner &&
        <div className="row">
          <div className="col-lg-12">
            <div className="planexpiry-banner">
              {planExpireDays > 1 ?
              <p>{`Your plan will expire in ${planExpireDays}${(planExpireDays>1)?'days':'day'}. Please renew it.`}</p>
              : (planExpireDays == 1)?<p>{`Your plan will expire today. Please renew it.`}</p>:<p>{`Your plan has been expired. Please renew it.`}</p>}
                <div className="expirebtn cursor-pointer" onClick={handlePlanClick}>Upgrade plan</div>
            </div>
          </div>
        </div>
      }
      <div className="row Greeting_total_wrapper">
        <div className="col-md-5" id="grettingWrapper">
          <div className="tile  welcom ">
            <div>
              <h6>
                <b>{userData?.name}</b>
              </h6>

              <p>{GreatingMsg}</p>
              {/* <div style={{marginTop:"10px" ,fontSize:"16px", cursor:"pointer"}}>
                <a href="https://www.youtube.com/watch?v=sg_ZoHZ-hEk" target="_blank">How it works <i class="fa-solid fa-up-right-from-square"></i></a>
                </div> */}
                <div  onClick={() => navigate("/faqs")} style={{marginTop:"10px" ,fontSize:"16px", cursor:"pointer", color:"#413e3c"}}>
                How it works <i class="fa-solid fa-up-right-from-square"></i>
                </div>
              {businessLink?.includes("undefined") ? (
                ""
              ) : (
                <button className="copybtn" onClick={handleCopyClick}>
                  Business Link <i className="fa-solid fa-copy"></i>
                </button>
              )}
            </div>
            <div className="QRcode_wrapper">
              {businessLink?.includes("undefined")?  
            "": 
              <> 
                <QRCode
                value={businessLink}
                bgColor={"#ffffff"}
                fgColor={"#372f2f"}
                includeMargin={true}
                level={"M"}
                style={{
                  height: "65px",
                  width: "66px",
                  margin: "auto",
                  display: "block",
                }}
                id="businessLinkQr"
                />
                <a onClick={downloadQR} className="download-qr-code"> Download QR </a>
              </>
            }
            </div>
          </div>
        </div>
        <div className="col-md-7" id="total_wrapper_container">
          <div className="row">
            <div className="col-md-4">
              <div className="tile bbox Total_Titlebox_container">
                <i className="fa-solid fa-business-time"></i>
                <h3>Total Business</h3>
                <h4>{sevdays?.theBusinesses?.[0]?.total_count || 0}</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tile bbox Total_Titlebox_container">
                <i className=" fa-regular fa-handshake"></i>
                <h3>Total Appointments</h3>
                <h4>{sevdays?.resultA?.total || 0}</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Staff</h3>
                <h4>{sevdays?.totalStaff?.total || 0}</h4>
              </div>
            </div>
            {sevdays?.totalEnquiries>0 &&
              <div className="col-md-4">
                <div className="tile bbox ">
                  <i className="fa-solid fa-users"></i>
                  <h3>Total Enquiry</h3>
                  <h4>{sevdays?.totalEnquiries || 0}</h4>
                </div>
              </div>
            }
            {sevdays?.totalMarketplaceEnquiry > 0 &&
            <div className="col-md-4">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Marketplace Enquiry</h3>
                <h4>{sevdays?.totalMarketplaceEnquiry || 0}</h4>
              </div>
            </div>
            }
    {renderEnquiryComponent()}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="Dashboard_appointment_visitors_wrapper">
          <div className="col-12 col-lg-6 Appointment_wrapper">
            <div className="tile border-left">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5>Appointments </h5>
                <span>Last 7 Days</span>
              </div>
              {sevdays &&
              typeof sevdays === "object" &&
              Object.keys(sevdays).length > 0 ? (
                <Charttc data={sevdays} />
              ) : null}
            </div>
          </div>

        <div className="col-12 col-lg-6 Visitors_wrapper">
          <div className="tile border-left">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Visitor </h5>
              <span></span>
            </div>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Charttb data={sevdays} />
            ) : null}
          </div>
        </div>
      </div>

      <div className="physicalcard_recentbusiness_wrapper">
        <div className="col-12 col-lg-8 recentbusines_card_wrapper">
          <div className="tile border-left ">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5>Recent Business </h5>
              <span></span>
              <div className="contact_filter_buttonwrapper">
                <button
                  className="contact_filter_buttonstyle"
                  onClick={() => navigate("/businesslist")}
                >
                  View all
                </button>
              </div>
            </div>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Charttf data={sevdays} />
            ) : null}
          </div>
        </div>

        <div className="col-12 col-lg-4 kheight physical_card_wrapperAdmin">
          <div className="tile border-left ">
            <h5>Physical Card </h5>
            {sevdays &&
            typeof sevdays === "object" &&
            Object.keys(sevdays).length > 0 ? (
              <Chartte data={sevdays} />
            ) : null}
          </div>
        </div>
        </div>

        <div className="col-md-12">

        {/* <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
         events={events}
         eventContent={eventContent}
      />
    </div> */}
    
    </div>
      </div>
    </>
  );
}
export default Dashbord;
//1112