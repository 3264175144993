import React from "react";
function SubGoogleReview(props) {
  let rs = props?.rs;
  let ff = rs?.googlereview;
  let totlreview = rs?.review

  return (
    <>
      <article class="abouttemplate sec">
        <h3 class="sectitle" style={{color:"#9E621C", fontWeight:"500"}}>Google Review</h3>
        {/* <p style={{ fontFamily: `"Red Hat Display", sans-serif` ,color:"#000",fontWeight:"400", fontSize:"13px", textAlign:"center"}}>Rating {rs?.rating} <span style={{margin:"0 1px"}}>|</span> {totlreview } Review</p> */}
        <ul class="conlink">
          <li>
          <a href={`https://www.google.com/search?q=${ff}`} target="_blank" rel="noopener noreferrer">
                    <button 
                    style={{
                      background: "#FFA438",
                      color: "#fff",
                      width: "100%",
                      fontSize: "16px",
                      padding: "12px 0",
                      border: "none",
                      fontWeight:"bold",
                      borderRadius: "6px",
                      }}
                    >Review us on Google</button>
                  </a>
          </li>
        </ul>
      </article>
    </>
  );
}
export default SubGoogleReview;
