import React from "react";
function SubGoogleReview(props) {
  let rs = props?.rs;
  let ff = rs?.googlereview;
  let totlreview = rs?.review
  
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Google Review</h3>
        {/* <p style={{ fontFamily: `"Red Hat Display", sans-serif` ,color:"#D9D9D9",textAlign:"center",fontWeight:"400", fontSize:"13px"}}>Rating {rs?.rating} <span style={{margin:"0 1px"}}>|</span> {totlreview } Review</p> */}
        <ul class="conlink">
          <li>
          <a href={`https://www.google.com/search?q=${ff}`} target="_blank" rel="noopener noreferrer">
                    <button 
                    style={{
                      // background: "linear-gradient(to bottom, #9365ff, #529bff)",
                      background:"#1970D1",
                      color: "#fff",
                      width: "100%",
                      fontSize: "16px",
                      padding: "0px 0",
                      margin: "10px 0",
                      border: "none",
                      borderRadius: "6px",
                      }}
                    >Review us on Google</button>
                  </a>
          </li>
        </ul>
      </article>
    </>
  );
}
export default SubGoogleReview;
