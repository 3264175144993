import React, { useContext } from "react";
import { useState } from "react";
import Template1 from "../../Templates/Template1";
import { environmentVariables } from "../../../config/env.config";
import { toast } from "react-toastify";
import plinkimg from "../../../Images/linkqr.png";
import Theme1 from "../../Templates/themes/theme1/Theme1";
import Theme2 from "../../Templates/themes/theme2/Theme2";
import Theme3 from "../../Templates/themes/theme3/Theme3";
import Theme4 from "../../Templates/themes/theme4/Theme4";
import Theme5 from "../../Templates/themes/theme5/Theme5";
import Theme6 from "../../Templates/themes/theme6/Theme6";
import Theme7 from "../../Templates/themes/theme7/Theme7";
import Theme11 from "../../Templates/themes/theme11/Theme11";
import Theme12 from "../../Templates/themes/theme12/Theme12";
import Theme16 from "../../Templates/themes/theme16/Theme16";
import Theme17 from "../../Templates/themes/theme17/Theme17";
import Theme15 from "../../Templates/themes/theme15/Theme15";

import Theme1Template from "../../Templates/themes/theme1/Theme1Template";
import Theme2Template from "../../Templates/themes/theme2/Theme2Template";
import Theme3Template from "../../Templates/themes/theme3/Theme3Template";
import Theme4Template from "../../Templates/themes/theme4/Theme4Template";
import Theme5Template from "../../Templates/themes/theme5/Theme5Template";
import Theme6Template from "../../Templates/themes/theme6/Theme6Template";
import Theme7Template from "../../Templates/themes/theme7/Theme7Template";
import Theme11Template from "../../Templates/themes/theme11/Theme11Template";
import Theme12Template from "../../Templates/themes/theme12/Theme12Template";
import Theme17Template from "../../Templates/themes/theme17/Theme17Template";
import Theme16Template from "../../Templates/themes/theme16/Theme16Template";
import Theme18Template from "../../Templates/themes/theme18/Theme18Template";
import Theme20Template from "../../Templates/themes/theme20/Theme20Template";
import Theme22Template from "../../Templates/themes/theme22/Theme22Template";
import Theme15Template from "../../Templates/themes/theme15/Theme15Template";
import Theme27Template from "../../Templates/themes/theme27/Theme27Template";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import { useNavigate } from "react-router";
import { userContext } from "../../../context/userContext";
import axios from "axios";
import "./MobileStyle.css";
import Theme8 from "../../Templates/themes/theme8/Theme8";
import Theme8Template from "../../Templates/themes/theme8/Theme8Template";
import Theme9Template from "../../Templates/themes/theme9/Theme9Template";
import Theme9 from "../../Templates/themes/theme9/Theme9";
import Theme10Template from "../../Templates/themes/theme10/Theme10Template";
import Theme10 from "../../Templates/themes/theme10/Theme10";
import Theme14Template from "../../Templates/themes/theme14/Theme14Template";
import Theme14 from "../../Templates/themes/theme14/Theme14";
import Theme18 from "../../Templates/themes/theme18/Theme18";
import Theme20 from "../../Templates/themes/theme20/Theme20";
import Theme22 from "../../Templates/themes/theme22/Theme22";
import Theme27 from "../../Templates/themes/theme27/Theme27";
import Theme28 from "../../Templates/themes/theme28/Theme28";
import Theme28Template from "../../Templates/themes/theme28/Theme28Template";
import Theme29 from "../../Templates/themes/theme29/Theme29";
import Theme29Template from "../../Templates/themes/theme29/Theme29Template";
import Theme30 from "../../Templates/themes/theme30/Theme30";
import Theme30Template from "../../Templates/themes/theme30/Theme30Template";
import Theme31Template from "../../Templates/themes/theme31/Theme31Template";
import Theme31 from "../../Templates/themes/theme31/Theme31";
import Theme32 from "../../Templates/themes/theme32/Theme32";
import Theme32Template from "../../Templates/themes/theme32/Theme32Template";
import Theme33Template from "../../Templates/themes/theme33/Theme33Template";
import Theme33 from "../../Templates/themes/theme33/Theme33";
import Theme34Template from "../../Templates/themes/theme34/Theme34Template";
import Theme34 from "../../Templates/themes/theme34/Theme34";
import Theme35Template from "../../Templates/themes/theme35/Theme35Template";
import Theme35 from "../../Templates/themes/theme35/Theme35";
import Theme36Template from "../../Templates/themes/theme36/Theme36Template";
import Theme36 from "../../Templates/themes/theme36/Theme36";
import Theme37 from "../../Templates/themes/theme37/Theme37";
import Theme37Template from "../../Templates/themes/theme37/Theme37Template";
import Theme38Template from "../../Templates/themes/theme38/Theme38Template";
import Theme38 from "../../Templates/themes/theme38/Theme38";
import Theme39 from "../../Templates/themes/theme39/Theme39";
import Theme39Template from "../../Templates/themes/theme39/Theme39Template";
import Theme40 from "../../Templates/themes/theme40/Theme40";
import Theme40Template from "../../Templates/themes/theme40/Theme40Template";
import Theme41 from "../../Templates/themes/theme41/Theme41";
import Theme41Template from "../../Templates/themes/theme41/Theme41Template";
import Theme42 from "../../Templates/themes/theme42/Theme42";
import Theme42Template from "../../Templates/themes/theme42/Theme42Template";
import Theme43 from "../../Templates/themes/theme43/Theme43";
import Theme43Template from "../../Templates/themes/theme43/Theme43Template";
import Theme44 from "../../Templates/themes/theme44/Theme44";
import Theme44Template from "../../Templates/themes/theme44/Theme44Template";
import Theme45Template from "../../Templates/themes/theme45/Theme45Template";
import Theme45 from "../../Templates/themes/theme45/Theme45";
import Theme46Template from "../../Templates/themes/theme46/Theme46Template";
import Theme46 from "../../Templates/themes/theme46/Theme46";
import Theme47Template from "../../Templates/themes/theme47/Theme47Template";
import Theme48Template from "../../Templates/themes/theme48/Theme48Template";
import Theme49Template from "../../Templates/themes/theme49/Theme49Template";
import Theme50Template from "../../Templates/themes/theme50/Theme50Template";
import Theme51Template from "../../Templates/themes/theme51/Theme51Template";
import Theme47 from "../../Templates/themes/theme47/Theme47";
import Theme48 from "../../Templates/themes/theme48/Theme48";
import Theme49 from "../../Templates/themes/theme49/Theme49";
import Theme50 from "../../Templates/themes/theme50/Theme50";
import Theme51 from "../../Templates/themes/theme51/Theme51";
import Theme52 from "../../Templates/themes/theme52/Theme52";
import Theme52Template from "../../Templates/themes/theme52/Theme52Template";
import Theme53 from "../../Templates/themes/theme53/Theme53";
import Theme53Template from "../../Templates/themes/theme53/Theme53Template";

const ViewThem = (props) => {
  // console.log(props,"v^%YT")
  const { userData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const fronturl = environmentVariables?.frntUrl;
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [loadingSavePreview, setLoadingSavePreview] = useState(false);
  const [order, setOrder] = useState(null);
  const [businessId, setBusinessId] = useState(
    localStorage.getItem("business_id")
  );
  const getTemplate = (theme) => {
    // const appointmentFilterArray = props?.appointmentArray.filter(item =>
    //   item.startDate !== '' && item.startDate !== null &&
    //   item.endDate !== '' && item.endDate !== null
    // );
    switch (theme) {
      case "theme6":
        return (
          <Theme1
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props?.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme5":
        return (
          <Theme2
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props?.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme3":
        return (
          <Theme3
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme4":
        return (
          <Theme4
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme2":
        return (
          <Theme5
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props?.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme1":
        return (
          <Theme6
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme3":
        return (
          <Theme3
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme7":
        return (
          <Theme7
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme8":
        return (
          <Theme8
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme9":
        return (
          <Theme9
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme10":
        return (
          <Theme10
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme11":
        return (
          <Theme11
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme12":
        return (
          <Theme12
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme15":
        return (
          <Theme15
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme14":
        return (
          <Theme14
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            isDine={props.isDine}
            isShowBusinessName={props.isShowBusinessName}
            isShowAddress={props?.isShowAddress}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme16":
        return (
          <Theme16
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme17":
        return (
          <Theme17
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme18":
        return (
          <Theme18
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme20":
        return (
          <Theme20
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            minimarketArray={props?.minimarketArray}
            review={props.reviews}
            rating={props.rating}
            isShowBusinessName={props.isShowBusinessName}
            isShowAddress={props?.isShowAddress}
          />
        );
      case "theme22":
        return (
          <Theme22
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            termsConditionData={props.termsConditionData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            cabArray={props?.cabArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
            isShowBusinessName={props.isShowBusinessName}
          />
        );
      case "theme27":
        return (
          <Theme27
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme28":
        return (
          <Theme28
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme29":
        return (
          <Theme29
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme30":
        return (
          <Theme30
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme31":
        return (
          <Theme31
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme32":
        return (
          <Theme32
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme33":
        return (
          <Theme33
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme34":
        return (
          <Theme34
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme35":
        return (
          <Theme35
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme36":
        return (
          <Theme36
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme37":
        return (
          <Theme37
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme38":
        return (
          <Theme38
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme39":
        return (
          <Theme39
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme40":
        return (
          <Theme40
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme41":
        return (
          <Theme41
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme42":
        return (
          <Theme42
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme43":
        return (
          <Theme43
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme44":
        return (
          <Theme44
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme45":
        return (
          <Theme45
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme46":
        return (
          <Theme46
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme47":
        return (
          <Theme47
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme48":
        return (
          <Theme48
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme49":
        return (
          <Theme49
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme50":
        return (
          <Theme50
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme51":
        return (
          <Theme51
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme52":
        return (
          <Theme52
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme53":
        return (
          <Theme53
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            minimarketArray={props?.minimarketArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      default:
        return null;
    }
  };
  const getStaticTemplate = (theme) => {
    switch (theme) {
      case "theme6":
        return <Theme1Template />;
      case "theme5":
        return <Theme2Template />;
      case "theme3":
        return <Theme3Template />;
      case "theme4":
        return <Theme4Template />;
      case "theme2":
        return <Theme5Template />;
      case "theme1":
        return <Theme6Template />;
      case "theme7":
        return <Theme7Template />;
      case "theme8":
        return <Theme8Template />;
      case "theme9":
        return <Theme9Template />;
      case "theme10":
        return <Theme10Template />;
      case "theme11":
        return <Theme11Template />;
      case "theme15":
        return <Theme15Template />;
      case "theme14":
        return <Theme14Template />;
      case "theme12":
        return <Theme12Template />;
      case "theme16":
        return <Theme16Template />;
      case "theme17":
        return <Theme17Template />;
      case "theme18":
        return <Theme18Template />;
      case "theme20":
        return <Theme20Template />;
      case "theme22":
        return <Theme22Template />;
      case "theme27":
        return <Theme27Template />;
      case "theme28":
        return <Theme28Template />;
      case "theme29":
        return <Theme29Template />;
      case "theme30":
        return <Theme30Template />;
      case "theme31":
        return <Theme31Template />;
      case "theme32":
        return <Theme32Template />;
      case "theme33":
        return <Theme33Template />;
      case "theme34":
        return <Theme34Template />;
      case "theme35":
        return <Theme35Template />;
      case "theme36":
        return <Theme36Template />;
      case "theme37":
        return <Theme37Template />;
      case "theme38":
        return <Theme38Template />;
      case "theme39":
        return <Theme39Template />;
      case "theme40":
        return <Theme40Template />;
      case "theme41":
        return <Theme41Template />;
      case "theme42":
        return <Theme42Template />;
      case "theme43":
        return <Theme43Template />;
      case "theme44":
        return <Theme44Template />;
      case "theme45":
        return <Theme45Template />;
      case "theme46":
        return <Theme46Template />;
      case "theme47":
        return <Theme47Template />;
      case "theme48":
        return <Theme48Template />;
      case "theme49":
        return <Theme49Template />;
      case "theme50":
        return <Theme50Template />;
      case "theme51":
        return <Theme51Template />;
      case "theme52":
        return <Theme52Template />;
      case "theme53":
        return <Theme53Template />;
      default:
        return null;
    }
  };
  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  // console.log(props.themeColor, "props of details");
  const changeTimeFormat = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(
        `${fronturl}${props?.businessObj?.slug ? props?.businessObj?.slug : ""}`
      )
      .then(() => {
        setCopied(true);
        toast.success("link copied", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    toast.success("link copied", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const httpProtocol = environmentVariables?.httpProtocol;
  const handleUpdateBusiness = (e) => {
    setLoadingSavePreview(true);
    if (props.selectedTheme) {
      e.stopPropagation();
      let obj = {
        theme: props?.selectedTheme,
        theme_color: props?.selectedTheme,
        businessId: props?.businessObj?.id,
        business_category: props?.seprationCategory,
      };
      axios
        .post(`${baseUrl}api/v1/business/edit`, obj, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?.success) {
            let businessDataBE = res.data.data;
            let redirectUrl =
              businessDataBE?.slug_subdomain != null
                ? httpProtocol + businessDataBE?.slug_subdomain
                : props?.businessObj?.slug
                ? "/" + props?.businessObj?.slug
                : "/";

            window.open(`${redirectUrl}`, "_blank");
            setLoadingSavePreview(false);
            props.setThemeColor(selectedTheme);
            // navigate("/dashboard");
          }
        })
        .catch((err) => {
          setLoadingSavePreview(false);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    } else if (props.activeTab == "tab2") {
      props.handleSubmit(e);
      setLoadingSavePreview(false);
    } else if (props.activeTab == "tab3") {
      props.handleSubmit(e);
      setLoadingSavePreview(false);
    } else {
      window.open(
        `/${props?.businessObj?.slug ? props?.businessObj?.slug : ""}`,
        "_blank"
      );
    }
  };

  return (
    <>
      <div className="col-12 col-lg-4">
        <div className="tile-aaa">
          {!!props.themeColor ? (
            <div className="privewmobile">
              <div className="viewlink border-bottom d-none">
                <div className="linkl">
                  <p>{`${fronturl}${
                    props?.businessObj?.slug ? props?.businessObj?.slug : ""
                  }`}</p>
                </div>
                <div className="linkr">
                  <ul className="linkandcopy">
                    <li onClick={handleCopyClick}>
                      <i class="fa-solid fa-copy"></i>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href={`${fronturl}${
                          props?.businessObj?.slug
                            ? props?.businessObj?.slug
                            : ""
                        }`}
                      >
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="viewlink  preview_nav d-none">
                <div className="linkl">
                  <ul className="btntabhere">
                    <li
                      onClick={() => handleTabChange(1)}
                      className={activeTab === 1 ? "active" : ""}
                    >
                      <i class="fa-solid fa-eye"></i>
                    </li>
                    <li
                      onClick={() => handleTabChange(2)}
                      className={activeTab === 2 ? "active" : ""}
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </li>
                  </ul>
                </div>
                <div className="linkr">
                  <button className="btn btn-save">Save</button>
                </div>
              </div>

              {activeTab === 1 &&
                (props.mobilePreviewLoading ? (
                  <CircularLoader size={40} />
                ) : (
                  <div className="movileview LL3007 MobileSaveandpreviewHeight">
                    <>
                      <div class="device-header"></div>
                      {/* <iframe
                  src="http://localhost:3000/template1"
                  width="100%"
                  height="600"
                  title="Example Iframe"
                /> */}
                      {props.activeTab == "tab1" &&
                      (props.isChecking == null ||
                        props.isChecking == undefined)
                        ? getStaticTemplate(props.themeColor)
                        : getTemplate(props.themeColor)}
                    </>
                  </div>
                ))}

              {activeTab === 2 && (
                <div>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/1200px-QR_Code_Example.svg.png"
                    className="mx-auto d-block img-fluid"
                  />
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="defaultthemetxt">
                To preview the theme, please select a theme.
              </div>
            </>
          )}

          {props.themeColor && (
            <>
              {props.activeTab == "tab1" &&
              (props.isChecking == null || props.isChecking == undefined) ? (
                <></>
              ) : (
                <>
                  {loadingSavePreview || props.isLoadingSubmit ? (
                    <CircularLoader />
                  ) : (
                    <>
                      {props.mobilePreviewLoading ? (
                        <></>
                      ) : (
                        <p className="linkcopunextwindiow">
                          <a
                            target="_blank"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                            onClick={(e) => {
                              handleUpdateBusiness(e);
                            }}
                          >
                            Save & Preview
                            <img style={{ padding: "0 10px" }} src={plinkimg} />
                          </a>
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {/* {(props?.isChecking != null || props?.isChecking != undefined) &&
              props?.activeTab != "tab1" && (
                <p className="linkcopunextwindiow">
                  <a
                    target="_blank"
                    href={`/${
                      props?.businessObj?.slug ? props?.businessObj?.slug : ""
                    }`}
                  >
                    Live Preview <img src={plinkimg} />
                  </a>
                </p>
              )} */}
        </div>
      </div>
    </>
  );
};
//112 16:28
export default ViewThem;
