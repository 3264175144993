import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Contact Info</h3>
        <ul class="conlink">
          {ff?.contactInfoPhone &&
            <li>
              <i class="fa-solid fa-phone"></i>
          
              <a href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}>
                  <span>{ff?.contactInfoPhone}</span>
                </a>
                {ff?.optionalContactInfoPhone?.length > 5 &&
                
                <a style={{display:"block", marginLeft:"40px"}}
                  href={ff?.optionalContactInfoPhone ? `tel:${ff?.optionalContactInfoPhone}` : ""} >
                  <span>{ff?.optionalContactInfoPhone || ``} </span>
                </a>
                }
             
            </li>
          }
          {ff?.contactInfoEmail &&
            <li>
              <a
              style={{display:"flex", alignItems:"center"}}
                href={
                  ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
                }
              >
                <i class="fa-solid fa-envelope"></i>
                <span className="mailurllong">{ff?.contactInfoEmail}</span>
              </a>
            </li>
          }
          {/* <li>
                      <a href="">
                        <span>
                         
                          {ff?.contactInfoWebUrl 
                            "Website Url"}
                        </span>
                      </a>
                    </li> */}
                    {ff?.contactInfoAddress && 
          <li>
            <a href="" style={{display:"flex", alignItems:"center"}}>
            <i style={{minWidth:"40px"}} class="fa-solid fa-location-dot"></i>
             
                <span> 
                  {ff?.contactInfoAddress?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              </a>
                <a>
                {ff?.contactInfoOptAddress && (
                <div style={{ paddingLeft: "40px", paddingTop: "5px" }}>
                  <p style={{ color: "#eadec9", fontSize: "14px" }}>Alternative Address</p>
                  <span style={{textAlign:"left"}} >
                    {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              )}
             
            </a>
          </li>
}
          {/* {ff?.contactInfoOptAddress &&
            <li>
              <a>
                <i class="fa-solid fa-location-dot"></i>
                <span>
                  {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              </a>
            </li>
          } */}
        
        {ff?.contactInfoWebUrl && 
          <li>
            {/* <a href={(ff?.contactInfoWebUrl)?`http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}`:''} target="_blank"> */}
            <a
            style={{display:"flex", alignItems:"center"}}
              href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!ff?.contactInfoWebUrl) {
                  e.preventDefault(); // Prevents the default link behavior
                }
              }}
              target={ff?.contactInfoWebUrl ? '_blank' : undefined}
              className="contactinfolink content"
            >
              <i class="fa-solid fa-link"></i>
              <span className="mailurllong">{ff?.contactInfoWebUrl}</span>
            </a>
          </li>
          }
        </ul>
      </article>
    </>
  );
}
export default SubContactInfo;
