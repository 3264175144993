import React from 'react'
import location from "./../../../../Images/cablocationicon.png";
import msg from "./../../../../Images/cabmailicon.png";
import web from "./../../../../Images/cabwebicon.png";
import phn from "./../../../../Images/cabphnicon.png";
import Insta from "./../../../../Images/CabInsta.png";
import Facebook from "./../../../../Images/CabFacebook.png";
import Twitter from "./../../../../Images/CabTwitter.png";
import Youtube from "./../../../../Images/Cabyoutube.png";
import Gmail from "./../../../../Images/CabGmail.png";
import LinkedIn from "./../../../../Images/CabLinkedIn.png";
import Pinterest from "./../../../../Images/CabPinterest.png";
import Telegram from "./../../../../Images/CabTelegram.png";
import Whatsapp from "./../../../../Images/CabWhatsapp.png";

const CabContactInfo = ({contdata,socialdata,socialactive}) => {
  let cnt = contdata


  const getSocialImage=(socialName)=>{
    
      if(socialName=="FaceBook"){
        
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Facebook} />
          </div>);
      }else if(socialName=="Twitter"){
        
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Twitter} />
          </div>);
      }else if(socialName=="Youtube"){
        
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Youtube} />
          </div>);
      }else if(socialName=="Gmail"){
       
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Gmail} />
          </div>);
      }else if(socialName=="Instagram"){
        
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Insta} />
          </div>);
      }else if(socialName=="whatsapp"){
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Whatsapp} />
          </div>);
      }else if(socialName=="linkedin"){
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={LinkedIn} />
          </div>);
      }else if(socialName=="telegram"){
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Telegram} />
          </div>);
      }else if(socialName=="pinterest"){
        return (<div className='px-3 pb-4'>
          <img className='w-fiftypx' src={Pinterest} />
          </div>);
      }
  }

let props = null
  
  let socialMediaData = socialdata;

  const getSocialIcon = (social) => {
    if (social == "gmail") {
      return "google";
    } else if (social == "facebook") {
      return "facebook-f";
    } else if (social == "twitter") {
      return "twitter";
    } else {
      return social;
    }
  };
  const getLink = (social, link) => {
    if (social == "whatsapp") {
      return `https://wa.me/${link}`;
    } else if (social == "gmail") {
      return `mailto:${link}`;
    } else if (social == "telegram") {
      return `https://t.me/${link}`;
    } else if (social == "pinterest") {
      return `https://in.pinterest.com/${link}`;
    } else {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "https://" + link;
      }
      link = link.replace("www.", "");
      return link;
    }
  };



  return (
  <div className='px-2'>
      <div>
           <div className='capitalize font-Poppins text-xl text-white font-medium pb-2'>
           Contact Info
           </div>

           {cnt?.contactInfoPhone && 
           <div className='flex items-center my-2' style={{flexDirection:"column"}}>
                <div className='z-0 p-2 rounded-2xl'>
                  <img className='z-10 w-thirtypx' src={phn}/>
                </div>
                <div className='mx-2 text-white font-DM font-medium'>
                  {/* {cnt?.contactInfoPhone} */}
                  <a
                  href={
                    cnt?.contactInfoPhone ? `tel:${cnt?.contactInfoPhone}` : ""
                  }
                >
                  <span style={{color: "white"}}>
                    {cnt?.contactInfoPhone || ``}
                  </span>
                </a>
                  </div>
                  {cnt?.optionalContactInfoPhone?.split("-")?.[1]?.length > 0  && 
                <div className='mx-2 text-white font-DM font-medium'>
                  {/* {cnt?.optionalContactInfoPhone} */}
                  <a
                  href={
                    cnt?.optionalContactInfoPhone ? `tel:${cnt?.optionalContactInfoPhone}` : ""
                  }
                >
                  <span style={{color: "white"}}>
                    {cnt?.optionalContactInfoPhone || ``}
                  </span>
                </a>
                </div>}
            </div>
          }

            {cnt?.contactInfoWebUrl && 
            <div className='flex items-center my-2' style={{flexDirection:"column"}}>
                <div className='z-0 p-2 rounded-2xl'>
                  <img className='z-10 w-thirtypx' src={web}/>
                </div>
                <div className='mx-2 text-white font-DM font-medium'>
                  {/* {cnt?.contactInfoWebUrl} */}
                  <a
              href={(cnt?.contactInfoWebUrl) ? `http://${cnt?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!cnt?.contactInfoWebUrl) {
                  e.preventDefault();
                }
              }}
              target={cnt?.contactInfoWebUrl ? '_blank' : undefined}
              
            >  <span style={{color: "white"}}>
                    {cnt?.contactInfoWebUrl}
                  </span>
                </a>
                  </div>
            </div>
}
{cnt?.contactInfoEmail &&
            <div className='flex items-center my-2' style={{flexDirection:"column"}}>
                <div className='z-0 p-2 rounded-2xl'>
                  <img className='z-10 w-thirtypx' src={msg}/>
                </div>
                <div className='mx-2 text-white font-DM font-medium'>
                  {/* {cnt?.contactInfoEmail}  */}
                  <a
                  href={
                    cnt?.contactInfoEmail ? `mailto:${cnt?.contactInfoEmail}` : ""
                  }
                >
                  <span style={{color: "white"}}>
                    {cnt?.contactInfoEmail}
                  </span>
                </a>
                  </div>
            </div>
            }

            {cnt?.contactInfoAddress && 
            <div className='flex items-center my-2' style={{flexDirection:"column"}}>
                <div className='z-0 p-2 rounded-2xl'>
                  <img className='z-10 w-thirtypx' src={location}/>
                </div>
                <div className='mx-2 text-white font-DM font-medium px-4 text-center'>
                {cnt?.contactInfoAddress}</div>
            </div>
}
            {cnt?.contactInfoOptAddress && 
            <div className='flex items-center my-2' style={{flexDirection:"column"}}>
                  <p style={{color:"#fff", fontSize:"12px"}}>Alternative Address</p>  
                <div className='mx-2 text-white font-DM font-medium px-4 text-center'>
                {cnt?.contactInfoOptAddress}</div>
            </div>
            }
    </div>

{socialactive == 1 && 
<>
     <div className='capitalize font-Poppins text-xl text-white font-medium pb-2 pt-4 py-2'>
   Social Media
     </div>
          <div className='flex items-center justify-evenly px-2 py-4 w-hundred'>
            <ul className="socil-link w-hundred">
              {!!socialMediaData && socialMediaData.length>0 && socialMediaData.map((val, index) => {
                const socialIcon = getSocialIcon(val?.nameOfSocial?.toLowerCase());
                const link = getLink(val?.nameOfSocial?.toLowerCase(), val?.link);

                if (val?.nameOfSocial && val?.link) {
                  return (
                    <>
                      <div className="social-link-container" key={index}>
                        <li>
                          <a href={link} target="_blank">
                            <i className={`fa-${val?.nameOfSocial.toLowerCase() === 'star' ? 'solid' : 'brands'} fa-${socialIcon}`}>
                            </i>
                          </a>
                        </li>
                      </div>
                    </>
                  );
                }

                return null;
              })}
            </ul>
          </div>
          </>
          }
  </div>
  )
}

export default CabContactInfo


// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Insta} />
// </div>

// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Facebook} />
// </div>

// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Twitter} />
// </div>

// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Youtube} />
// </div>
// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Youtube} />
// </div>
// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Youtube} />
// </div>
// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Youtube} />
// </div>
// <div className='px-3 pb-4'>
// <img className='w-fiftypx' src={Youtube} />
// </div>