import React, { useState, useEffect } from 'react';
import product1 from '../../../../Images/theme-53-p1.png';
import { environmentVariables } from "../../../../config/env.config";
// import { getServicesArray } from "../../../../utils/utility";
// import { transform } from 'next/dist/build/swc';

export const Products = ({data, active, toggleProduct }) => {
  console.log(data,"bbbbbbbbbbbbbbbbbbbbbbbbbbbbb")
  const items = Array.from({ length: 10 }, (_, index) => index + 1);
  const [closing, setClosing] = useState(false);
  // const link = `${environmentVariables.apiUrl}uploads/gallery/${item.image}`;
  const handleClose = () => {
    setClosing(true); 
    setTimeout(() => {
      toggleProduct(); 
      setClosing(false);
    }, 600); 
  };

  

  return (
    <section 
    style={{ bottom:closing?"-100%":active?"50px":"-100%"}} 
    className={`${active ?  "product-popup" : ""}`}>
      <div className='product-popup-inner'>
        <article className="products sec">
          <h3 className="sectitle">Products</h3>
          <p className="cross">
            {active && (
              <i onClick={handleClose} className="fa-solid fa-x"></i>
            )}
          </p>
          <div className="product-inner">
            {data.map((item) => {
             
              const link = `${environmentVariables.apiUrl}uploads/${item?.image}`
            
               return(<div className="item" key={item}>
                <div className="image"><img src={link} alt="" /></div>
                <h6>{item.product_name}</h6>
                <p>₹1099.00/-</p>
                <button>Buy Now</button>
              </div>)
})}
          
          </div>
        </article>
      </div>
    </section>
  );
};
