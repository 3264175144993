import React from "react";
function SubGoogleReview(props) {
  let rs = props?.rs;
  // console.log(rs,"!RF#E");
  let ff = rs?.googlereview;
  let totlreview = rs?.review

  // if (ff?.length) {
  //   ff = JSON?.parse(ff);
  // }
  // if (ff?.length) {
  //   ff = JSON?.parse(ff);
  // }

  // console.log(ff,"@%#Wadfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Google Review</h3>
        {/* <p style={{color: "#1e1e1e" , marginBottom:"15px",fontWeight:"800"}}>Total Review: {totlreview}</p> */}
        {/* <p style={{ fontFamily: `"Red Hat Display", sans-serif` ,color:"#000",fontWeight:"400", fontSize:"13px",textAlign:"center",}}>Rating {rs?.rating} <span style={{margin:"0 1px"}}>|</span> {totlreview } Review</p> */}

        <ul class="conlink">
          <li style={{margin:"0"}}>
          <a href={`https://www.google.com/search?q=${ff}`} target="_blank" rel="noopener noreferrer">
                    <button 
                    style={{
                      background: "#495057",
                      color: "#fff",
                      width: "100%",
                      fontSize: "16px",
                      padding: "12px 0",
                      border: "none",
                      borderRadius: "6px",
                      }}
                    >Review us on Google</button>
                  </a>
          </li>
        </ul>
      </article>
    </>
  );
}
export default SubGoogleReview;
