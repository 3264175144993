import React, { useContext, useState, useRef } from "react";

import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";

import "./Theme53.css";
import axios from "axios";
import download from "../../../../Images/theme-17-download.png";
import addcontact from "../../../../Images/theme17-add-contact.png";
import logo1 from "../../../../Images/theme-53-l1.png";
import logo2 from "../../../../Images/theme-53-l2.png";
import logo3 from "../../../../Images/theme-53-l3.png";
import logo4 from "../../../../Images/theme-53-l4.png";
import product1 from "../../../../Images/theme-53-p1.png";
import product2 from "../../../../Images/theme-53-p2.png";
import {Products} from './Products.js'
import bannerImage from "../../../../Images/theme-53-banner.png";
import insta from "../../../../Images/theme-53-insta.png";
import youtube from "../../../../Images/Theme-53-youtube.png";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import { getServicesArray } from "../../../../utils/utility";
import Star1 from "../../../../Images/Img1FullStar.png";
import Star2 from "../../../../Images/Img3blurStar.png";
import Star3 from "../../../../Images/Img2HalfStar.png";


const Theme53 = (props) => {
  const [workingHour, setWorkingHour] = useState(false);
  const { userData } = useContext(userContext);
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const [active, setActive] = useState(false);
  const touchStartX = useRef(0);
  const calendarInputRef = useRef(null);
  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);

    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      // const parsedContent = JSON.parse(
      //   props?.businessObj?.appointmentObj?.content
      // );
      // setAppointmentData(
      //   parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      // );
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, testimonials.length - 1)
      );
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };
  // console.log(props.orderOfBlocks, "appointment inside iframe");

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    // console.log(brand);
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        } else if (val.content == "Google Review") {
          orders["google_review"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };
  const getJSXElement = (elemName) => {
    // console.log(props?.appointmentArray, "elemNameelemName");
    if (elemName == "appointment") {
      return (
        props?.onState?.appointment && ""
        // <article class="contact sec">
        //   <h3 class="sectitle">Appoinment</h3>
        //   <select class="form-select" aria-label="Default select example">
        //     <option selected>Select time</option>
        //     {props?.appointmentArray?.map((val) => (
        //       <>
        //         <option value="1">
        //           {props.changeTimeFormat(val?.startDate)} -{" "}
        //           {props.changeTimeFormat(val?.endDate)}{" "}
        //         </option>
        //       </>
        //     ))}
        //   </select>
        // </article>
        // <article className="makeappitmneets sec">
        //   <h3 class="sectitle">Make Appointment</h3>
        //   <div className="contact-form">
        //     <div className="row">
        //       <div class="col-6 mb-3" style={{ position: "relative" }}>
        //         <label> Select Date </label>
        //         <div style={{ position: "relative" }}>
        //           <input
        //             ref={calendarInputRef}
        //             type="date"
        //             class="form-control clear_string"
        //             placeholder="Date"
        //             name="currentDate"
        //             value={selectedDate}
        //             onChange={(e) =>
        //               handleChangeAppointmentDate(e.target.value)
        //             }
        //           />
        //           <div className="calanderIcon" onClick={openCalendar}>
        //             <img src={CalanderIconn} alt="Calendar Icon" />
        //           </div>
        //         </div>
        //       </div>
        //       <div class="col-6 mb-3">
        //         <label> Select Time </label>
        //         <select
        //           class="form-select"
        //           aria-label="Default select example"
        //           // onChange={(e) => setSelectedTime(e.target.value)}
        //         >
        //           <option value="Select time">Select time</option>
        //           {appointmentData?.map((val) => (
        //             <option
        //               key={val?.startDate}
        //               value={`${val?.startDate} - ${val?.endDate}`}
        //             >
        //               {`${val?.startDate} - ${val?.endDate}`}
        //             </option>
        //           ))}
        //         </select>
        //       </div>
        //     </div>
        //     <div class="col-12 mb-3">
        //       <button className="btn btnmakeappitmnets">
        //         Create Appointment <img src={arrow} alt="arrow" />
        //       </button>
        //     </div>
        //   </div>
        // </article>
      );
    } else if (elemName == "business_hour") {
      return "";
      // props?.onState?.businessHours && (
      //   <article className="businessHours sec">
      //     <h3 class="sectitle">
      //       Working Hours{" "}
      //       <i
      //         onClick={() => {
      //           setWorkingHour(!workingHour);
      //         }}
      //         class="fa-solid fa-chevron-right rightcorner"
      //       ></i>
      //     </h3>
      //     {/* <p>
      //             Mon to Sat <span className="bgText">9AM to 9PM</span>
      //             <br />
      //             Sunday Closed!
      //           </p> */}

      //     <ul
      //       className="timelist"
      //       style={{ display: workingHour ? "none" : "block" }}
      //     >
      //       {console.log(workingHour)}
      //       {props?.businessHourObject?.isMonday &&
      //         props?.businessHourObject?.modayStartDate &&
      //         props?.businessHourObject?.modayEndDate && (
      //           <li>
      //             <div className="day">Monday :</div>
      //             <div className="time">
      //               {" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.modayStartDate
      //                 )}
      //               </span>{" "}
      //               -{" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.modayEndDate
      //                 )}
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //       {props?.businessHourObject?.isTuesday &&
      //         props?.businessHourObject?.tuesdayStartDate &&
      //         props?.businessHourObject?.tuesdayEndDate && (
      //           <li>
      //             <div className="day">Tuesday :</div>
      //             <div className="time">
      //               {" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.tuesdayStartDate
      //                 )}
      //               </span>{" "}
      //               -{" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.tuesdayEndDate
      //                 )}
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //       {props?.businessHourObject?.isWednesDay &&
      //         props?.businessHourObject?.wednesdayStartDate &&
      //         props?.businessHourObject?.wednesdayEndDate && (
      //           <li>
      //             <div className="day">Wednesday :</div>
      //             <div className="time">
      //               {" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.wednesdayStartDate
      //                 )}
      //               </span>{" "}
      //               -{" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.wednesdayEndDate
      //                 )}
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //       {props?.businessHourObject?.isthrusdayDay &&
      //         props?.businessHourObject?.thrusdayStartDate &&
      //         props?.businessHourObject?.thrusdayEndDate && (
      //           <li>
      //             <div className="day">Thursday :</div>
      //             <div className="time">
      //               {" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.thrusdayStartDate
      //                 )}
      //               </span>{" "}
      //               -{" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.thrusdayEndDate
      //                 )}
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //       {props?.businessHourObject?.isfridayDay &&
      //         props?.businessHourObject?.fridayStartDate &&
      //         props?.businessHourObject?.fridayEndDate && (
      //           <li>
      //             <div className="day">Friday :</div>
      //             <div className="time">
      //               {" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.fridayStartDate
      //                 )}
      //               </span>{" "}
      //               -{" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.fridayEndDate
      //                 )}
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //       {props?.businessHourObject?.issaturdayDay &&
      //         props?.businessHourObject?.SaturdayStartDate &&
      //         props?.businessHourObject?.SaturdayEndDate && (
      //           <li>
      //             <div className="day">Saturday :</div>
      //             <div className="time">
      //               {" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.SaturdayStartDate
      //                 )}
      //               </span>{" "}
      //               -{" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.SaturdayEndDate
      //                 )}
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //       {props?.businessHourObject?.issundayDay &&
      //         props?.businessHourObject?.SundayStartDate &&
      //         props?.businessHourObject?.SundayEndDate && (
      //           <li>
      //             <div className="day">Sunday :</div>
      //             <div className="time">
      //               {" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.SundayStartDate
      //                 )}
      //               </span>{" "}
      //               -{" "}
      //               <span>
      //                 {props.changeTimeFormat(
      //                   props?.businessHourObject?.SundayEndDate
      //                 )}
      //               </span>
      //             </div>
      //           </li>
      //         )}
      //     </ul>
      //   </article>
      // )
    } else if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec">
            <h3 class="sectitle">For Enquiry </h3>

            <ul class="conlink">
              <li>
                <a>
                  <i class="fa-solid fa-phone"></i>
                  <span>{props?.contactInfo?.contactInfoPhone}</span>
                  {props?.contactInfo?.optionalContactInfoPhone?.split("-")?.[1]
                    ?.length > 0 && (
                    <span>
                      {" "}
                      , {props?.contactInfo?.optionalContactInfoPhone}{" "}
                    </span>
                  )}
                </a>
              </li>

              <li>
                <a>
                  <i class="fa-solid fa-envelope"></i>
                  <span>{props?.contactInfo?.contactInfoEmail}</span>
                </a>
              </li>
              <li>
                <a href="">
                <i class="fa-solid fa-envelope"></i>
                  <span>
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li>
              {props?.contactInfo?.contactInfoWebUrl && (
                <li>
                  <a>
                    <i class="fa-solid fa-link"></i>
                    <span style={{ marginLeft: "5px", wordBreak: "break-all" }}>
                      {props?.contactInfo?.contactInfoWebUrl}
                    </span>
                  </a>
                </li>
              )}
              <li>
                <a>
                  <i className="fa-solid fa-location-dot"></i>
                  <div>
                    <a href="#">
                      <span>{props?.contactInfo?.contactInfoAddress}</span>
                    </a>
                    {props?.contactInfo?.contactInfoOptAddress && (
                      <>
                        <p
                          style={{
                            color: "#6D6E71",
                            marginTop: "5px",
                            fontSize: "14px",
                          }}
                        >
                          Alternative Address
                        </p>
                        <a href="#">
                          <span>
                            {props?.contactInfo?.contactInfoOptAddress}
                          </span>
                        </a>
                      </>
                    )}
                  </div>
                </a>
              </li>
            </ul>
          </article>
        )
      );
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "google_review") {
      return (
        props?.onState?.googlereview == true && (
          <article class="contact sec">
            <h3 class="sectitle">Google review</h3>
            <ul class="conlink">
              <li>
                <p
                  style={{
                    wordBreak: "break-all",
                    // maxHeight: "77px",
                    // overflow: "hidden",
                    fontSize: "16px",
                    // color: "#fff",
                  }}
                >
                  {props?.googlereview !== undefined && props?.googlereview}
                </p>
              </li>
            </ul>
          </article>
        )
      );
    } else if (elemName == "contact_form") {
      return (
        props?.onState?.contactForm && (
          <article className="conatctform sec">
            <h3 class="sectitle">Contact Form</h3>
            <div class="contact-form">
              <form>
                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Name*</lable> */}
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Name"
                    name="last_name"
                    // value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Email*</lable> */}
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Abc@gmail.com"
                    name="email"
                    // value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Phone*</lable> */}
                  <input
                    type="text"
                    class="form-control clear_string"
                    maxlength="10"
                    minlength="10"
                    placeholder="+91 (000) 000-0000 "
                    name="mobile_number"
                    // value=""
                    required=""
                  />
                </div>

                <div class="col-12 mb-3">
                  {/* <lable class="contactformLableName">Case Description</lable> */}
                  <textarea
                    class="form-control clear_string"
                    rows="3"
                    placeholder="Your message goes here..."
                    required=""
                    name="message"
                  ></textarea>
                </div>

                <div class="col-12 mb-3">
                  <button type="button" class="btn btn-submitform">
                    Submit
                    {/* <img src={arrow} alt="arrow" /> */}
                  </button>
                </div>
              </form>
            </div>
          </article>
        )
      );
    } else if (elemName == "description") {
      return (
        <article className="abouttemplate sec">
          {/* <h3 class="sectitle">ABOUT US</h3> */}
          <p className="para">
            {props.description ||
              ` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`}
          </p>
        </article>
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && (
          <article className="gallery sec sec-common">
            {props?.galleryData && (
              <>
                <GalleryCarousel slides={props?.galleryData} active={active} />
              </>
            )}
            <h3
              className="sectitle"
              style={{ marginTop: "5px", fontSize: "14px", cursor: "pointer" }}
              onClick={() => setActive(!active)}
            >
              View All
            </h3>
          </article>
        )
      );
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName == "service") {
      return (
        props?.onState?.services && (
          <article className="servicesName sec">
            <h3 class="sectitle">Services </h3>
            <div className="mainservicebox">
              {/* <ul className="servesbox">
                {props?.servicesArray?.map((val) => (
                  <li> {val?.name}</li>
                ))}
              </ul> */}
              {props?.servicesArray && (
                <ul className="servesbox">
                  {getServicesArray(props?.servicesArray).map(
                    (val) =>
                      val?.name != null && <li key={val?.name}> {val?.name}</li>
                  )}
                </ul>
              )}
            </div>
          </article>
        )
      );
    } else if (elemName == "social") {
      return "";
      // props?.onState?.socials && (
      //   <article className="social-links-sec">
      //     <h3 class="sectitle">Social Links</h3>
      //     <ul class="socil-link socil-link-inner">
      //       {props?.socialMediaData?.map((val) => (
      //         <li>
      //           <a>
      //             {/* <i
      //               class={`fa-brands fa-${getBrandNameForSocial(
      //                 val?.nameOfSocial.toLowerCase()
      //               )}`}
      //             ></i> */}
      //             <i
      //               className={`fa-${
      //                 val?.nameOfSocial.toLowerCase() === "star"
      //                   ? "solid"
      //                   : "brands"
      //               } fa-${getBrandNameForSocial(
      //                 val?.nameOfSocial.toLowerCase()
      //               )}`}
      //             ></i>
      //           </a>
      //         </li>
      //       ))}
      //     </ul>
      //   </article>
      // )
    } else if (elemName == "testimonials") {
      return (''
        // props?.onState?.testimonials && (
        //   <div>
          
        //     <article class="testimoniyalbox sec">
        //       <div className="testimonial-inner">
        //         <h3 class="sectitle testimonial">Testimonial </h3>

        //         <div>
        //           <TestimonialCarousel
        //             testimonials={props?.testimonialArray}
        //             currentElem={currentIndex}
        //             handleTouchStart={handleTouchStart}
        //             handleTouchMove={handleTouchMove}
        //             handleTouchEnd={handleTouchEnd}
        //           />
        //         </div>
        //       </div>
        //     </article>

        //     {/* <div className="carousel-container">
        //       <button
        //         onClick={() =>
        //           currentIndex == 0
        //             ? setCurrentIndex(props.testimonialArray.length - 1)
        //             : setCurrentIndex((prev) => prev - 1)
        //         }
        //         className="carousel-btn"
        //       >
        //         <i class="fa fa-angle-left"></i>
        //       </button>
        //       <button
        //         onClick={() => {
        //           currentIndex == props.testimonialArray.length - 1
        //             ? setCurrentIndex(0)
        //             : setCurrentIndex((prev) => prev + 1);
        //         }}
        //         className="carousel-btn"
        //       >
        //         <i class="fa fa-angle-right"></i>
        //       </button>
        //     </div> */}
        //   </div>
        // )
      );
    }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in props.order) {
      BlocksArray[props.order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };

  const StarRating = ({ value, totalStars, starSize }) => {
    const starElements = [];
    const fullStars = Math.floor(value);
    const halfStar = value % 1 >= 0.5 && value % 1 < 1;

    for (let i = 1; i <= totalStars; i++) {
      let imgSrc;

      if (i <= fullStars) {
        imgSrc = Star1;
      } else if (i === fullStars + 1 && halfStar) {
        imgSrc = Star3;
      } else {
        imgSrc = Star2;
      }

      const imgStyle = {
        width: starSize,
        height: starSize,
        marginRight: "2px",
      };

      starElements.push(
        <img key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
      );
    }

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {starElements}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div class="main-temaplt" id="temp53">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
              <article className="imgsechere sec">
                <img
                  src={
                    props?.bannerImageUrl
                      ? props.getImage(props.bannerImageUrl)
                      : bannerImage
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                />
                <div className="imageOverlay"></div>

                <div className="followers">
                  <div className="item">
                    <img src={insta} alt="" />
                    <span>1.8M Followers</span>
                  </div>
                  <p className="middle">|</p>
                  <div className="item">
                    <img src={youtube} alt="" />
                    <span>859K Subscribers</span>
                  </div>
                </div>
                {props?.onState?.socials && (
                  <article className="social-links-sec">
                    {/* <h3 class="sectitle">Social Links</h3> */}
                    <ul class="socil-link socil-link-inner">
                      {props?.socialMediaData?.map((val) => (
                        <li>
                          <a>
                            {/* <i
                      class={`fa-brands fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i> */}
                            <i
                              className={`fa-${
                                val?.nameOfSocial.toLowerCase() === "star"
                                  ? "solid"
                                  : "brands"
                              } fa-${getBrandNameForSocial(
                                val?.nameOfSocial.toLowerCase()
                              )}`}
                            ></i>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </article>
                )}
              </article>
              <section className="mainsection">
                {/* <div className="chevron">
                  {" "}
                  <i class="fa-solid fa-chevron-up"></i>
                </div> */}

                {/* <div className="logoTextContainer"> */}
                <article className="personalinfo">
                  {/* <img
                      src={
                        props?.logoImageUrl
                          ? props.getImage(props?.logoImageUrl)
                          : logoImage
                      }
                      className="mx-auto d-block img-fluid logo-profile"
                    /> */}
                  <h3 className="username">{props.subTitle || "Name"}</h3>
                  <p className="titleuser">
                    {props.designation || "Designation"}
                  </p>

                  {/* <p className="companeyname">
                  {props.subTitle || "Company Name"}
                </p> */}
                  <p className="reviewrating">
                    <StarRating
                      value={props?.rating}
                      totalStars={5}
                      starSize="16px"
                    />
                    <span className="ratingspan"> {props.rating}/5</span> (Based
                    on {props.review} reviews)
                  </p>
                </article>
                {/* </div> */}

                {getBlocksOrder().map((val) =>
                  val == undefined ? <></> : val
                )}

                <article className="brand-deals sec">
                  <h3 class="sectitle">Brand Deals </h3>

                  <div className="brand-inner">
                    <div className="item">
                      {" "}
                      <img src={logo1} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo2} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo3} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo4} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo1} alt="" />
                    </div>
                  </div>
                </article>

                {props?.onState?.miniproduct && (
                 <Products data={props.minimarketArray} />
                )}

                {/* <article className="products sec">
                  <h3 class="sectitle"> Products </h3>
                  <div className="product-inner">
                    <div className="item">
                      <div className="image">
                        <img src={product1} alt="" />
                      </div>
                      <h6>All stay foundation</h6>
                      <p>₹1099.00/-</p>
                      <button>Buy Now</button>
                    </div>
                    <div className="item">
                      <div className="image">
                        <img src={product2} alt="" />
                      </div>
                      <h6>All stay foundation</h6>
                      <p>₹1099.00/-</p>
                      <button>Buy Now</button>
                    </div>
                  </div>
                </article> */}
              </section>
              <div class="fixedd">
                <article class="fixedfooter">
                  <div class="addtocinatct">
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 28 28"
                      version="1.1"
                                       
                     
                      fill="#fff"
                      stroke="#fff"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                       
                        <title>grid</title>{" "}
                        <desc>Created with Sketch Beta.</desc> <defs> </defs>{" "}
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          
                        >
                        
                          <g
                            id="Icon-Set-Filled"
                           
                            transform="translate(-104.000000, -935.000000)"
                            fill="#fff"
                          >
                           
                            <path
                              d="M128,935 L124,935 C121.791,935 120,936.791 120,939 L120,943 C120,945.209 121.791,947 124,947 L128,947 C130.209,947 132,945.209 132,943 L132,939 C132,936.791 130.209,935 128,935 L128,935 Z M128,951 L124,951 C121.791,951 120,952.791 120,955 L120,959 C120,961.209 121.791,963 124,963 L128,963 C130.209,963 132,961.209 132,959 L132,955 C132,952.791 130.209,951 128,951 L128,951 Z M112,951 L108,951 C105.791,951 104,952.791 104,955 L104,959 C104,961.209 105.791,963 108,963 L112,963 C114.209,963 116,961.209 116,959 L116,955 C116,952.791 114.209,951 112,951 L112,951 Z M112,935 L108,935 C105.791,935 104,936.791 104,939 L104,943 C104,945.209 105.791,947 108,947 L112,947 C114.209,947 116,945.209 116,943 L116,939 C116,936.791 114.209,935 112,935 L112,935 Z"
                              id="grid"
                           
                            >
                             
                            </path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <p>Products</p>
                  </div>

                  <div class="footerleft">
                    <div
                      class="qrcodeshaere"
                      data-bs-toggle="modal"
                      data-bs-target="#appotiments"
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                  </div>
                  <div class="addtocinatct">
                    <i class="fa-solid fa-user-plus"></i>
                    <p>Add To Contact</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        //   ref={createUserRef}
        id="appotimnets"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Make Appointment
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="row">
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Full Name"
                    //   value={appointmentName}
                    //   onChange={(e) =>
                    //     setAppointmentName(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    eror
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Email"
                    //   value={appointmentEmail}
                    //   onChange={(e) =>
                    //     setAppointmentEmail(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    'errormsg'
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Mobile No."
                    //   value={appointmentContactNumber}
                    //   onChange={(e) =>
                    //     setAppointmentContactNumber(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    'errormsgni'
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12">
                  <button
                    //   onClick={handleSubmits}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    <i class="bi bi-check-circle-fill ms-2 "></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme53;
