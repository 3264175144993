import axios from "axios";
import { environmentVariables } from "../config/env.config";
import parsePhoneNumberFromString from "libphonenumber-js";
import moment from 'moment';
import NoImage from "../Images/No image available.png";


export const getContactInfo = (responseData)=>{
    let jsonData = responseData;
    if (jsonData?.length) {
      jsonData = JSON?.parse(jsonData);
    }
    if (jsonData?.length) {
      jsonData = JSON?.parse(jsonData);
    }
    return jsonData?.contactInfoAddress;
}

export const isValidEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  // Minimum length of 8 characters and at least one letter and one digit
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
  return passwordRegex.test(password);
};

export const isValidName = (name) => {
  const nameRegex = /^[A-Za-z -]{3,}$/;
  return nameRegex.test(name);
};

export const fetchFiles = (fileName, cardName)=>{
  return new Promise((resolve, reject)=>{
    try{
      let apiUrl  = `${environmentVariables?.apiUrl}api/v1/phy_card/fetchFiles`;
      let payload = {
        fileName:fileName, cardName:cardName
      }
      let config = {
        method: 'post',
        url: apiUrl,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : payload
      };
      
      axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve(false);
      });
    }catch(error){
      console.log("error====>>>", error);
      resolve(false);
    }
  })
  
}

export const isValidPhoneNumber = (inputNumber, country) => {
 try{
  const phoneNumber = parsePhoneNumberFromString(inputNumber, country?.iso2?.toUpperCase());
  return phoneNumber?.isValid() || false;
 }catch(err){
  console.log(err)
 }
};

export   const getAvailableSlots = (slotsData, date)=>{
  const currentDate = moment();
  const dateWithTimeSlots = slotsData.map(slot => {
    return {
      ...slot,
      startDateTime: moment(`${date} ${slot.startDate}`, 'YYYY-MM-DD hh:mm A'),
      endDateTime: moment(`${date} ${slot.endDate}`, 'YYYY-MM-DD hh:mm A')
    };
  });
  const filtered = dateWithTimeSlots.filter(slot => {
    return slot.startDateTime.isAfter(currentDate);
  });
  return filtered;
}

export const getJSON = (str)=>{
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
}

export const getServicesArray = (services)=> {
  
  if (typeof services === 'string') {
      try {        
        const parsedArray = JSON.parse(services);
        if (Array.isArray(parsedArray)) {
          return parsedArray;
        }else{
          return typeof parsedArray =='object'? parsedArray: JSON.parse(parsedArray);
        }
      } catch (e) {
          console.error('Invalid JSON string:', e);
      }
  }
  
  return Array.isArray(services) ? services : [];
}



export const handleErrorImage = (event) => {
  event.target.src = NoImage;
};