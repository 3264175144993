import React from "react";
function SubGoogleReview(props) {
  let rs = props?.rs;
  let ff = rs?.googlereview;
  let totlreview = rs?.review


  return (
    <>
      <article class="abouttemplate sec">
        <h3 class="sectitle" style={{padding:"0"}}>Google Review</h3>
        {/* <p style={{color:"#343C99",textAlign:"center",fontWeight:"800"}}>Total Review: {totlreview}</p> */}
        {/* <p style={{ fontFamily: `"Red Hat Display", sans-serif` ,color:"#000",fontWeight:"400", fontSize:"13px", marginTop:"10px"}}>Rating {rs?.rating} <span style={{margin:"0 1px"}}>|</span> {totlreview } Review</p> */}

        <ul class="conlink" style={{background:"none"}}>
          <li className="reviewusongoogle">
          <a href={`https://www.google.com/search?q=${ff}`} target="_blank" rel="noopener noreferrer">
                    <button 
                    style={{
                      background: "#fff",
                      color: "#000",
                      // width: "100%",
                      fontSize: "17px",
                      padding: "12px 40px",
                      border: "1px solid #00005",
                      borderRadius: "6px",
                      boxShadow: "0px 0px 10px 2px #0000001a",
                      
                      }}
                    >Review us on Google</button>
                  </a>
          </li>
        </ul>
      </article>
    </>
  );
}
export default SubGoogleReview;
