import NewBusinessDetilsCreate from "./NewBusinessDetilsCreate";
import NewTestimonialsCreate from "./NewTestimonialsCreate";
import NewRestaurantMenuCreate from "./NewRestaurantMenuCreate";
import NewAppointmentsCreate from "./NewAppointmentsCreate";
import NewSocialmediaCreate from "./NewSocialmediaCreate";
import NewGallerycreate from "./NewGallerycreate";
import NewBusinessHour from "./NewBusinessHour";
import NewRestaurantHour from "./NewRestaurantHour";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import bannerimage from "../../../Images/placeholder-imagevmycard.jpg";
import bannerimage from "../../../Images/blackpic.jpg";
import avatar from "../../../Images/blackpic2.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../../context/userContext";
import ViewThem from "./ViewThem";
import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Modal, Slider, Button } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import styled from "styled-components";
import "./DetailsStylee.css";
import { currencyContext } from "../../../context/currencyContext.js";
import { isValidPhoneNumber } from "../../../utils/utility.js";
import NewminimarketProductCreate from "./NewminimarketProductCreate.js";
import TermsConditionsCreate from "./NewTermsConditionsCreate.js";
import NewBusinessDetilsCreatesaloon from "./NewBusinessDetilsCreatesaloon.js";
import NewCabProductCreate from "./NewCabProductCreate.js";
import { debounce } from "lodash";

const ErrorStyle = styled.div`
  color: #ff1717;
  font-size: 12px;
`;

function Details({
  appointmentSlotDuration,
  setAppointmentSlotDuration,
  businessObj,
  mobilePreviewLoading,
  themeColor,
  handleFetchById,
  themeNumber,
  activeTab,
  setActiveTab,
}) {

  const { currencyObjData } = useContext(currencyContext);

  const { userData, setUserData } = useContext(userContext);
  const { businessObjData } = useContext(businessObjContext);
  const { resumeData } = useContext(businessObjContext);
  const { businessData, setBusinessData } = useContext(businessContext);
  const [countryName, setCountryName] = useState("India");
  const [addressPlaceId, setAddressPlaceId] = useState("");
  const [addressLat, setAddressLat] = useState("");
  const [addressLng, setAddressLng] = useState("");
  const fileInputRefBanner = useRef(null);
  const fileInputRefLogo = useRef(null);
  const baseUrl = environmentVariables?.apiUrl;

  const [title, setTitle] = useState("");
  const [isDine, setIsDine] = useState(0);
  const [isShowBusinessName, setIsShowBusinessName] = useState(1);
  const [isShowAddress, setIsShowAddress] = useState(1);
  const [designation, setDesignation] = useState("");
  const [googlereview, setGoogleReview] = useState("");
  const [googleaddress, setgoogleAddress] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const [slug, setSlug] = useState("");
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [invalidOptPhoneErr, setInvalidOptPhoneErr] = useState("");
  const [isBannerRequired, setIsBannerRequired] = useState(false);
  //contact info
  const [isAddressDisabled, setIsAddressDisabled] = useState(false);
  const [isEnabledContactInfo, setIsEnabledContactInfo] = useState(true);
  const [isEnabledGoogleReview, setIsEnabledGoogleReview] = useState(false);


  const [isEnabledContactForm, setIsEnabledContactForm] = useState(false);
  const [existingGalleryImages, setExistingGalleryImages] = useState([]);

  // order
  const [isEnabledOrders, setIsEnabledOrders] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [contactInfoPhone, setContactInfoPhone] = useState("");
  const [optionalContactInfoPhone, setOptionalContactInfoPhone] = useState("");
  const [contactInfoEmail, setContactInfoEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isAddSubmit, setIsAddSubmit] = useState(false);
  const [isAddressSubmit, setIsAddressSubmit] = useState(false);
  const [contactInfoWebUrl, setContactInfoWebUrl] = useState("");
  const [contactInfoAddress, setContactInfoAddress] = useState("");
  const [contactInfoOptAddress, setContactInfoOptAddress] = useState("");
  const [galleryView, setGalleryView] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [logoData, setLogoData] = useState("");
  const [bannerData, setBannerData] = useState("");
  const [onState, setOnState] = useState({
    contactInfo: true,
    services: false,
    testimonials: false,
    restaurantmenu: false,
    miniproduct: false,
    termsconditions: false,
    socials: false,
    businessHours: false,
    gallery: false,
    appointment: false,
    contactForm: false,
    orders: false,
    googlereview: false,
    cabproduct: false,
  });
  //services array
  const [servicesArray, setServicesArray] = useState([]);
  const [isEnabledServices, setIsEnabledServices] = useState(false);
  //testimonial arry
  const [testimonialArray, setTestimonialArray] = useState([]);
  const [isTestimonial, setIsTestimonial] = useState(false);

  const httpProtocol = environmentVariables?.httpProtocol;

  // menu array
  const [restaurantArray, setRestaurantArray] = useState([]);
  const [isRestaurant, setIsRestaurant] = useState(false);
  const [minimarketArray, setMiniMarketArray] = useState([]);
  // const [minimarketTempArray, setMiniMarketTempArray] = useState([]);
  const [isMinimktProduct, setIsMinimktProduct] = useState(false);

  const [cabArray, setCabArray] = useState([]);
  const [isCabProduct, setIsCabProduct] = useState(false);


  //appointment array
  const [isAppointment, setIsAppointment] = useState(false);
  const [appointmentArray, setAppointmentArray] = useState([]);
  //social
  const [isSocial, setIsSocial] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);

  const [isTermsConditions, setIsTermsConditions] = useState(false);
  const [termsConditionData, setTermsConditionData] = useState([]);
  //business hours
  const [businessHourObject, setBusinessHourObject] = useState({});
  const [IsBusinessHourObject, setIsBusinessHourObject] = useState(false);

  //galary array
  const [gallaryObject, setGallaryObject] = useState({});
  const [isGallaryEnable, SetIsGallaryEnable] = useState(false);

  const [divStates, setDivStates] = useState({
    div1: false,
    div2: false,
    div3: false,
    div4: false,
    div5: false,
    div6: false,
    div7: false,
    div8: false,
    div9: false,
  });

  const [rating, setRating] = useState('');
  const [link, setLink] = useState(null);
  const [reviews, setReviews] = useState('');
  const [tempRating, setTempRating] = useState(null);
  const [tempLink, setTempLink] = useState(null);
  const [tempReviews, setTempReviews] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isAddressVerified, setIsAddressVerified] = useState(false);
  const [latLngError, setLatLngError] = useState("");

  // Debounced function to call the API
  const fetchGoogleRating = async () => {
    try {
      const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
        businessName: [formik.values.googlereview],
        locationName: [formik.values.googleaddress],
      });
      setIsAddSubmit(true);
      if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
        setTempRating(responseFirstAPI.data.rating);
        setTempLink(responseFirstAPI.data.placeId);
        setTempReviews(responseFirstAPI.data.totalreview);
        setIsAddSubmit(false)
      } else {
        setTempRating('');
        setTempLink(null);
        setTempReviews('');
        setIsAddSubmit(false)
      }
    } catch (error) {
      console.log("Error calling findGoogleRating API:", error);
      setIsAddSubmit(false)
    }
  };

  const handleGoogleAddressChange = (e) => {
    const newGoogleAddress = e.target.value;

    formik.setFieldValue("googleaddress", newGoogleAddress);

    setIsVerified(false);
  };

  const handleBlurReview = async () => {
    const newGoogleAddress = formik.values.googleaddress;
    if (formik.values.googlereview === '' || newGoogleAddress === '') {
      console.log('Business name and city name are required');
      return;
    }
    setIsAddSubmit(true);
    try {
      const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
        businessName: formik.values.googlereview,
        locationName: newGoogleAddress,
      });

      if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
        setTempRating(responseFirstAPI.data.rating);
        setTempLink(responseFirstAPI.data.placeId);
        setTempReviews(responseFirstAPI.data.totalreview);
      } else {
        setTempRating('');
        setTempLink(null);
        setTempReviews('');
      }
    } catch (error) {
      console.log("Error calling findGoogleRating API:", error);
    } finally {
      setIsAddSubmit(false);
    }
  };

  const handleCorrectData = () => {
    setRating(tempRating);
    setLink(tempLink);
    setReviews(tempReviews);
    setIsVerified(true);
  };

  const handleCorrectAddressData = () => {
    setIsAddressVerified(true);
    setIsAddressDisabled(true);
  };

  const handleIncorrectAddressData = () => {
    setIsAddressVerified(false);
    setIsAddressDisabled(false);
  };

  const handleIncorrectData = () => {
    setRating('');
    setLink(null);
    setReviews('');
    setTempRating('');
    setTempLink(null);
    setTempReviews('');
    setIsVerified(false);
  };

  const handleRadioChange = (event) => {
    formik.handleChange(event); // Update Formik's state

    // Update isBannerRequired based on the selected value
    setIsBannerRequired(event.target.value === "1");
  };


  const toggleDiv = (divId) => {
    setDivStates({
      ...divStates,
      [divId]: !divStates[divId],
    });
  };
  const isBannerShow = (theme) => {
    const themeswithbanner = [
      "theme1",
      "theme2",
      "theme3",
      "theme4",
      "theme5",
      "theme6",
      "theme7",
      "theme8",
      "theme9",
      "theme10",
      "theme11",
      "theme13",
      "theme15",
      "theme16",
      "theme17",
      "theme18",
      "theme19",
      "theme20",
      "theme21",
      "theme22",
      "theme23",
      "theme24",
      "theme25",
      "theme26",
      "theme27",
      "theme28",
      "theme29",
      "theme30",
      "theme31",
      "theme32",
      "theme33",
      "theme34",
      "theme35",
      "theme36",
      "theme37",
      "theme38",
      "theme39",
      "theme40",
      "theme41",
      "theme42",
      "theme43",
      "theme44",
      "theme45",
      "theme46",
      "theme47",
      "theme48",
      "theme49",
      "theme50",
      "theme51",
      "theme52",
      "theme53",

    ];
    if (themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };
  const isLogoShow = (theme) => {
    const themeswithbanner = ["theme10"];
    if (!themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };
  

  useEffect(() => {
    if (businessObj && businessObj?.id) {
      setBusinessId(businessObj?.id);
      setTitle(businessObj?.title || "");
      setIsDine(businessObj?.is_dine || 0);
      setIsShowBusinessName(businessObj?.is_showBusinessName === null ? 1 : businessObj?.is_showBusinessName);
      setIsShowAddress(businessObj?.is_showAddress === null ? 1 : businessObj?.is_showAddress);
      setDesignation(businessObj?.designation || "");
      setGoogleReview(businessObj?.Googlereview || "");
      setgoogleAddress(businessObj?.googleaddress || "");
      // setLink(businessObj?.reviewlink || "");
      setRating(businessObj?.rating || "");
      setReviews(businessObj?.review || "");
      setSubTitle(businessObj?.sub_title || "");
      setDescription(businessObj?.description || "");
      setBannerImageUrl(businessObj?.banner || "");
      setLogoImageUrl(businessObj?.logo || "");
      setSlug(businessObj?.slug || "");
      const reviewLink = businessObj?.reviewlink || "";
      setLink(reviewLink);
      if (reviewLink) {
        // Extract the place_id from the review URL
        const placeIdMatch = reviewLink.match(/place_id:([^&]+)/);
        if (placeIdMatch) {
          setTempLink(placeIdMatch[1]);
          setIsVerified(true);
        } else {
          setTempLink("");
          setIsVerified(false);
        }
      } else {
        setIsVerified(false);
        setTempLink("");
      }
    } else {
      setBusinessId("");
      setTitle("");
      setIsDine(0);
      setIsShowBusinessName(1);
      setIsShowAddress(1);
      setDesignation("");
      setGoogleReview("");
      setgoogleAddress("");
      setLink("");
      setRating("");
      setReviews("");
      setSubTitle("");
      setDescription("");
      setBannerImageUrl("");
      setLogoImageUrl("");
      setSlug("");
      setIsVerified(false);
      setTempLink("");
    }

    let contactInfoData;
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.business_id &&
      businessObj?.contactInfo?.content
    ) {
      contactInfoData = JSON.parse(businessObj?.contactInfo?.content);
      if (contactInfoData.length > 0) {
        contactInfoData = JSON.parse(contactInfoData);
      }
    }
    if (
      contactInfoData?.contactInfoAddress ||
      contactInfoData?.contactInfoPhone ||
      contactInfoData?.optionalContactInfoPhone ||
      contactInfoData?.contactInfoWebUrl ||
      contactInfoData?.contactInfoEmail
    ) {
      setContactInfoPhone(contactInfoData?.contactInfoPhone);
      setOptionalContactInfoPhone(contactInfoData?.optionalContactInfoPhone)
      setContactInfoEmail(contactInfoData?.contactInfoEmail);
      setContactInfoWebUrl(contactInfoData?.contactInfoWebUrl);
      setContactInfoAddress(contactInfoData?.contactInfoAddress);

      if (!!contactInfoData?.contactInfoAddress && businessObj?.addressPlaceId) {
        setAddressPlaceId(businessObj?.addressPlaceId);
        setAddressLat(businessObj?.latitude);
        setAddressLng(businessObj?.longitude);
        setIsAddressVerified(true);
        setIsAddressDisabled(true);
      }
      setContactInfoOptAddress(contactInfoData?.contactInfoOptAddress);

    } else {
      // setIsEnabledContactInfo(false);
      setContactInfoPhone("");
      setContactInfoEmail("");
      setContactInfoWebUrl("");
      setContactInfoAddress("");
      setContactInfoOptAddress("");
      setOptionalContactInfoPhone("");
      setIsAddressVerified(false);
      setIsAddressDisabled(false);
    }
    if (
      businessObj &&
      businessObj?.gallaryObj &&
      businessObj?.gallaryObj?.is_enabled
    ) {
      SetIsGallaryEnable(true);
      setOnState({ ...onState, gallery: true });
      const galleryData = JSON.parse(businessObj?.gallaryObj?.content);
      setGallaryObject(galleryData);
      setExistingGalleryImages([...galleryData?.gallaryArray]);
    } else {
      SetIsGallaryEnable(false);
    }
    if (
      businessObj &&
      businessObj?.businessHourObj &&
      businessObj?.businessHourObj?.is_enabled
    ) {
      setIsBusinessHourObject(true);
      setOnState({ ...onState, businessHours: true });
    } else {
      setIsBusinessHourObject(false);
    }
    if (
      businessObj &&
      businessObj?.SocialMediaObj &&
      businessObj?.SocialMediaObj?.is_enabled
    ) {
      setIsSocial(true);
      setOnState({ ...onState, socials: true });
    } else {
      setIsSocial(false);
    }
    if (
      businessObj &&
      businessObj?.appointmentObj &&
      businessObj?.appointmentObj?.is_enabled
    ) {
      setIsAppointment(true);
      setOnState({ ...onState, appointment: true });
    } else {
      setIsAppointment(false);
    }
    if (
      businessObj &&
      businessObj?.testimonialObj &&
      businessObj?.testimonialObj?.is_enabled == 1
    ) {
      setIsTestimonial(true);
      setOnState({ ...onState, testimonials: true });
    } else {
      setIsTestimonial(false);
    }
    if (
      businessObj &&
      businessObj?.menu &&
      businessObj?.menu?.is_enabled == 1
    ) {
      setIsRestaurant(true);
      setOnState({ ...onState, restaurantmenu: true });
    } else {
      setIsRestaurant(false);
    }
    if (
      businessObj &&
      businessObj?.miniMktProductArr &&
      businessObj?.is_mini_mktproduct == 1
    ) {
      setIsMinimktProduct(true);
      setOnState({ ...onState, miniproduct: true });
    } else {
      setIsMinimktProduct(false);
    }
    if (
      businessObj &&
      businessObj?.is_CabProduct == 1
    ) {
      setIsCabProduct(true);
      setOnState({ ...onState, cabproduct: true });
    } else {
      setIsCabProduct(false);
    }
    if (
      businessObj &&
      businessObj?.cabTermsConditions &&
      businessObj?.cabTermsConditions?.is_enabled == 1
    ) {
      setIsTermsConditions(true);
      setOnState({ ...onState, cabTermsConditions: true });
    } else {
      setIsTermsConditions(false);
    }

    if (
      businessObj &&
      businessObj?.serviceObj &&
      businessObj?.serviceObj?.is_enabled
    ) {
      setIsEnabledServices(true);
      setOnState({
        ...onState,
        services: true,
      });
    } else {
      setIsEnabledServices(false);
    }

    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.is_enabled
    ) {
      setIsEnabledContactInfo(true);
      setOnState({
        ...onState,
        contactInfo: true,
      });
    } else {
      // setIsEnabledContactInfo(false);
    }
    if (businessObj && businessObj?.is_enabledGooglereview == 1) {
      setIsEnabledGoogleReview(true);
      // let obj={...onstate}
      setOnState({
        ...onState,
        googlereview: true,
      });
    } else {
      setIsEnabledGoogleReview(false);
    }

    if (businessObj && businessObj?.is_contact_form_enabled) {
      setIsEnabledContactForm(true);
    } else {
      setIsEnabledContactForm(false);
    }
    if (businessObj && businessObj?.is_order_enabled) {
      setIsEnabledOrders(true);
    } else {
      setIsEnabledOrders(false);
    }
    if (businessObj?.newGalleryImages) {
      setGalleryData(businessObj?.newGalleryImages);
    }
    if(businessObj?.is_allow_in_search == 1){
      setIsBannerRequired(true)
    }

    formik.setValues({
      ...formik.values,
      contactInfoPhone: contactInfoData?.contactInfoPhone || (userData?.contact?.startsWith('+') ? userData?.contact : `${userData?.country_code || ""}${userData?.contact || ""}`) || "",
      optionalContactInfoPhone: contactInfoData?.optionalContactInfoPhone,
      contactInfoEmail: contactInfoData?.contactInfoEmail || userData?.email || "",
      contactInfoWebUrl: contactInfoData?.contactInfoWebUrl || "",
      contactInfoAddress: contactInfoData?.contactInfoAddress || "",
      subTitle: businessObj?.sub_title || userData?.name || "",
      designation: businessObj?.designation || "",
      googlereview: businessObj?.googlereview || "",
      googleaddress: businessObj?.googleaddress || "",
      description: businessObj?.description || "",
      description: businessObj?.description || "",
      is_allow_in_search: businessObj?.is_allow_in_search !== undefined ? businessObj.is_allow_in_search : 1,
    });
  }, [businessObj]);


  useEffect(() => { }, [onState]);

  useEffect(() => {
    let stateObj = {};
    if (businessObj?.testimonialObj?.is_enabled) {
      stateObj["testimonials"] = true;
    }
    if (businessObj?.menu?.is_enabled) {
      stateObj["restaurantmenu"] = true;
    }
    if (businessObj?.is_mini_mktproduct == 1) {
      stateObj["miniproduct"] = true;
    }
    if (businessObj?.serviceObj?.is_enabled) {
      stateObj["services"] = true;
    }
    if (businessObj?.contactInfo?.is_enabled == 0) {
      stateObj["contactInfo"] = false;
    } else {
      stateObj["contactInfo"] = true;
    }
    if (businessObj?.gallaryObj?.is_enabled) {
      stateObj["gallery"] = true;
    }
    if (businessObj?.businessHourObj?.is_enabled) {
      stateObj["businessHours"] = true;
    }
    if (businessObj?.SocialMediaObj?.is_enabled) {
      stateObj["socials"] = true;
    }
    if (businessObj?.cabTermsConditions?.is_enabled) {
      stateObj["termsconditions"] = true;
    }
    if (businessObj?.is_CabProduct == 1) {
      stateObj["cabproduct"] = true;
    }
    if (businessObj?.appointmentObj?.is_enabled) {
      stateObj["appointment"] = true;
    }
    if (businessObj?.is_contact_form_enabled) {
      stateObj["contactForm"] = true;
    }
    if (businessObj?.is_order_enabled) {
      stateObj["orders"] = true;
    }
    if (
      businessObj?.is_enabledGooglereview &&
      businessObj?.is_enabledGooglereview == 1
    ) {
      stateObj["googlereview"] = true;
    }
    setOnState(stateObj);
  }, [businessObj]);
  const handleBannerChange = (e) => {
    const file = e.target.files[0];

    // List of accepted image MIME types
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (file && acceptedImageTypes.includes(file.type)) {
      setBannerData(file);

      // Use the FileReader API to display the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setBannerImageUrl(reader.result); // Immediate image for showing
      };
      reader.readAsDataURL(file);
    } else {
      // Display an error message if the selected file is not an image
      toast.error("Please select a valid image file (JPEG, PNG, JPG).", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    // List of accepted image MIME types
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (file && acceptedImageTypes.includes(file.type)) {
      setLogoData(file); // Setting logo data

      setSrc(URL.createObjectURL(file));
      setModalOpen(true);

      // Use the FileReader API to display the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setLogoImageUrl(reader.result); // Immediate image for showing
      };
      reader.readAsDataURL(file);
    } else {
      // Display an error message if the selected file is not an image
      toast.error("Please select a valid image file (JPEG, PNG, JPG).", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };


  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validationSchema = Yup.object().shape({
    contactInfoPhone: Yup.string().min(10, "Enter valid Mobile Number"),
    optionalContactInfoPhone: Yup.string().min(0, "Enter valid Mobile Number"),
    contactInfoEmail: Yup.string().email("Enter valid Email").required("Email is required"),
    contactInfoAddress: Yup.string().required("Address is required"),
    contactInfoWebUrl: Yup.string(),
    subTitle: Yup.string()
      .matches(
        /^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])?\s*$/,
        "Only alphabets are allowed"
      )
      .min(2, "Minimum 2 characters required")
      .max(63, "Maximum 63 characters are allowed in name."),
    designation: Yup.lazy((value) => {
      if (["theme14", "theme20", "theme22"].includes(businessObjData?.theme_color)) {
        return Yup.string().nullable();
      } else {
        return Yup.string()
          .matches(
            /^[a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/,
            "Invalid input"
          )
          .min(2, "Minimum 2 characters required")
          .max(100, "Maximum 100 characters required")
          .required("Designation is required");
      }
    }),
    googlereview: Yup.string(),
    description: Yup.string()
      .required("Description is required.")
      .matches(/^[a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/, "Description contains invalid characters."),
    is_allow_in_search: Yup.number().oneOf([0, 1], "Please select an option"),
  });


  const [countryData, setCountryData] = useState({});

  const fetchDynamicCountry = async () => {
    try {
      const response = await axios.get(`${baseUrl}api/v1/user/getCountries`);
      const countryArray = response?.data?.result; // Assuming 'result' contains the country list
      
      // Transform the response data to match the desired format
      const dynamicCountryData = countryArray.reduce((acc, country) => {
        acc[country.phone_code] = {
          name: country.name,
          iso2: country.short_code,
          dialCode: country.phone_code,
        };
        return acc;
      }, {});

      // Update the state with the transformed data
      setCountryData(dynamicCountryData);
      
    } catch (error) {
      console.log(error, "Error fetching country data");
    }
  };

  useEffect(() => {
    fetchDynamicCountry();
  }, []);

  const handleSubmit = async (e) => {
    setIsSubmit(true);

    // let link = "";
    // let rating = "";
    // let reviews = "";

    await formik.validateForm();

    // try {
    //   const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
    //     businessName: [formik.values.googlereview],
    //     locationName: ['Shop No. 7, Bird Sanctuary, Okhla Bird Sanctuary, Rd, Pocket K, Sector 95, Noida, Uttar Pradesh 201301']
    //   });

    //   // Check if the API call was successful and if it returned a rating
    //   if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
    //     rating = responseFirstAPI?.data?.rating;
    //     link = responseFirstAPI?.data?.placeId;
    //     reviews = responseFirstAPI?.data?.totalreview;
    //   } else {
    //     rating = null;
    //     link = null;
    //     reviews = null;
    //   }
    // } catch (error) {
    //   // Handle the case where the API call fails
    //   console.log("Error calling findGoogleRating API:", error);
    // }
    if (businessObjData?.theme_color !== 'theme12') {
      // Check if the banner is required and the banner image URL is missing
      if (isBannerRequired && !bannerImageUrl) {
          toast.error("Banner image is required.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
          });
          setIsSubmit(false);
          return;
      }
  }

    if (invalidPhoneErr) {
      toast.error(invalidPhoneErr, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    if (invalidOptPhoneErr) {
      toast.error(invalidOptPhoneErr, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    if (!formik.values.contactInfoEmail) {
      toast.error("Email is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
    if (!formik.values.contactInfoAddress) {
      toast.error("Address is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
    if (!isAddressVerified) {
      toast.error("Address is not verified", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    if (!formik.isValid) {
      if (formik?.errors?.subTitle) {
        toast.error(formik?.errors?.subTitle, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.designation) {
        toast.error(formik?.errors?.designation, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.description) {
        toast.error(formik?.errors?.description, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoEmail) {
        toast.error(formik?.errors?.contactInfoEmail, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoWebUrl) {
        toast.error(formik?.errors?.contactInfoWebUrl, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoPhone) {
        toast.error(formik?.errors?.contactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.optionalContactInfoPhone) {
        toast.error(formik?.errors?.optionalContactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      setIsSubmit(false);
      return;
    }
    if (businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme20') {
  } else {
      if (businessObjData?.theme_color === 'theme12') {
          if (!isEnabledGoogleReview || !rating || rating.length === 0) {
              toast.error("Google review is required.", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
              });
              setIsSubmit(false);
              return;
          }
      } else {
          if (isEnabledGoogleReview && (!rating || rating.length === 0)) {
              toast.error("In Google review add details.", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
              });
              setIsSubmit(false);
              return;
          }
      }
  }



    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme22'
    ) {
    } else {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error("At least one service is required.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }

        const hasEmptyName = servicesArray.some(service => !service.name?.trim());
        if (hasEmptyName) {
          toast.error("Service name is mandatory.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      }
    }

    if (businessObjData?.theme_color === 'theme20') {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error("Please select at least one category to proceed.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      }
  }



  if (businessObjData?.theme_color !== 'theme22'
  ) {
  } else {
    if (isCabProduct && (!cabArray || cabArray.length === 0)) {
      toast.error("At least one Cab Product is required.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
  }

    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme15' ||
      businessObjData?.theme_color === 'theme16' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme22'
    ) {
    } else {
      if (isTestimonial && (!testimonialArray || testimonialArray.length === 0)) {
        toast.error("At least one testimonial is required.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }
    }

    if (isSocial && (!socialMediaData || socialMediaData.length === 0)) {
      toast.error("At least one social Media Data is required.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (IsBusinessHourObject) {
        const {
          isMonday, isTuesday, isWednesDay,
          isfridayDay, issaturdayDay,
          issundayDay, isthrusdayDay
        } = businessHourObject;

        const allDaysFalse = !isMonday && !isTuesday && !isWednesDay && !isfridayDay && !issaturdayDay && !issundayDay && !isthrusdayDay;

        if (allDaysFalse) {
          toast.error("At least one business hour should be selected.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
          return;
        }
      }
    }


    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (isGallaryEnable && (!galleryData || galleryData.length === 0)) {
        toast.error("At least one Image in Portfolio is required.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }
    }
    let titleData = title.trim();
    if(titleData == '' || titleData == null){
      toast.error("Company name is required.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
    console.log("title====>>>", title);
    const formData = new FormData();

    formData.append("reviewlink", link);
    formData.append("rating", rating);
    formData.append("review", reviews);
    formData.append("banner", bannerData);
    formData.append("logo", logoData);
    formData.append("businessId", businessId);
    formData.append("title", title);
    formData.append("isDine", isDine);
    formData.append("isShowBusinessName", isShowBusinessName);
    formData.append("isShowAddress", isShowAddress);
    formData.append("designation", formik.values.designation);
    formData.append("googlereview", formik.values.googlereview);
    formData.append("googleaddress", formik.values.googleaddress);
    formData.append("sub_title", formik.values.subTitle);
    formData.append("description", formik.values.description);
    formData.append("slug", slug);
    // formData.append("is_allow_in_search", isCheckboxChecked ? 1 : 0);
    formData.append("is_allow_in_search", formik.values.is_allow_in_search)
    let formCount = 0;
    formData.append(
      "is_enabledContactInfo",
      isEnabledContactInfo == true ? 1 : 0
    );
    formData.append(
      "isEnabledGoogleReview",
      isEnabledGoogleReview == true ? 1 : 0
    );
    formData.append(
      "is_contact_form_enabled",
      isEnabledContactForm == true ? 1 : 0
    );
    formData.append("is_order_enabled", isEnabledOrders == true ? 1 : 0);
    let latData = addressLat.toString();
    let lngData = addressLng.toString();
    let addressPlaceIdData = addressPlaceId.toString();    
    let contactOptionallength = formik.values.optionalContactInfoPhone?.split("-")?.[1]?.length; 
    
    let content = {
      contactInfoPhone: formik.values.contactInfoPhone,
      optionalContactInfoPhone: (contactOptionallength>0)?formik.values.optionalContactInfoPhone:'',
      contactInfoEmail: formik.values.contactInfoEmail,
      contactInfoWebUrl: formik.values.contactInfoWebUrl,
      contactInfoAddress,
      contactInfoOptAddress,
      addressLat: latData,
      addressLng: lngData,
      addressPlaceId: addressPlaceIdData,
    };
    if (isEnabledContactInfo == true) {
      formData.append("content", JSON.stringify(content));
    }
    formData.append("is_services", isEnabledServices ? 1 : 0);
    if (servicesArray && servicesArray.length) {
      servicesArray.forEach((file, index) => {
        if (file?.banner && file?.banner["0"].name?.length) {
          formData.append(`servicesArray`, file?.banner["0"]);
        } else if (file && file.image) {
          formData.append(`servicesImages[${index}]`, file?.image);
        }
        formData.append(`serviceId[${index}]`, file?.id)
        formData.append(`serviceDescription[${index}]`, file?.description);
        formData.append(`serviceName[${index}]`, file?.name);
        formData.append(`serviceButtonLink[${index}]`, file?.buttonLink);
        formData.append(`serviceButtonName[${index}]`, file?.buttonName);
      });
    }

    //testimonial array

    formData.append("is_testimonial", isTestimonial ? 1 : 0);
    if (testimonialArray && testimonialArray.length) {
      testimonialArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`testimonialArray`, file?.image["0"]);
        } else {
          formData.append(`testimonialImageArray[${index}]`, file?.image);
        }
        formData.append(`testimonialDescription[${index}]`, file?.description);
        formData.append(`testimonialName[${index}]`, file?.name);
        formData.append(`testimonialFormCount[${index}]`, index);
      });
    }

    //restaurantmenuarray

    formData.append("is_menu", isRestaurant ? 1 : 0);
    if (restaurantArray && restaurantArray.length) {
      restaurantArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`restaurantArray`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`dishPrice[${index}]`, file.price);
        formData.append(`dishName[${index}]`, file?.name);
        formData.append(`dishCategory[${index}]`, file?.category);
        formData.append(`restaurantFormCount[${index}]`, index);
      });
    }

    // mini market place products
    let dataAllArr = [...minimarketArray]
    // dataAllArr = dataAllArr?.filter((e) => e.price)
    formData.append("is_mini_mktproduct", isMinimktProduct ? 1 : 0);
    if (dataAllArr && dataAllArr.length) {
      dataAllArr.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`mini_mktproduct`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`minimktproductPrice[${index}]`, (!!file.price) ? file.price : 0);
        formData.append(`minimktproductQuantity[${index}]`, (!!file.quantity) ? file.quantity : 0);
        formData.append(`minimktproductName[${index}]`, (!!file?.name) ? file?.name : "");
        formData.append(`minimktproductCategory[${index}]`, file?.category);
        formData.append(`minimktproductservice[${index}]`, file?.service);
        formData.append(`minimktproductDescription[${index}]`, (!!file?.description) ? file?.description : "")
        formData.append(`restaurantFormCount[${index}]`, index);
      });
    }


    formData.append("is_business_hours_enabled", IsBusinessHourObject ? 1 : 0);
    formData.append("businesshoursOBject", JSON.stringify(businessHourObject));

    //appointment array
    formData.append("appointmentContent", JSON.stringify(appointmentArray));
    formData.append("is_appointment", isAppointment ? 1 : 0);

    //social media
    formData.append("is_social", isSocial ? 1 : 0);
    formData.append("isSocalData", JSON.stringify(socialMediaData));

    // terms and conditions data
    formData.append("is_tnc", isTermsConditions ? 1 : 0);
    formData.append("tncData", JSON.stringify(termsConditionData))


    // gallary
    formData.append("isGallaryEnable", isGallaryEnable ? 1 : 0);
    formData.append("isCabProduct", isCabProduct ? 1 : 0);
    // formData.append(`gallary`, gallaryObject?.gallary);
    galleryData &&
      galleryData.length &&
      galleryData.map((val, index) => {
        formData.append("gallary", val);
      });
    existingGalleryImages.forEach((val) => {
      formData.append("gallaryArray", val);
    });
    formData.append("gallary_video", gallaryObject?.gallary_video);
    formData.append("gallaryImage", gallaryObject?.gallaryImage);
    formData.append("gallaryVideoLink", gallaryObject?.videoLink);
    formData.append("country", countryName);
    axios
      .post(`${baseUrl}api/v1/business/updateDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setActiveTab("tab3");
          setIsSubmit(false);
        } else {
          toast.error(
            res?.data?.message?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(
          err?.response?.data?.message?.message || err?.response?.data?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        setIsSubmit(false);
      });
    // } catch (err) {
    //   console.log(err);
    //   toast.error(err, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }
  };

  const formik = useFormik({
    initialValues: {
      contactInfoPhone: "",
      contactInfoEmail: "",
      optionalContactInfoPhone: "",
      contactInfoWebUrl: "",
      subTitle: businessObj?.sub_title || "",
      designation: businessObj?.designation || "",
      description: businessObj?.description || "",
      googlereview: businessObj?.googlereview || "",
      googleaddress: businessObj?.googleaddress || "",
      is_allow_in_search: businessObj?.is_allow_in_search || 1,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const getBusinessDataBySlug = (slug) => {
    return new Promise((resolve, reject) => {
      try {
        if (!!slug) {
          axios
            .get(`${baseUrl}api/v1/business/fetchBusinessBySlug`, {
              params: { slug: slug },
              headers: {
                //   _token: userData?.token,
              },
              // withCredentials: true,
            })
            .then((res) => {
              resolve(res?.data?.data);
            })
            .catch((err) => {
              console.log({ msg: "err", err: err });
              resolve(false);
            });
        } else {
          resolve(false);
        }
      } catch (error) {
        console.error(error);
        resolve(false);
      }
    });
  };

  const handleSubmitWithoutTabChange = async (savetype = '') => {
    // try {
    setIsLoadingSubmit(true);
    // let link = "";
    // let rating = "";
    // let reviews = "";

    //   try {
    //   const responseFirstAPI = await axios.post(`${baseUrl}api/v1/business/findGoogleRating`, {
    //     businessName: [formik.values.googlereview]
    //   });

    //   // Check if the API call was successful and if it returned a rating
    //   if (responseFirstAPI.status === 200 && responseFirstAPI.data.rating) {
    //     rating = responseFirstAPI?.data?.rating;
    //     link = responseFirstAPI?.data?.placeId;
    //     reviews = responseFirstAPI?.data?.totalreview;
    //   } else {
    //     rating = null;
    //     link = null;
    //     reviews = null;
    //   }
    // } catch (error) {
    //   // Handle the case where the API call fails
    //   console.log("Error calling findGoogleRating API:", error);
    // }
    if (businessObjData?.theme_color !== 'theme12') {
      // Check if the banner is required and the banner image URL is missing
      if (isBannerRequired && !bannerImageUrl) {
          toast.error("Banner image is required", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
      }
  }

    if (invalidPhoneErr) {
      toast.error(invalidPhoneErr, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);

      return;
    }

    if (invalidOptPhoneErr) {
      toast.error(invalidOptPhoneErr, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }
    // e.preventDefault();

    if (!formik.values.contactInfoEmail) {
      toast.error("Email is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;

    }

    if (!formik.values.contactInfoAddress) {
      toast.error("Address is required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }

    if (!isAddressVerified) {
      toast.error("Address is not verified", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }

    if (!formik.isValid) {
      if (formik?.errors?.subTitle) {
        toast.error(formik?.errors?.subTitle, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.designation) {
        toast.error(formik?.errors?.designation, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.description) {
        toast.error(formik?.errors?.description, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoEmail) {
        toast.error(formik?.errors?.contactInfoEmail, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoWebUrl) {
        toast.error(formik?.errors?.contactInfoWebUrl, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.contactInfoPhone) {
        toast.error(formik?.errors?.contactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (formik?.errors?.optionalContactInfoPhone) {
        toast.error(formik?.errors?.optionalContactInfoPhone, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
      setIsLoadingSubmit(false)
      return;
    }

    // setting field validation

    if (businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme20') {
  } else {
      if (businessObjData?.theme_color === 'theme12') {
          if (!isEnabledGoogleReview || !rating || rating.length === 0) {
              toast.error("Google review is required.", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
              });
              setIsLoadingSubmit(false);
              return;
          }
      } else {
          if (isEnabledGoogleReview && (!rating || rating.length === 0)) {
              toast.error("In Google review add details.", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
              });
              setIsLoadingSubmit(false);
              return;
          }
      }
  }

    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme22'
    ) {
    } else {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error("At least one service is required.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }

        const hasEmptyName = servicesArray.some(service => !service.name?.trim());
        if (hasEmptyName) {
          toast.error("Service name is mandatory.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
    }

    if (businessObjData?.theme_color === 'theme20') {
      if (isEnabledServices) {
        if (!servicesArray || servicesArray.length === 0) {
          toast.error("Please select at least one category to proceed.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
  }

  // console.log(businessObjData,"businessObjDatabusinessObjData")

  if (businessObjData?.theme_color === 'theme22') {
    if (isCabProduct) {
      if (!isCabProduct || cabArray.length === 0) {
        toast.error("Please select at least one Cab Product to proceed.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }
}
  
    if (
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme14' ||
      businessObjData?.theme_color === 'theme15' ||
      businessObjData?.theme_color === 'theme16' ||
      businessObjData?.theme_color === 'theme20' ||
      businessObjData?.theme_color === 'theme22'
    ) {
    } else {
      if (isTestimonial && (!testimonialArray || testimonialArray.length === 0)) {
        toast.error("At least one testimonial is required.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }

    if (isSocial && (!socialMediaData || socialMediaData.length === 0)) {
      toast.error("At least one social Media Data is required.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false)
      return;
    }

    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme12' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (IsBusinessHourObject) {
        const {
          isMonday, isTuesday, isWednesDay,
          isfridayDay, issaturdayDay,
          issundayDay, isthrusdayDay
        } = businessHourObject;

        const allDaysFalse = !isMonday && !isTuesday && !isWednesDay && !isfridayDay && !issaturdayDay && !issundayDay && !isthrusdayDay;

        if (allDaysFalse) {
          toast.error("At least one business hour should be selected.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
          return;
        }
      }
    }

    if (
      businessObjData?.theme_color === 'theme22' ||
      businessObjData?.theme_color === 'theme17' ||
      businessObjData?.theme_color === 'theme20') {
    } else {
      if (isGallaryEnable && (!galleryData || galleryData.length === 0)) {
        toast.error("At least one Image in Portfolio is required.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }
    let titleData = title.trim();
    if(titleData == '' || titleData == null){
      toast.error("Company name is required.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }

    const formData = new FormData();
    formData.append("banner", bannerData);
    formData.append("logo", logoData);
    formData.append("reviewlink", link);
    formData.append("rating", rating);
    formData.append("review", reviews);
    formData.append("businessId", businessId);
    formData.append("title", title);
    formData.append("isDine", isDine);
    formData.append("isShowBusinessName", isShowBusinessName);
    formData.append("isShowAddress", isShowAddress);
    formData.append("designation", formik.values.designation);
    formData.append("googlereview", formik.values.googlereview);
    formData.append("googleaddress", formik.values.googleaddress);
    formData.append("sub_title", formik.values.subTitle);
    formData.append("description", formik.values.description);
    formData.append("slug", slug);
    formData.append("is_allow_in_search", formik.values.is_allow_in_search);
    let formCount = 0;
    formData.append(
      "is_enabledContactInfo",
      isEnabledContactInfo == true ? 1 : 0
    );
    formData.append(
      "isEnabledGoogleReview",
      isEnabledGoogleReview == true ? 1 : 0
    );
    let latData = addressLat.toString();
    let lngData = addressLng.toString();
    let addressPlaceIdData = addressPlaceId.toString();
    let contactOptionallength = formik.values.optionalContactInfoPhone?.split("-")?.[1]?.length; 
    
    let content = {
      contactInfoPhone: formik.values.contactInfoPhone,
      optionalContactInfoPhone: (contactOptionallength>0)?formik.values.optionalContactInfoPhone:'',
      contactInfoEmail: formik.values.contactInfoEmail,
      contactInfoWebUrl: formik.values.contactInfoWebUrl,
      contactInfoAddress,
      contactInfoOptAddress,
      addressLat: latData,
      addressLng: lngData,
      addressPlaceId: addressPlaceIdData,
    };

    if (isEnabledContactInfo == true) {
      formData.append("content", JSON.stringify(content));
    }

    formData.append(
      "is_contact_form_enabled",
      isEnabledContactForm == true ? 1 : 0
    );
    formData.append("is_order_enabled", isEnabledOrders == true ? 1 : 0);
    formData.append("is_services", isEnabledServices ? 1 : 0);
    if (servicesArray && servicesArray.length) {
      servicesArray.forEach((file, index) => {
        if (file?.banner && file?.banner["0"].name?.length) {
          formData.append(`servicesArray`, file?.banner["0"]);
        } else if (file && file.image) {
          formData.append(`servicesImages[${index}]`, file?.image);
        }
        formData.append(`serviceId[${index}]`, file?.id)
        formData.append(`serviceDescription[${index}]`, file?.description);
        formData.append(`serviceName[${index}]`, file?.name);
        formData.append(`serviceButtonLink[${index}]`, file?.buttonLink);
        formData.append(`serviceButtonName[${index}]`, file?.buttonName);
      });
    }

    //testimonial array

    formData.append("is_testimonial", isTestimonial ? 1 : 0);
    if (testimonialArray && testimonialArray.length) {
      testimonialArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`testimonialArray`, file?.image["0"]);
        } else {
          formData.append(`testimonialImageArray[${index}]`, file?.image);
        }
        formData.append(`testimonialDescription[${index}]`, file?.description);
        formData.append(`testimonialName[${index}]`, file?.name);
        formData.append(`testimonialFormCount[${index}]`, index);
      });
    }

    //restaurantmenuarray

    formData.append("is_menu", isRestaurant ? 1 : 0);
    if (restaurantArray && restaurantArray.length) {
      restaurantArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`restaurantArray`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`dishPrice[${index}]`, file.price);
        formData.append(`dishCategory[${index}]`, file?.category);
        formData.append(`dishName[${index}]`, file?.name);
        formData.append(`restaurantFormCount[${index}]`, index);
      });
    }

    // mini market place products
    let dataAllArr = [...minimarketArray]
    // dataAllArr = dataAllArr?.filter((e) => e.price);
    formData.append("is_mini_mktproduct", isMinimktProduct ? 1 : 0);
    if (dataAllArr && dataAllArr.length) {
      dataAllArr.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`mini_mktproduct`, file?.image["0"]);
        } else {
          formData.append(`restaurantImageArray[${index}]`, file?.image);
        }
        formData.append(`minimktproductPrice[${index}]`, (!!file.price) ? file.price : 0);
        formData.append(`minimktproductQuantity[${index}]`, (!!file.quantity) ? file.quantity : 0);
        formData.append(`minimktproductName[${index}]`, (!!file?.name) ? file?.name : "");
        formData.append(`minimktproductCategory[${index}]`, file?.category);
        formData.append(`minimktproductservice[${index}]`, file?.service);
        formData.append(`minimktproductDescription[${index}]`, (!!file?.description) ? file?.description : "")
        formData.append(`restaurantFormCount[${index}]`, index);
      });
    }


    formData.append("is_business_hours_enabled", IsBusinessHourObject ? 1 : 0);
    formData.append("businesshoursOBject", JSON.stringify(businessHourObject));

    //appointment array
    formData.append("appointmentContent", JSON.stringify(appointmentArray));
    formData.append("is_appointment", isAppointment ? 1 : 0);

    //social media
    formData.append("is_social", isSocial ? 1 : 0);
    formData.append("isSocalData", JSON.stringify(socialMediaData));

    //terms and conditions data
    formData.append("is_tnc", isTermsConditions ? 1 : 0);
    formData.append("tncData", JSON.stringify(termsConditionData))

    formData.append("isCabProduct", isCabProduct ? 1 : 0);

    // gallary
    formData.append("isGallaryEnable", isGallaryEnable ? 1 : 0);
    // formData.append(`gallary`, gallaryObject?.gallary);
    galleryData &&
      galleryData.length &&
      galleryData.map((val, index) => {
        formData.append("gallary", val);
      });
    existingGalleryImages.forEach((val) => {
      formData.append("gallaryArray", val);
    });
    formData.append("gallary_video", gallaryObject?.gallary_video);
    formData.append("gallaryImage", gallaryObject?.gallaryImage);
    formData.append("gallaryVideoLink", gallaryObject?.videoLink);
    formData.append("country", countryName);
    axios
      .post(`${baseUrl}api/v1/business/updateDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      })
      .then(async (res) => {

        if (res?.data?.success) {
          let businessData = await getBusinessDataBySlug(
            businessObj?.slug ? businessObj?.slug : ""
          );
          // setMiniMarketArray([])
          if (savetype != 'productadd') {

            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            let redirectUrl =
              businessData?.slug_subdomain != null
                ? httpProtocol + businessData?.slug_subdomain
                : businessObj?.slug
                  ? "/" + businessObj?.slug
                  : "/";

            window.open(`${redirectUrl}`, "_blank");
          }

          // window.open(
          //   `http://${businessData?.slug_subdomain}`,
          //   "_blank"
          // );
        } else {
          toast.error(
            res?.data?.message?.message,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        }
        setIsLoadingSubmit(false);
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(err?.response?.data?.message?.message || err?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
      });
    // } catch (err) {
    //   console.log(err);
    //   toast.error(err, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }
  };
  // console.log(termsConditionData,';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;')
  const handleImageClickBanner = () => {
    fileInputRefBanner.current.click();
  };

  const handleImageClickLogo = () => {
    fileInputRefLogo.current.click();
  };

  const handlePreviousClick = () => {
    if (activeTab === "tab2") {
      setActiveTab("tab1");
    } else if (activeTab === "tab3") {
      setActiveTab("tab2");
    }
  };

  const handleChangeCompanyName = (e) => {
    const alphanumericWithSpaceRegex = /^[a-zA-Z0-9 ,.?\/()&@$‘’'"_\-\\\"\n\r!#$%&\(\)\-\+\[\]\{\};:'“”,.~@\/?]*$/;
    if (
      e.target.value === "" ||
      alphanumericWithSpaceRegex.test(e.target.value)
    ) {
      setTitle(e.target.value);
    }
  };

  const [src, setSrc] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);
  const inputRef = useRef(null);

  const handleSave = async () => {
    const dataUrl = cropRef.current.getImage().toDataURL();
    setLogoImageUrl(dataUrl);
    setModalOpen(false);

    // Convert data URL to Blob
    const byteString = atob(dataUrl.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/png" }); // Adjust the MIME type accordingly
    // Create a File object from the Blob
    const file = new File([blob], "filename.png", { type: "image/png" }); // Adjust the file name and type accordingly
    setLogoData(file);
  };
  const boxStyle = {};
  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#000000b3",
  };
  const handleIsDine = (e) => {
    let isDineData = e.target.checked ? 1 : 0;
    setIsDine(isDineData);
  }
  const handleIsShow = (e) => {
    let isShowData = e.target.checked ? 1 : 0;
    setIsShowBusinessName(isShowData);
  }
  const handleIsShowAddress = (e) => {
    let isAddData = e.target.checked ? 1 : 0;
    setIsShowAddress(isAddData);
  }
  useEffect(() => {
    if (onState.restaurantmenu) {
      setOnState((prevState) => ({ ...prevState, orders: true }));
      setIsEnabledOrders(true);
    } else {
      setOnState((prevState) => ({ ...prevState, orders: false }));
      setIsEnabledOrders(false);
    }
  }, [onState.restaurantmenu]);
  const [contactInfoAddressError, setContactInfoAddressError] = useState("");
  const [contactInfoOptAddressError, setContactInfoOptAddressError] = useState("");
  const handleContactInfoAddressChange = (e) => {
    const value = e.target.value;
    // Pattern to match characters not allowed (excluding newline \n)
    const specialCharPattern = /[^a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:]/;
    if (specialCharPattern.test(value)) {
      setContactInfoAddressError('Special characters are not allowed.');
    } else {
      setContactInfoAddressError('');
    }
    // Regular expression to allow only letters, numbers, spaces, and newline
    const filteredValue = value.replace(/[^a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:]/g, '');
    setContactInfoAddress(filteredValue);
    formik.setFieldValue("contactInfoAddress", filteredValue);
    setAddressPlaceId("");
    setTimeout(() => {
      setContactInfoAddressError('');
    }, 5000)
  };

  const handleContactInfoOptAddressChange = (e) => {
    const value = e.target.value;
    // Pattern to match characters not allowed (excluding newline \n)
    const specialCharPattern = /[^a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:]/;
    if (specialCharPattern.test(value)) {
      setContactInfoOptAddressError('Special characters are not allowed.');
    } else {
      setContactInfoOptAddressError('');
    }
    // Regular expression to allow only letters, numbers, spaces, and newline
    const filteredValue = value.replace(/[^a-zA-Z0-9 ,.?\/()&@$'’"_\-\n\r–%:]/g, '');
    setContactInfoOptAddress(filteredValue);
    setTimeout(() => {
      setContactInfoOptAddressError('');
    }, 5000)
  };


  // Function to get country data by dial code
  const getCountryByDialCode = (dialCode) => {
    return countryData[dialCode] || null;
  };

  const handleBlur = (value, phoneData) => {
    const extractPhoneNumber = (phone) => {
      if (phone.includes('-')) {
        let val = phone.split("-");
        return (val.length > 1) ? val[1] : "";
      } else {
        return phone.startsWith(`+${phoneData.dialCode}`) ? phone.slice(phoneData.dialCode.length + 1) : phone;
      }
    };

    const extractCountryCode = (phone) => {
      if (phone.startsWith('+')) {
        phone = phone.replace('+', '');

        if (phone.includes('-')) {
          return phone.split('-')[0];
        }
        return phone.substring(0, 2) === "91" ? phone.substring(0, 2) : phone.substring(0, 3);
      }
      return null;
    };

    let contactNumber = formik.values.contactInfoPhone;
    let optionalContactNumber = formik.values.optionalContactInfoPhone;

    if (!!contactNumber) {
      const contactDialCode = extractCountryCode(contactNumber);
      const phoneDataForContact = getCountryByDialCode(contactDialCode);

      let valArray = extractPhoneNumber(contactNumber);
      if (valArray === '') {
        setInvalidPhoneErr(`Please enter phone number.`);
      } else if (!isValidPhoneNumber(valArray, phoneDataForContact)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneDataForContact?.name || 'unknown country'}`);
      } else {
        setInvalidPhoneErr('');
      }
    }

    // Handle validation for optionalContactNumber
    if (!!optionalContactNumber) {
      const optionalDialCode = extractCountryCode(optionalContactNumber);
      const phoneDataForOptional = getCountryByDialCode(optionalDialCode);

      let valArray = extractPhoneNumber(optionalContactNumber);
      if (valArray === '') {
        setInvalidOptPhoneErr(``);
      } else if (!isValidPhoneNumber(valArray, phoneDataForOptional)) {
        setInvalidOptPhoneErr(`Invalid phone number for ${phoneDataForOptional?.name || 'unknown country'}`);
      } else {
        setInvalidOptPhoneErr('');
      }
    }
  };

  const handleGetLatLang = () => {
    setIsAddressSubmit(true);
    setLatLngError(""); 
    let addressData = title + ' '+ contactInfoAddress;    
    let data = JSON.stringify({
      "address": addressData
    });

    let config = {
      method: 'post',
      url: `${baseUrl}api/v1/business/getLatLng`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setIsAddressSubmit(false);
        if (response.data) {
          setAddressPlaceId(response?.data?.placeId);
          setAddressLng(response?.data?.longitude);
          setAddressLat(response?.data?.latitude);
        }
      })
      .catch((error) => {
        setIsAddressSubmit(false);
        setAddressPlaceId("");
        setAddressLng("");
        setAddressLat("");
        setLatLngError("Unable to fetch the address coordinates. Please check the address and try again.");
        console.log(error,"error");
      });
  }

  const getLogoSizeLabel = (themeColor) => {
    switch (themeColor) {
      case "theme1":
        return "(Size : 350 x 200)";
      case "theme2":
        return "(Size : 350 x 200)";
      case "theme3":
        return "(Size : 350 x 200)";
      case "theme4":
        return "(Size : 350 x 200)";
      case "theme5":
        return "(Size : 350 x 200)";
      case "theme6":
        return "(Size : 350 x 200)";
      case "theme7":
        return "(Size : 400 x 300)";
      case "theme8":
        return "(Size : 350 x 200)";
      case "theme9":
        return "(Size : 350 x 200)";
      case "theme10":
        return "(Size : 350 x 200)";
      case "theme11":
        return "(Size : 400 x 900)";
      case "theme12":
        return "(Size : 350 x 200)";
      case "theme13":
        return "(Size : 350 x 200)";
      case "theme14":
        return "(Size : 400 x 200)";
      case "theme15":
        return "(Size : 350 x 450)";
      case "theme16":
        return "(Size : 400 x 550)";
      case "theme18":
        return "(Size : 350 x 450)";
      case "theme20":
        return "(Size : 400 x 160)";
      case "theme41":
        return "(Size : 350 x 450)";
      case "theme48":
        return "(Size : 350 x 450)";
      case "theme35":
          return "(Size : 420 x 500)";


      // Add more cases as needed for other themes
      default:
        return "(Size : 400 x 200)"; // Default size if no theme matches
    }
  };


  return (
    <>
      <div className="ChangeBlock_Sequenceview_Wrapper">
        <div className="row ChangeBlock_Sequenceview_Container">
          <div className="col-12 col-lg-8 ChangeBlock_Container ChangeblockContainerpd">
            {themeNumber == 12 ? (
              <div className="tile">
                <form onSubmit={formik.handleSubmit} className="row">
                  <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                    {isBannerShow(businessObj?.theme_color) ? (
                      <div class="mb-4 col-md-8">
                        {/* <div className="boxbanner DetailsSelectBanner_wrapper">
                        <label className="form-label">Select Banner</label>
                        <div className="bannerbox">
                          <img
                            src={
                              bannerImageUrl
                                ? bannerImageUrl?.includes("base64")
                                  ? bannerImageUrl
                                  : bannerImageUrl == "null"
                                  ? ""
                                  : `${baseUrl}uploads/${bannerImageUrl}`
                                : bannerimage
                              // : ""
                            }
                            className="mx-auto d-block img-fluid"
                            onClick={(e) => handleImageClickBanner(e)}
                          ></img>
                         
                        </div>
                        <input
                          className="form-control d-none"
                          type="file"
                          name="banner" // Set the name to match the Multer field name
                          onChange={handleBannerChange}
                          ref={fileInputRefBanner}
                        />
                      </div> */}
                      </div>
                    ) : (
                      <></>
                    )}
                    {isLogoShow(businessObj?.theme_color) ? (
                      <div className="mb-3 col-md-4">
                        <div className="boxbanner detailsdptextlogowrapper">
                          <label className="form-label detailsdptext">
                            Select Logo (Size : 150 x 150)
                          </label>
                          <div className="bannerbox2 AdddetailsdpContainer">
                            <img
                              src={
                                logoImageUrl
                                  ? logoImageUrl?.includes("base64")
                                    ? logoImageUrl
                                    : logoImageUrl == "null"
                                      ? ""
                                      : `${baseUrl}uploads/${logoImageUrl}`
                                  : avatar
                              }
                              className="mx-auto d-block"
                              onClick={handleImageClickLogo}
                            />
                          </div>
                          {/* <p>
                      <strong>Size : (150 x 150)</strong>
                    </p> */}
                          <input
                            className="form-control d-none"
                            type="file"
                            name="logo" // Set the name to match the Multer field name
                            onChange={handleLogoChange}
                            ref={fileInputRefLogo}
                            accept=".png, .jpg, .jpeg"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="AddDetailsNameDestiCom_Wrapper">
                    <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">Name*</label>
                      <input
                        // value={subTitle}
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        // onChange={(e) => setSubTitle(e.target.value)}
                        {...formik.getFieldProps("subTitle")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.subTitle && formik.errors.subTitle && (
                        <ErrorStyle className="error">
                          {formik.errors.subTitle}
                        </ErrorStyle>
                      )}
                    </div>

                    <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                      <label className="form-label">Designation* </label>
                      <input
                        // value={designation}
                        {...formik.getFieldProps("designation")}
                        className="form-control"
                        type="text"
                        placeholder="Designation"
                      // onChange={(e) => setDesignation(e.target.value)}
                      />
                      {formik.touched.designation &&
                        formik.errors.designation && (
                          <ErrorStyle className="error">
                            {formik.errors.designation}
                          </ErrorStyle>
                        )}
                    </div>

                    <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">Company Name*</label>
                      <input
                        value={title}
                        className="form-control"
                        type="text"
                        placeholder="Company Name"
                        // onChange={(e) => setTitle(e.target.value)}
                        onChange={(e) => handleChangeCompanyName(e)}
                      />
                    </div>
                  </div>

                  <div className="mb-3 col-md-12">
                    <label className="form-label">Description*</label>
                    <textarea
                      // value={description}
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder="Enter Description"
                    // onChange={(e) => setDescription(e.target.value)}
                    >
                      {/* Hi, I'm a graphic designer ! */}
                    </textarea>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <ErrorStyle className="error">
                          {formik.errors.description}
                        </ErrorStyle>
                      )}
                  </div>


                  {/* <div
                    className="accordion-body AddDetailsContactInfoInnerForm"
                    style={{ padding: "0 20px" }}
                  >
                    <div className="d-flex">
                      <h5
                        style={{
                          fontWeight: "400",
                          padding: "10px 0",
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                        border-0
                      >
                        Google Review
                      </h5>
                    </div>
                    <div className="row MobileDetailsWrapper">
                      <div className="mb-3 col-md-12 MobilePhoneFieldWrapper">
                        <input
                          {...formik.getFieldProps("googlereview")}
                          className="form-control"
                          type="text"
                          placeholder="Domain Name"
                        />
                        {formik.touched.googlereview &&
                          formik.errors.googlereview && (
                            <ErrorStyle className="error">
                              {formik.errors.googlereview}
                            </ErrorStyle>
                          )}
                      </div>
                    </div>
                  </div> */}

                  <label className="form-label" style={{ fontSize: "17px" }}>
                    Settings
                  </label>
                  <div className="accordion" id="accordionExample">

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          onClick={(e) => {

                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              Contact Info
                            </h5>
                          </div>
                        </button>

                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          // for="flexSwitchCheckDefault"
                          >
                            On/Off
                          </label>
                          <input
                            onClick={(e) => {
                              setIsEnabledContactInfo(!isEnabledContactInfo);
                              setOnState({
                                ...onState,
                                contactInfo: !onState.contactInfo,
                              });
                            }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={() => toggleDiv("div1")}
                            checked={onState?.contactInfo}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AddDetailsContactInfoInnerForm">
                          {/* {divStates.div1 && ( */}
                          <div className="row MobileDetailsWrapper">
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                Mobile Number*
                              </label>

                              <PhoneInput
                                country={"in"}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.contactInfoPhone}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                  } else {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      value
                                    );
                                  }
                                }}
                                // onBlur={formik.handleBlur("contactInfoPhone")}
                                onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                // {...formik.getFieldProps('contactInfoPhone')}
                                className="DetailsInfoPhoneInput"
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                              />
                              {formik.touched.contactInfoPhone &&
                                formik.errors.contactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidPhoneErr && <ErrorStyle className="error">
                                {invalidPhoneErr}
                              </ErrorStyle>}
                            </div>
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                Alternative Mobile Number
                              </label>

                              <PhoneInput
                                country={"in"}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.optionalContactInfoPhone}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                  } else {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      value
                                    );
                                  }
                                }}
                                // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                // {...formik.getFieldProps('optionalContactInfoPhone')}
                                className="DetailsInfoPhoneInput"
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                              />
                              {formik.touched.optionalContactInfoPhone &&
                                formik.errors.optionalContactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.optionalContactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidOptPhoneErr && <ErrorStyle className="error">
                                {invalidOptPhoneErr}
                              </ErrorStyle>}
                            </div>

                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">Email*</label>
                              <input
                                {...formik.getFieldProps("contactInfoEmail")}
                                // value={contactInfoEmail}
                                // value={formik.values.contactInfoEmail}
                                className="form-control"
                                type="text"
                                placeholder="Email"
                              // onChange={(e) =>
                              //   setContactInfoEmail(e.target.value)
                              // }
                              // onChange={formik.handleChange('contactInfoEmail')}
                              />
                              {formik.touched.contactInfoEmail &&
                                formik.errors.contactInfoEmail && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoEmail}
                                  </ErrorStyle>
                                )}
                            </div>

                            <div className="mb-3 col-md-12">
                              <label className="form-label">Address*</label>
                              <textarea
                                value={contactInfoAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder="Address"
                                onChange={(e) =>
                                  handleContactInfoAddressChange(e)
                                }
                                // onBlur={handleGetLatLang}
                                disabled={isAddressDisabled}
                              ></textarea>
                              {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                              {formik.errors.contactInfoAddress && (
                                <ErrorStyle className="error">
                                  {formik.errors.contactInfoAddress}
                                </ErrorStyle>
                              )}
                              <a className="btn btn-info-detail" onClick={handleGetLatLang}>Submit</a>
                              {latLngError && <p className="text-danger mt-2" style={{fontSize: "12px"}}>{latLngError}</p>}
                              {isAddressSubmit ? (
                                <CircularLoader size={40} />
                              ) : (
                                addressPlaceId && formik.values.contactInfoAddress && (
                                  <div className="">
                                    <div className="">
                                      <a
                                        href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-link"
                                        style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                      >
                                        Please check & verify your business address on Google Map.
                                      </a>
                                    </div>
                                    <div className="">
                                      {isAddressVerified ? (
                                        <>
                                          <button className="btn btn-success me-2 selectreviewverified" disabled>
                                            Address Verified
                                          </button>
                                          <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                            Edit
                                          </button>
                                        </>
                                      ) : (
                                        <button className="btn btn-success me-2 selectreview 
                                          verifybusinesswtd" onClick={handleCorrectAddressData}>
                                          Verify Address
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            <div className="mb-3 col-md-12">
                              <label className="form-label">Alternative Address</label>
                              <textarea
                                value={contactInfoOptAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder="Address"
                                onChange={(e) =>
                                  handleContactInfoOptAddressChange(e)
                                }
                              ></textarea>
                              {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                            </div>
                          </div>
                          {/* )} */}
                        </div>

                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingZero">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseZero"
                          aria-expanded="false"
                          aria-controls="collapseZero"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              Google Review
                            </h5>
                          </div>
                        </button>

                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={() => toggleDiv("div8")}
                            checked={onState?.googlereview}
                            onClick={(e) => {
                              setIsEnabledGoogleReview(!isEnabledGoogleReview);
                              setOnState({
                                ...onState,
                                googlereview: !onState.googlereview,
                              });
                            }}
                          />

                        </div>
                      </h2>
                      <div
                        id="collapseZero"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingZero"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AddDetailsContactInfoInnerForm">
                          <div className="row MobileDetailsWrapper">
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <input
                                {...formik.getFieldProps("googlereview")}
                                className="form-control"
                                type="text"
                                placeholder="Business Name"
                                onBlur={handleBlurReview}
                                disabled={isVerified}
                              />
                              {formik.touched.googlereview && formik.errors.googlereview && (
                                <ErrorStyle className="error">
                                  {formik.errors.googlereview}
                                </ErrorStyle>
                              )}
                            </div>
                            <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                              <input
                                {...formik.getFieldProps("googleaddress")}
                                className="form-control"
                                type="text"
                                placeholder="City Name"
                                onChange={handleGoogleAddressChange}
                                onBlur={handleBlurReview}
                                disabled={isVerified}
                              />
                              {formik.touched.googleaddress && formik.errors.googleaddress && (
                                <ErrorStyle className="error">
                                  {formik.errors.googleaddress}
                                </ErrorStyle>
                              )}
                            </div>
                            <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                              <a className="btn selectreviewverifiedbtn" onClick={fetchGoogleRating}>
                                Submit
                              </a>
                            </div>
                          </div>

                          {isAddSubmit ? (
                            <CircularLoader size={40} />
                          ) : (
                            tempLink && (
                              <>
                                <div className="">
                                  <a
                                    href={`https://www.google.com/maps/place/?q=place_id:${tempLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-link"
                                    style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                  >
                                    Please check & verify your business on Google Map.
                                  </a>
                                </div>
                                <div className="">
                                  {isVerified ? (
                                    <>
                                      <button className="btn btn-success me-2 selectreviewverified verifybusinesswtd" disabled>
                                        Business Verified
                                      </button>
                                      <button className="selectreviewedit verifybusinesswtd" onClick={handleIncorrectData}>
                                        Edit
                                      </button>
                                    </>
                                  ) : (
                                    <button className="btn btn-success me-2 selectreview verifybusinesswtd" onClick={handleCorrectData}>
                                      Business Verify
                                    </button>
                                  )}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#five"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => {

                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Social Media</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"

                          >
                            On/Off
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.socials}
                            onChange={() => toggleDiv("div5")}
                            onClick={() => {
                              setIsSocial(!isSocial);
                              setOnState({
                                ...onState,
                                socials: !onState.socials,
                              });
                            }}
                          />
                        </div>
                      </h2>
                      <div
                        id="five"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                          style={{ padding: "15px 0" }}
                        >

                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            <NewSocialmediaCreate
                              setSocialMediaData={setSocialMediaData}
                              socialMediaData={socialMediaData}
                            />
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="accordion-item">
                      <div className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#seven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => {

                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Portfolio</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"

                          >
                            On/Off
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.gallery}
                            onClick={(e) => {
                              SetIsGallaryEnable(!isGallaryEnable);
                              setOnState({
                                ...onState,
                                gallery: !onState.gallery,
                              });
                            }}
                            onChange={() => toggleDiv("div7")}
                          />
                        </div>
                      </div>
                      <div
                        id="seven"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingseven"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewGallaryformwrapper"
                        >
                          <div className="row">
                            <NewGallerycreate
                              businessId={businessId}
                              existingGalleryImages={existingGalleryImages}
                              setExistingGalleryImages={
                                setExistingGalleryImages
                              }
                              gallaryObject={gallaryObject}
                              galleryData={galleryData}
                              setGalleryData={setGalleryData}
                              galleryView={galleryView}
                              setGalleryView={setGalleryView}
                              setGallaryObject={setGallaryObject}
                              isGallaryEnable={isGallaryEnable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row Adddetails_saveprev_ButtonWrapper">
                    <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                      <button
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                        id="changeblockprenxtbtn"
                        type="button"
                        onClick={handlePreviousClick}
                      >
                        Previous
                      </button>
                      {/* </div>
                  <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                      {isSubmit ? (
                        <CircularLoader size={40} />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                          id="changeblockprenxtbtn"
                          onClick={(e) => handleSubmit(e)}
                        >
                          Save & Next
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            ) : themeNumber == 14 ? (
              ////////////////////////////    restaurant detIALS PAGE  START  ////////////////////////////////////

              <div className="tile">
                <form onSubmit={formik.handleSubmit} className="row">
                <div className="flex items-center i-agree">
                      <div className="mr-2">
                        <label>Would you like to publish your services to the marketplace?</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_allow_in_search"
                          value={1}
                          id="yes"
                          checked={formik.values.is_allow_in_search == 1}
                          onChange={handleRadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="yes" className="mr-4">Yes</label>
                      </div>
                      <div className="mr-2">
                        <input
                          type="radio"
                          name="is_allow_in_search"
                          value={0}
                          id="no"
                          checked={formik.values.is_allow_in_search == 0}
                          onChange={handleRadioChange}
                          onBlur={formik.handleBlur}
                        />
                        <label htmlFor="no">No</label>
                      </div>

                    </div>
                  <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                    {/* {isBannerShow(businessObj?.theme_color) ? ( */}
                    <div class="mb-4 col-md-8">
                      <div className="boxbanner DetailsSelectBanner_wrapper">
                        <label className="form-label detailsdptext">
                        Select Banner <span style={{fontSize:"12px"}}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                                <span className="text-danger">
                                  {isBannerRequired ? " *" : " "}
                                </span>
                        </label>
                        <div className="bannerbox">
                          <img
                            src={
                              bannerImageUrl
                                ? bannerImageUrl?.includes("base64")
                                  ? bannerImageUrl
                                  : bannerImageUrl == "null"
                                    ? ""
                                    : `${baseUrl}uploads/${bannerImageUrl}`
                                : bannerimage
                              // : ""
                            }
                            className="mx-auto d-block img-fluid"
                            onClick={(e) => handleImageClickBanner(e)}
                          ></img>
                        </div>
                        <input
                          className="form-control d-none"
                          type="file"
                          name="banner" // Set the name to match the Multer field name
                          onChange={handleBannerChange}
                          ref={fileInputRefBanner}
                        />
                       
                        <p className="imgserve">Note - This image will serve as the marketplace thumbnail banner</p> 
                      </div>
                    </div>

                    {/* {isLogoShow(businessObj?.theme_color) ? (
                  <div className="mb-3 col-md-4">
                    <div className="boxbanner detailsdptextlogowrapper">
                      <label className="form-label detailsdptext DetailsSelectLogo">
                       Select Logo (Size : 150 x 150)
                      </label>
                      <div className="bannerbox2 AdddetailsdpContainer">
                        <img
                          src={
                            logoImageUrl
                              ? logoImageUrl?.includes("base64")
                                ? logoImageUrl
                                : logoImageUrl == "null"
                                ? ""
                                : `${baseUrl}uploads/${logoImageUrl}`
                              : avatar
                          }
                          className="mx-auto d-block"
                          onClick={handleImageClickLogo}
                        />
                      </div>
                      
                      <input
                        className="form-control d-none"
                        type="file"
                        name="logo" // Set the name to match the Multer field name
                        onChange={handleLogoChange}
                        ref={fileInputRefLogo}
                        accept=".png, .jpg, .jpeg"
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )} */}
                  </div>

                  <div className="AddDetailsNameDestiCom_Wrapper">
                    {/* <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">Name*</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        {...formik.getFieldProps("subTitle")}
                        onChange={formik.handleChange}
                      />
                      {formik.touched.subTitle && formik.errors.subTitle && (
                        <ErrorStyle className="error">
                          {formik.errors.subTitle}
                        </ErrorStyle>
                      )}
                    </div>

                    <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                      <label className="form-label">Designation* </label>
                      <input
                        {...formik.getFieldProps("designation")}
                        className="form-control"
                        type="text"
                        placeholder="Designation"
                      />
                      {formik.touched.designation &&
                        formik.errors.designation && (
                          <ErrorStyle className="error">
                            {formik.errors.designation}
                          </ErrorStyle>
                        )}
                    </div> */}

                    <div className="mb-3 col-md-4 AddDetailswidContainer">
                      <label className="form-label">Restaurant Name </label>
                      <input
                        value={title}
                        className="form-control"
                        type="text"
                        placeholder="Restaurant Name"
                        onChange={(e) => handleChangeCompanyName(e)}

                      />
                    </div>
                    <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                      <label className="form-label">
                        <input
                          type="checkbox"
                          checked={isShowBusinessName ? true : false}
                          onChange={(e) => handleIsShow(e)}
                        />
                        Show/Hide   
                      </label>
                    </div>
                    <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                    <label className="form-label">  
                      <input type="checkbox" checked={isDine ? true : false} onChange={(e) => handleIsDine(e)} /> Is dine in available?</label>
                    </div>
                  </div>

                  <div className="mb-3 col-md-12">
                    <label className="form-label">Description*</label>
                    <textarea
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder="Enter Description"
                    ></textarea>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <ErrorStyle className="error">
                          {formik.errors.description}
                        </ErrorStyle>
                      )}
                  </div>

                  <label className="form-label" style={{ fontSize: "17px" }}>
                    Settings
                  </label>

                  <div className="accordion" id="accordionExample">
                    {/* ///SERVICES-----------------services  */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={(e) => {

                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Cuisine</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          >
                            On/Off
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.services}
                            onClick={(e) => {
                              setIsEnabledServices(!isEnabledServices);
                              setOnState({
                                ...onState,
                                services: !onState.services,
                              });
                            }}
                            onChange={() => toggleDiv("div2")}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewBusinessDetilsCreate
                              setServicesArray={setServicesArray}
                              businessObj={businessObj}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* =========Menu============== */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Menu</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.restaurantmenu}
                            onClick={() => {
                              setIsRestaurant(!isRestaurant);
                              setOnState((prevState) => ({
                                ...prevState,
                                restaurantmenu: !prevState.restaurantmenu,
                              }));
                            }}
                            onChange={() => toggleDiv("div3")}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewRestaurantMenuCreate
                              setRestaurantArray={setRestaurantArray}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*------Review & Rating ------------ */}

                    {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingZero">
                       
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              Review & Rating
                            </h5>
                          </div>

                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={() => toggleDiv("div8")}
                            checked={onState?.googlereview}
                            onClick={(e) => {
                              setIsEnabledGoogleReview(!isEnabledGoogleReview);
                              setOnState({
                                ...onState,
                                googlereview: !onState.googlereview,
                              });
                            }}

                          />
                        </div>
                      </h2>
                     
                    </div> */}

                    {/*------ Order ------------ */}
                    <div className="accordion-item ContactFormPadIssue">
                      <h2 className="accordion-header">
                        <div className="d-flex border-0">
                          <h5 style={{ fontWeight: "400" }} border-0>
                            Order
                          </h5>
                        </div>

                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            // onClick={(e) => {
                            //   setIsEnabledOrders(!isEnabledOrders);
                            //   setOnState((prevState) => ({
                            //     ...prevState,
                            //     orders: !prevState.orders,
                            //   }));
                            // }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.orders}
                            disabled
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      ></div>
                    </div>

                    {/* =====Opening Hours===== */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingsix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#six"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Opening Hours</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.businessHours}
                            onChange={() => toggleDiv("div6")}
                            onClick={(e) => {
                              setIsBusinessHourObject(!IsBusinessHourObject);
                              setOnState({
                                ...onState,
                                businessHours: !onState.businessHours,
                              });
                            }}
                          />
                        </div>
                      </h2>
                      <div
                        id="six"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingsix"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          style={{ padding: "10px 0" }}
                        >
                          <div className="row" style={{ padding: "10px 0" }}>
                            <NewRestaurantHour
                              setAppointmentArray={setAppointmentArray}
                              appointmentSlotDuration={appointmentSlotDuration}
                              setAppointmentSlotDuration={
                                setAppointmentSlotDuration
                              }
                              setBusinessHourObject={setBusinessHourObject}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ======gallery=========== */}
                    <div className="accordion-item">
                      <div className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#seven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Gallery</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.gallery}
                            onClick={(e) => {
                              SetIsGallaryEnable(!isGallaryEnable);
                              setOnState({
                                ...onState,
                                gallery: !onState.gallery,
                              });
                            }}
                            onChange={() => toggleDiv("div7")}
                          />
                        </div>
                      </div>
                      <div
                        id="seven"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingseven"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewGallaryformwrapper"
                        >
                          <div className="row">
                            <NewGallerycreate
                              businessId={businessId}
                              existingGalleryImages={existingGalleryImages}
                              setExistingGalleryImages={
                                setExistingGalleryImages
                              }
                              gallaryObject={gallaryObject}
                              galleryData={galleryData}
                              setGalleryData={setGalleryData}
                              galleryView={galleryView}
                              setGalleryView={setGalleryView}
                              setGallaryObject={setGallaryObject}
                              isGallaryEnable={isGallaryEnable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* =========social Media=================== */}
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#five"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Social Media</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.socials}
                            onChange={() => toggleDiv("div5")}
                            onClick={() => {
                              setIsSocial(!isSocial);
                              setOnState({
                                ...onState,
                                socials: !onState.socials,
                              });
                            }}
                          />
                        </div>
                      </h2>
                      <div
                        id="five"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                          style={{ padding: "15px 0" }}
                        >
                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            <NewSocialmediaCreate
                              setSocialMediaData={setSocialMediaData}
                              socialMediaData={socialMediaData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*------ contact INFO ------------ */}

                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          onClick={(e) => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              Contact Info
                            </h5>
                          </div>
                        </button>

                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            onClick={(e) => {
                              setIsEnabledContactInfo(!isEnabledContactInfo);
                              setOnState({
                                ...onState,
                                contactInfo: !onState.contactInfo,
                              });
                            }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            onChange={() => toggleDiv("div1")}
                            checked={onState?.contactInfo}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body AddDetailsContactInfoInnerForm">
                          <div className="row MobileDetailsWrapper">
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                Mobile Number*<span style={{ fontSize: "10px" }}>(Enter whatsapp no only)</span>
                              </label>

                              <PhoneInput
                                country={"in"}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.contactInfoPhone}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                    setInvalidPhoneErr("");
                                  } else {
                                    formik.setFieldValue(
                                      "contactInfoPhone",
                                      value
                                    );
                                    setInvalidPhoneErr("");
                                  }
                                }}
                                onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                className="DetailsInfoPhoneInput"
                              />

                              {formik.touched.contactInfoPhone &&
                                formik.errors.contactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidPhoneErr && <ErrorStyle className="error">
                                {invalidPhoneErr}
                              </ErrorStyle>}
                            </div>
                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">
                                Alternative Mobile Number
                              </label>

                              <PhoneInput
                                country={"in"}
                                enableSearch={true}
                                countryCodeEditable={false}
                                value={formik.values.optionalContactInfoPhone}
                                onChange={(value, country) => {
                                  if (country.dialCode) {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      `+${country.dialCode}-${value.substring(
                                        country.dialCode.length
                                      )}`
                                    );
                                    setCountryName(country.name);
                                  } else {
                                    formik.setFieldValue(
                                      "optionalContactInfoPhone",
                                      value
                                    );
                                  }
                                }}
                                // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                // {...formik.getFieldProps('optionalContactInfoPhone')}
                                className="DetailsInfoPhoneInput"
                                isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                              />
                              {formik.touched.optionalContactInfoPhone &&
                                formik.errors.optionalContactInfoPhone && (
                                  <ErrorStyle className="error">
                                    {formik.errors.optionalContactInfoPhone}
                                  </ErrorStyle>
                                )}
                              {invalidOptPhoneErr && <ErrorStyle className="error">
                                {invalidOptPhoneErr}
                              </ErrorStyle>}
                            </div>

                            <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                              <label className="form-label">Email*</label>
                              <input
                                {...formik.getFieldProps("contactInfoEmail")}
                                className="form-control"
                                type="text"
                                placeholder="Email"
                              />
                              {formik.touched.contactInfoEmail &&
                                formik.errors.contactInfoEmail && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoEmail}
                                  </ErrorStyle>
                                )}
                            </div>
                            <div className="mb-3 col-md-12">
                              <label className="form-label">Web Url</label>
                              <input
                                {...formik.getFieldProps("contactInfoWebUrl")}
                                // value={contactInfoWebUrl}
                                className="form-control"
                                type="text"
                                placeholder="web url"
                              // onChange={(e) =>
                              //   setContactInfoWebUrl(e.target.value)
                              // }
                              />
                              {formik.touched.contactInfoWebUrl &&
                                formik.errors.contactInfoWebUrl && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoWebUrl}
                                  </ErrorStyle>
                                )}
                            </div>
                            <div className="mb-3 col-md-12">
                              <label className="form-label">Address*</label>
                              <textarea
                                value={contactInfoAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder="Address"
                                onChange={(e) =>
                                  handleContactInfoAddressChange(e)
                                }
                                // onBlur={handleGetLatLang}
                                disabled={isAddressDisabled}
                              ></textarea>
                              {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                              {formik.errors.contactInfoAddress && (
                                <ErrorStyle className="error">
                                  {formik.errors.contactInfoAddress}
                                </ErrorStyle>
                              )}
                             <div style={{display:"flex", justifyContent:"space-between", marginTop:"6px"}}> <a className="btn btn-info-detail" onClick={handleGetLatLang}>Submit</a><label className="form-label">
                              <input type="checkbox" checked={isShowAddress ? true : false} onChange={(e) => handleIsShowAddress(e)} /> Show/Hide</label>
                           </div>
                              {latLngError && <p className="text-danger mt-2" style={{fontSize: "12px"}}>{latLngError}</p>}
                              {isAddressSubmit ? (
                                <CircularLoader size={40} />
                              ) : (
                                addressPlaceId && formik.values.contactInfoAddress && (
                                  <div className="">
                                    <div className="">
                                      <a
                                        href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-link"
                                        style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                      >
                                        Please check & verify your business address on Google Map.
                                      </a>
                                    </div>
                                    <div className="">
                                      {isAddressVerified ? (
                                        <>
                                          <button className="btn btn-success me-2 selectreviewverified" disabled>
                                            Address Verified
                                          </button>
                                          <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                            Edit
                                          </button>
                                        </>
                                      ) : (
                                        <button className="btn btn-success me-2 selectreview 
                                          verifybusinesswtd" onClick={handleCorrectAddressData}>
                                          Verify Address
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                           
                            <div className="mb-3 col-md-12">
                              <label className="form-label">Alternative Address</label>
                              <textarea
                                value={contactInfoOptAddress}
                                className="form-control"
                                rows="3"
                                maxLength={250}
                                placeholder="Address"
                                onChange={(e) =>
                                  handleContactInfoOptAddressChange(e)
                                }
                              ></textarea>
                              {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    {/* =============appointments============ */}
                    {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Appointments</h5>
                          <div class="form-check form-switch">
                            <label
                              class="form-check-label"
                            >
                              On/Off
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.appointment}
                              disabled={!onState?.businessHours}
                              onChange={() => toggleDiv("div4")}
                              onClick={() => {
                                setIsAppointment(!isAppointment);
                                setOnState({
                                  ...onState,
                                  appointment: !onState?.appointment,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </h2>

                    </div> */}
                    
                  </div>

                  <div className="row Adddetails_saveprev_ButtonWrapper">
                    <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                      <button
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                        id="changeblockprenxtbtn"
                        type="button"
                        onClick={handlePreviousClick}
                      >
                        Previous
                      </button>

                      {isSubmit ? (
                        <CircularLoader size={40} />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                          id="changeblockprenxtbtn"
                          onClick={(e) => handleSubmitWithoutTabChange(e)}
                        >
                          Save & Preview
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ////////////////////////////    restaurant detIALS PAGE End ////////////////////////////////////

              themeNumber == 20 ? (
                /////////////////////////////////////// minimarketplace  1945 - 2860////////////////////////////////




                <div className="tile">
                  <form onSubmit={formik.handleSubmit} className="row">
                  <div className="flex items-center i-agree">
                        <div className="mr-2">
                          <label>Would you like to publish your services to the marketplace?</label>
                        </div>
                        <div className="mr-2">
                          <input
                            type="radio"
                            name="is_allow_in_search"
                            value={1}
                            id="yes"
                            checked={formik.values.is_allow_in_search == 1}
                            onChange={handleRadioChange}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="yes" className="mr-4">Yes</label>
                        </div>
                        <div className="mr-2">
                          <input
                            type="radio"
                            name="is_allow_in_search"
                            value={0}
                            id="no"
                            checked={formik.values.is_allow_in_search == 0}
                            onChange={handleRadioChange}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="no">No</label>
                        </div>

                      </div>
                    <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                      {isBannerShow(businessObj?.theme_color) ? (
                        <div class="mb-4 col-md-8">
                          <div className="boxbanner DetailsSelectBanner_wrapper">
                            <label className="form-label detailsdptext">
                            Select Banner <span style={{fontSize:"12px"}}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                                <span className="text-danger">
                                  {isBannerRequired ? " *" : " "}
                                </span>
                            </label>
                            <div className="bannerbox">
                              <img
                                src={
                                  bannerImageUrl
                                    ? bannerImageUrl?.includes("base64")
                                      ? bannerImageUrl
                                      : bannerImageUrl == "null"
                                        ? ""
                                        : `${baseUrl}uploads/${bannerImageUrl}`
                                    : bannerimage
                                  // : ""
                                }
                                className="mx-auto d-block img-fluid"
                                onClick={(e) => handleImageClickBanner(e)}
                              ></img>
                            </div>
                            <input
                              className="form-control d-none"
                              type="file"
                              name="banner" // Set the name to match the Multer field name
                              onChange={handleBannerChange}
                              ref={fileInputRefBanner}
                            />
                            <p className="imgserve">Note - This image will serve as the marketplace thumbnail banner</p> 
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* <div className="row justify-content-end px-0 mb-4">
           
            <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
              {isSubmit ? (
                <CircularLoader size={40} />
              ) : (
                <button
                  type="button"
                  className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {"Save & Next"}
                </button>
              )}
            </div>
          </div> */}
                      {/* <div class="mb-3 col-md-12">
          <div className="sec-gap">
            <h3 className="templatheading">Your Page Code</h3>
            <div class="input-group mb-1">
              <span class="input-group-text" id="basic-addon3">
                {`https://vmycards.com/users/${businessObj?.slug}`}
              </span>
              <input
                value={businessObj?.slug}
                type="text"
                class="form-control"
                disabled={true}
              />
            </div>
            <small className="etidspan">
              *minimum 5 characters required.
            </small>
          </div>
        </div> */}

                      {isLogoShow(businessObj?.theme_color) ? (
                        <div className="mb-3 col-md-4">
                          {themeNumber != 20 ? (
                            <div className="boxbanner detailsdptextlogowrapper">
                              <label className="form-label detailsdptext DetailsSelectLogo">
                                Select Logo (Size : 150 x 150)
                              </label>
                              <div className="bannerbox2 AdddetailsdpContainer">
                                <img
                                  src={
                                    logoImageUrl
                                      ? logoImageUrl?.includes("base64")
                                        ? logoImageUrl
                                        : logoImageUrl == "null"
                                          ? ""
                                          : `${baseUrl}uploads/${logoImageUrl}`
                                      : avatar
                                  }
                                  className="mx-auto d-block"
                                  onClick={handleImageClickLogo}
                                />
                              </div>
                              {/* <p>
                <strong>Size : (150 x 150)</strong>
              </p> */}
                              <input
                                className="form-control d-none"
                                type="file"
                                name="logo" // Set the name to match the Multer field name
                                onChange={handleLogoChange}
                                ref={fileInputRefLogo}
                                accept=".png, .jpg, .jpeg"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="AddDetailsNameDestiCom_Wrapper">
                      <div className="mb-3 col-md-4 AddDetailswidContainer">
                        <label className="form-label">Name*</label>
                        <input
                          // value={subTitle}
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          // maxLength="40"
                          // onChange={(e) => setSubTitle(e.target.value)}
                          {...formik.getFieldProps("subTitle")}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.subTitle && formik.errors.subTitle && (
                          <ErrorStyle className="error">
                            {formik.errors.subTitle}
                          </ErrorStyle>
                        )}
                      </div>

                      {/* <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                  <label className="form-label">Designation* </label>
                  <input
                    // value={designation}
                    {...formik.getFieldProps("designation")}
                    className="form-control"
                    type="text"
                    placeholder="Designation"
                    // onChange={(e) => setDesignation(e.target.value)}
                  />
                  {formik.touched.designation &&
                    formik.errors.designation && (
                      <ErrorStyle className="error">
                        {formik.errors.designation}
                      </ErrorStyle>
                    )}
                </div> */}

                      <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                        <label className="form-label">Company Name*</label>
                        <input
                          value={title}
                          className="form-control"
                          type="text"
                          placeholder="Company Name"
                          // onChange={(e) => setTitle(e.target.value)}
                          onChange={(e) => handleChangeCompanyName(e)}
                        />
                      </div>
                      <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                      <label className="form-label">
                        <input
                          type="checkbox"
                          checked={isShowBusinessName ? true : false}
                          onChange={(e) => handleIsShow(e)}
                        />
                        Show/Hide 
                      </label>
                    </div>
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Description*</label>
                      <textarea
                        // value={description}
                        {...formik.getFieldProps("description")}
                        className="form-control"
                        rows="4"
                        placeholder="Enter Description"
                      // onChange={(e) => setDescription(e.target.value)}
                      >
                        {/* Hi, I'm a graphic designer ! */}
                      </textarea>
                      {formik.touched.description &&
                        formik.errors.description && (
                          <ErrorStyle className="error">
                            {formik.errors.description}
                          </ErrorStyle>
                        )}
                    </div>
                    {/* <div class="mb-3 col-md-12 ">
          <div class="form-group">
            <label class="form-label">Personalized link:</label>
            <div class="input-group">
              <input
                type="text"
                className="form-control"
                readonly=""
                value=" https://vmycards.com/freedom-5"
              />
              <input
                className="input-group-text text-start"
                placeholder="Enter Slug"
                name="slug"
                type="text"
                onChange={(e) => setSlug(e.target.value)}
                value={slug}
              />
            </div>
          </div>
        </div> */}
                    <label className="form-label" style={{ fontSize: "17px" }}>
                      Settings
                    </label>
                    {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            onClick={(e) => {
                              // setIsEnabledContactInfo(!isEnabledContactInfo);
                              // setOnState({
                              //   ...onState,
                              //   contactInfo: !onState.contactInfo,
                              // });
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }} border-0>
                                Contact Info
                              </h5>
                            </div>
                          </button>

                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              On/Off
                            </label>
                            <input
                              onClick={(e) => {
                                setIsEnabledContactInfo(!isEnabledContactInfo);
                                setOnState({
                                  ...onState,
                                  contactInfo: !onState.contactInfo,
                                });
                              }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              onChange={() => toggleDiv("div1")}
                              checked={onState?.contactInfo}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body AddDetailsContactInfoInnerForm">
                            {/* {divStates.div1 && ( */}
                            <div className="row MobileDetailsWrapper">
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  Mobile Number*
                                </label>
                                <PhoneInput
                                  country={"in"}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.contactInfoPhone}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );

                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.contactInfoPhone &&
                                  formik.errors.contactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidPhoneErr && <ErrorStyle className="error">
                                  {invalidPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  Alternative Mobile Number
                                </label>

                                <PhoneInput
                                  country={"in"}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.optionalContactInfoPhone}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );
                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                  onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                  // {...formik.getFieldProps('optionalContactInfoPhone')}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.optionalContactInfoPhone &&
                                  formik.errors.optionalContactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.optionalContactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidOptPhoneErr && <ErrorStyle className="error">
                                  {invalidOptPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">Email*</label>
                                <input
                                  {...formik.getFieldProps("contactInfoEmail")}
                                  // value={contactInfoEmail}
                                  // value={formik.values.contactInfoEmail}
                                  className="form-control"
                                  type="text"
                                  placeholder="Email"
                                // onChange={(e) =>
                                //   setContactInfoEmail(e.target.value)
                                // }
                                // onChange={formik.handleChange('contactInfoEmail')}
                                />
                                {formik.touched.contactInfoEmail &&
                                  formik.errors.contactInfoEmail && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoEmail}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">Web Url</label>
                                <input
                                  {...formik.getFieldProps("contactInfoWebUrl")}
                                  // value={contactInfoWebUrl}
                                  className="form-control"
                                  type="text"
                                  placeholder="web url"
                                // onChange={(e) =>
                                //   setContactInfoWebUrl(e.target.value)
                                // }
                                />
                                {formik.touched.contactInfoWebUrl &&
                                  formik.errors.contactInfoWebUrl && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoWebUrl}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">Address*</label>
                                <textarea
                                  value={contactInfoAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleContactInfoAddressChange(e)
                                  }
                                  // onBlur={handleGetLatLang}
                                  disabled={isAddressDisabled}
                                ></textarea>
                                {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                                {formik.errors.contactInfoAddress && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoAddress}
                                  </ErrorStyle>
                                )}
                              <div style={{display:"flex", justifyContent:"space-between"}}>  <a className="btn btn-info-detail" onClick={handleGetLatLang}>Submit</a> <label className="form-label">
                                <input type="checkbox" checked={isShowAddress ? true : false} onChange={(e) => handleIsShowAddress(e)} /> Show/Hide</label>
                              </div>
                                {latLngError && <p className="text-danger mt-2" style={{fontSize: "12px"}}>{latLngError}</p>}
                                {isAddressSubmit ? (
                                  <CircularLoader size={40} />
                                ) : (
                                  addressPlaceId && formik.values.contactInfoAddress && (
                                    <div className="">
                                      <div className="">
                                        <a
                                          href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-link"
                                          style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                        >
                                          Please check & verify your business address on Google Map.
                                        </a>
                                      </div>
                                      <div className="">
                                        {isAddressVerified ? (
                                          <>
                                            <button className="btn btn-success me-2 selectreviewverified" disabled>
                                              Address Verified
                                            </button>
                                            <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                              Edit
                                            </button>
                                          </>
                                        ) : (
                                          <button className="btn btn-success me-2 selectreview 
                                            verifybusinesswtd" onClick={handleCorrectAddressData}>
                                            Verify Address
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            
                              <div className="mb-3 col-md-12">
                                <label className="form-label">Alternative Address</label>
                                <textarea
                                  value={contactInfoOptAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleContactInfoOptAddressChange(e)
                                  }
                                ></textarea>
                                {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>





                      {/* ///SERVICES-----------------services  */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            onClick={(e) => {
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>Category</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            >
                              On/Off
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.services}
                              onClick={(e) => {
                                setIsEnabledServices(!isEnabledServices);
                                setOnState({
                                  ...onState,
                                  services: !onState.services,
                                });
                              }}
                              onChange={() => toggleDiv("div2")}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewBusinessDetilsCreate
                                setServicesArray={setServicesArray}
                                businessObj={businessObj}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ========= MINI Marketplace Products ============== */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() => { }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>Products</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label className="form-check-label">On/Off</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.miniproduct}
                              onClick={() => {
                                setIsMinimktProduct(!isMinimktProduct);
                                setOnState((prevState) => ({
                                  ...prevState,
                                  miniproduct: !prevState.miniproduct,
                                }));
                              }}
                              onChange={() => toggleDiv("div9")}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewminimarketProductCreate
                                setMiniMarketArray={setMiniMarketArray}
                                servicesArray={servicesArray}
                                handleFetchById={handleFetchById}
                                handleSubmitWithoutTabChange={handleSubmitWithoutTabChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>



                      {/* =========social=================== */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#five"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() => {
                              // setIsSocial(!isSocial);
                              // setOnState({
                              //   ...onState,
                              //   socials: !onState.socials,
                              // });
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>Social Media</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              On/Off
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.socials}
                              onChange={() => toggleDiv("div5")}
                              onClick={() => {
                                setIsSocial(!isSocial);
                                setOnState({
                                  ...onState,
                                  socials: !onState.socials,
                                });
                              }}
                            />
                          </div>
                        </h2>
                        <div
                          id="five"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                            style={{ padding: "15px 0" }}
                          >
                            {/* {divStates.div5 && ( */}
                            <div
                              className="row"
                              style={{ justifyContent: "center" }}
                            >
                              <NewSocialmediaCreate
                                setSocialMediaData={setSocialMediaData}
                                socialMediaData={socialMediaData}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      {/* =====business hour===== */}
                     

                    </div>

                    <div className="row Adddetails_saveprev_ButtonWrapper">
                      <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                        <button
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                          id="changeblockprenxtbtn"
                          type="button"
                          onClick={handlePreviousClick}
                        >
                          Previous
                        </button>
                        {/* </div>
            <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                        {isSubmit ? (
                          <CircularLoader size={40} />
                        ) : (
                          <button
                            type="button"
                            className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                            id="changeblockprenxtbtn"
                            onClick={(e) => handleSubmitWithoutTabChange(e)}
                          >
                            Save & Preview
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>



                ////////////////////////////////////end mini marketplace ////////////////////////////

              ) : (
                themeNumber == 22 ? <> <div className="tile">
                  <form onSubmit={formik.handleSubmit} className="row">
                  <div className="flex items-center i-agree">
                        <div className="mr-2">
                          <label>Would you like to publish your services to the marketplace?</label>
                        </div>
                        <div className="mr-2">
                          <input
                            type="radio"
                            name="is_allow_in_search"
                            value={1}
                            id="yes"
                            checked={formik.values.is_allow_in_search == 1}
                            onChange={handleRadioChange}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="yes" className="mr-4">Yes</label>
                        </div>
                        <div className="mr-2">
                          <input
                            type="radio"
                            name="is_allow_in_search"
                            value={0}
                            id="no"
                            checked={formik.values.is_allow_in_search == 0}
                            onChange={handleRadioChange}
                            onBlur={formik.handleBlur}
                          />
                          <label htmlFor="no">No</label>
                        </div>

                      </div>
                    <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                      {isBannerShow(businessObj?.theme_color) ? (
                        <div class="mb-4 col-md-8">
                          <div className="boxbanner DetailsSelectBanner_wrapper">
                            <label className="form-label detailsdptext">
                            Select Banner <span style={{fontSize:"12px"}}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                                <span className="text-danger">
                                  {isBannerRequired ? " *" : " "}
                                </span>
                            </label>
                            <div className="bannerbox">
                              <img
                                src={
                                  bannerImageUrl
                                    ? bannerImageUrl?.includes("base64")
                                      ? bannerImageUrl
                                      : bannerImageUrl == "null"
                                        ? ""
                                        : `${baseUrl}uploads/${bannerImageUrl}`
                                    : bannerimage
                                  // : ""
                                }
                                className="mx-auto d-block img-fluid"
                                onClick={(e) => handleImageClickBanner(e)}
                              ></img>
                            </div>
                            <input
                              className="form-control d-none"
                              type="file"
                              name="banner" // Set the name to match the Multer field name
                              onChange={handleBannerChange}
                              ref={fileInputRefBanner}
                            />
                            <p className="imgserve">Note - This image will serve as the marketplace thumbnail banner</p> 
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* <div className="row justify-content-end px-0 mb-4">
         
          <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
            {isSubmit ? (
              <CircularLoader size={40} />
            ) : (
              <button
                type="button"
                className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                onClick={(e) => handleSubmit(e)}
              >
                {"Save & Next"}
              </button>
            )}
          </div>
        </div> */}
                      {/* <div class="mb-3 col-md-12">
        <div className="sec-gap">
          <h3 className="templatheading">Your Page Code</h3>
          <div class="input-group mb-1">
            <span class="input-group-text" id="basic-addon3">
              {`https://vmycards.com/users/${businessObj?.slug}`}
            </span>
            <input
              value={businessObj?.slug}
              type="text"
              class="form-control"
              disabled={true}
            />
          </div>
          <small className="etidspan">
            *minimum 5 characters required.
          </small>
        </div>
      </div> */}

                      {isLogoShow(businessObj?.theme_color) ? (
                        <div className="mb-3 col-md-4">
                          {themeNumber != 18 ? (
                            <div className="boxbanner detailsdptextlogowrapper">
                              <label className="form-label detailsdptext DetailsSelectLogo">
                                Select Logo (Size : 150 x 150)
                              </label>
                              <div className="bannerbox2 AdddetailsdpContainer">
                                <img
                                  src={
                                    logoImageUrl
                                      ? logoImageUrl?.includes("base64")
                                        ? logoImageUrl
                                        : logoImageUrl == "null"
                                          ? ""
                                          : `${baseUrl}uploads/${logoImageUrl}`
                                      : avatar
                                  }
                                  className="mx-auto d-block"
                                  onClick={handleImageClickLogo}
                                />
                              </div>
                              {/* <p>
              <strong>Size : (150 x 150)</strong>
            </p> */}
                              <input
                                className="form-control d-none"
                                type="file"
                                name="logo" // Set the name to match the Multer field name
                                onChange={handleLogoChange}
                                ref={fileInputRefLogo}
                                accept=".png, .jpg, .jpeg"
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="AddDetailsNameDestiCom_Wrapper">
                      <div className="mb-3 col-md-4 AddDetailswidContainer">
                        <label className="form-label">Name*</label>
                        <input
                          // value={subTitle}
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          // maxLength="40"
                          // onChange={(e) => setSubTitle(e.target.value)}
                          {...formik.getFieldProps("subTitle")}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.subTitle && formik.errors.subTitle && (
                          <ErrorStyle className="error">
                            {formik.errors.subTitle}
                          </ErrorStyle>
                        )}
                      </div>
                      {/* 
                    <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                      <label className="form-label">Designation* </label>
                      <input
                        // value={designation}
                        {...formik.getFieldProps("designation")}
                        className="form-control"
                        type="text"
                        placeholder="Designation"
                      // onChange={(e) => setDesignation(e.target.value)}
                      />
                      {formik.touched.designation &&
                        formik.errors.designation && (
                          <ErrorStyle className="error">
                            {formik.errors.designation}
                          </ErrorStyle>
                        )}
                    </div> */}

                      <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                        <label className="form-label">Company Name*</label>
                        <input
                          value={title}
                          className="form-control"
                          type="text"
                          placeholder="Company Name"
                          // onChange={(e) => setTitle(e.target.value)}
                          onChange={(e) => handleChangeCompanyName(e)}
                        />
                      </div>
                      <div className="mb-3 col-md-4 AddDetailswidContainer dine_available">
                      <label className="form-label">
                        <input
                          type="checkbox"
                          checked={isShowBusinessName ? true : false}
                          onChange={(e) => handleIsShow(e)}
                        />
                        Show/Hide 
                      </label>
                    </div>
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label">Description*</label>
                      <textarea
                        // value={description}
                        {...formik.getFieldProps("description")}
                        className="form-control"
                        rows="4"
                        placeholder="Enter Description"
                      // onChange={(e) => setDescription(e.target.value)}
                      >
                        {/* Hi, I'm a graphic designer ! */}
                      </textarea>
                      {formik.touched.description &&
                        formik.errors.description && (
                          <ErrorStyle className="error">
                            {formik.errors.description}
                          </ErrorStyle>
                        )}
                    </div>
                    {/* <div class="mb-3 col-md-12 ">
        <div class="form-group">
          <label class="form-label">Personalized link:</label>
          <div class="input-group">
            <input
              type="text"
              className="form-control"
              readonly=""
              value=" https://vmycards.com/freedom-5"
            />
            <input
              className="input-group-text text-start"
              placeholder="Enter Slug"
              name="slug"
              type="text"
              onChange={(e) => setSlug(e.target.value)}
              value={slug}
            />
          </div>
        </div>
      </div> */}
                    <label className="form-label" style={{ fontSize: "17px" }}>
                      Settings
                    </label>
                    {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            onClick={(e) => {
                              // setIsEnabledContactInfo(!isEnabledContactInfo);
                              // setOnState({
                              //   ...onState,
                              //   contactInfo: !onState.contactInfo,
                              // });
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }} border-0>
                                Contact Info
                              </h5>
                            </div>
                          </button>

                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              On/Off
                            </label>
                            <input
                              onClick={(e) => {
                                setIsEnabledContactInfo(!isEnabledContactInfo);
                                setOnState({
                                  ...onState,
                                  contactInfo: !onState.contactInfo,
                                });
                              }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              onChange={() => toggleDiv("div1")}
                              checked={onState?.contactInfo}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body AddDetailsContactInfoInnerForm">
                            {/* {divStates.div1 && ( */}
                            <div className="row MobileDetailsWrapper">
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  Mobile Number*
                                </label>
                                {/* <input
                    value={contactInfoPhone}
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setContactInfoPhone(e.target.value)
                    }
                    placeholder="Phone Number"
                  /> */}
                                <PhoneInput
                                  country={"in"}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.contactInfoPhone}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );
                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "contactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  // onBlur={formik.handleBlur("contactInfoPhone")}
                                  onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                  // {...formik.getFieldProps('contactInfoPhone')}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.contactInfoPhone &&
                                  formik.errors.contactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidPhoneErr && <ErrorStyle className="error">
                                  {invalidPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">
                                  Alternative Mobile Number
                                </label>

                                <PhoneInput
                                  country={"in"}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={formik.values.optionalContactInfoPhone}
                                  onChange={(value, country) => {
                                    if (country.dialCode) {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        `+${country.dialCode}-${value.substring(
                                          country.dialCode.length
                                        )}`
                                      );
                                      setCountryName(country.name);
                                    } else {
                                      formik.setFieldValue(
                                        "optionalContactInfoPhone",
                                        value
                                      );
                                    }
                                  }}
                                  // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                  onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                  // {...formik.getFieldProps('optionalContactInfoPhone')}
                                  className="DetailsInfoPhoneInput"
                                  isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                />
                                {formik.touched.optionalContactInfoPhone &&
                                  formik.errors.optionalContactInfoPhone && (
                                    <ErrorStyle className="error">
                                      {formik.errors.optionalContactInfoPhone}
                                    </ErrorStyle>
                                  )}
                                {invalidOptPhoneErr && <ErrorStyle className="error">
                                  {invalidOptPhoneErr}
                                </ErrorStyle>}
                              </div>
                              <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                <label className="form-label">Email*</label>
                                <input
                                  {...formik.getFieldProps("contactInfoEmail")}
                                  // value={contactInfoEmail}
                                  // value={formik.values.contactInfoEmail}
                                  className="form-control"
                                  type="text"
                                  placeholder="Email"
                                // onChange={(e) =>
                                //   setContactInfoEmail(e.target.value)
                                // }
                                // onChange={formik.handleChange('contactInfoEmail')}
                                />
                                {formik.touched.contactInfoEmail &&
                                  formik.errors.contactInfoEmail && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoEmail}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">Web Url</label>
                                <input
                                  {...formik.getFieldProps("contactInfoWebUrl")}
                                  // value={contactInfoWebUrl}
                                  className="form-control"
                                  type="text"
                                  placeholder="web url"
                                // onChange={(e) =>
                                //   setContactInfoWebUrl(e.target.value)
                                // }
                                />
                                {formik.touched.contactInfoWebUrl &&
                                  formik.errors.contactInfoWebUrl && (
                                    <ErrorStyle className="error">
                                      {formik.errors.contactInfoWebUrl}
                                    </ErrorStyle>
                                  )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">Address*</label>
                                <textarea
                                  value={contactInfoAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleContactInfoAddressChange(e)
                                  }
                                  // onBlur={handleGetLatLang}
                                  disabled={isAddressDisabled}
                                ></textarea>
                                {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                                {formik.errors.contactInfoAddress && (
                                  <ErrorStyle className="error">
                                    {formik.errors.contactInfoAddress}
                                  </ErrorStyle>
                                )}
                                <a className="btn btn-info-detail" onClick={handleGetLatLang}>Submit</a>
                                {latLngError && <p className="text-danger mt-2" style={{fontSize: "12px"}}>{latLngError}</p>}
                                {isAddressSubmit ? (
                                  <CircularLoader size={40} />
                                ) : (
                                  addressPlaceId && formik.values.contactInfoAddress && (
                                    <div className="">
                                      <div className="">
                                        <a
                                          href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-link"
                                          style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                        >
                                          Please check & verify your business address on Google Map.
                                        </a>
                                      </div>
                                      <div className="">
                                        {isAddressVerified ? (
                                          <>
                                            <button className="btn btn-success me-2 selectreviewverified" disabled>
                                              Address Verified
                                            </button>
                                            <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                              Edit
                                            </button>
                                          </>
                                        ) : (
                                          <button className="btn btn-success me-2 selectreview 
                                            verifybusinesswtd" onClick={handleCorrectAddressData}>
                                            Verify Address
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label">Alternative Address</label>
                                <textarea
                                  value={contactInfoOptAddress}
                                  className="form-control"
                                  rows="3"
                                  maxLength={250}
                                  placeholder="Address"
                                  onChange={(e) =>
                                    handleContactInfoOptAddressChange(e)
                                  }
                                ></textarea>
                                {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>

                      {/* =========Menu============== */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() => { }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>Cab Products</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label className="form-check-label">On/Off</label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.cabproduct}
                              onClick={() => {
                                setIsCabProduct(!isCabProduct);
                                setOnState({
                                  ...onState,
                                  cabproduct: !onState.cabproduct,
                                });
                              }}
                              onChange={() => toggleDiv("div9")}
                            />
                          </div>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                          >
                            <div className="row">
                              <NewCabProductCreate
                                setCabArray={setCabArray}
                                servicesArray={servicesArray}
                                handleFetchById={handleFetchById}
                                handleSubmitWithoutTabChange={handleSubmitWithoutTabChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>





                      {/* =========social=================== */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#five"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() => {
                              // setIsSocial(!isSocial);
                              // setOnState({
                              //   ...onState,
                              //   socials: !onState.socials,
                              // });
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>Social Media</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              On/Off
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.socials}
                              onChange={() => toggleDiv("div5")}
                              onClick={() => {
                                setIsSocial(!isSocial);
                                setOnState({
                                  ...onState,
                                  socials: !onState.socials,
                                });
                              }}
                            />
                          </div>
                        </h2>
                        <div
                          id="five"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFive"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                            style={{ padding: "15px 0" }}
                          >
                            {/* {divStates.div5 && ( */}
                            <div
                              className="row"
                              style={{ justifyContent: "center" }}
                            >
                              <NewSocialmediaCreate
                                setSocialMediaData={setSocialMediaData}
                                socialMediaData={socialMediaData}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingsix">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#six"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() => {
                              // setIsTermsConditions(!isTermsConditions);
                              // setOnState({
                              //   ...onState,
                              //   termsconditions: !onState.termsconditions,
                              // });
                            }}
                          >
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>Terms and Conditions</h5>
                            </div>
                          </button>
                          <div className="form-check form-switch">
                            <label
                              className="form-check-label"
                            // for="flexSwitchCheckDefault"
                            >
                              On/Off
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.termsconditions}
                              onChange={() => toggleDiv("div5")}
                              onClick={() => {
                                setIsTermsConditions(!isTermsConditions);
                                setOnState({
                                  ...onState,
                                  termsconditions: !onState.termsconditions,
                                });
                              }}
                            />
                          </div>
                        </h2>
                        <div
                          id="six"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingsix"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            id="AddnewServicesformwrapper"
                            style={{ padding: "15px 0" }}
                          >
                            {/* {divStates.div5 && ( */}
                            <div
                              className="row"
                              style={{ justifyContent: "center" }}
                            >
                              <TermsConditionsCreate
                                setTermsConditionData={setTermsConditionData}
                                termsConditionData={termsConditionData}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        </div>
                      </div>
                      

                      {formik.errors.is_allow_in_search && formik.touched.is_allow_in_search && (
                        <div style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_allow_in_search}</div>
                      )}
                    </div>

                    <div className="row Adddetails_saveprev_ButtonWrapper">
                      <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                        <button
                          className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                          id="changeblockprenxtbtn"
                          type="button"
                          onClick={handlePreviousClick}
                        >
                          Previous
                        </button>
                        {/* </div>
          <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                        {isSubmit ? (
                          <CircularLoader size={40} />
                        ) : (
                          <button
                            type="button"
                            className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                            id="changeblockprenxtbtn"
                            onClick={(e) => handleSubmitWithoutTabChange(e)}
                          >
                            Save & Preview
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div></> : <>
                  <div className="tile">
                    <form onSubmit={formik.handleSubmit} className="row">
                    <div className="flex items-center i-agree">
                          <div className="mr-2 ">
                            <label>Would you like to publish your services to the marketplace?</label>
                          </div>
                          <div className="mr-2">
                            <input
                              type="radio"
                              name="is_allow_in_search"
                              className="radiobtn"
                              value={1}
                              id="yes"
                              checked={formik.values.is_allow_in_search == 1}
                              onChange={handleRadioChange}
                              onBlur={formik.handleBlur}
                            />
                            <label htmlFor="yes" className="mr-4">Yes</label>
                          </div>
                          <div className="mr-2">
                            <input
                              className="radiobtn"
                              type="radio"
                              name="is_allow_in_search"
                              value={0}
                              id="no"
                              checked={formik.values.is_allow_in_search == 0}
                              onChange={handleRadioChange}
                              onBlur={formik.handleBlur}
                            />
                            <label htmlFor="no">No</label>
                          </div>

                        </div>
                      <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                        {isBannerShow(businessObj?.theme_color) ? (
                          <div class="mb-4 col-md-8">
                            <div className="boxbanner DetailsSelectBanner_wrapper">
                              <label className="form-label detailsdptext">
                               Select Banner <span style={{fontSize:"12px"}}>{getLogoSizeLabel(businessObj?.theme_color)}</span>
                                <span className="text-danger">
                                  {isBannerRequired ? " *" : " "}
                                </span>
                              </label>
                              <div className="bannerbox 55">
                                <img
                                  src={
                                    bannerImageUrl
                                      ? bannerImageUrl?.includes("base64")
                                        ? bannerImageUrl
                                        : bannerImageUrl == "null"
                                          ? ""
                                          : `${baseUrl}uploads/${bannerImageUrl}`
                                      : bannerimage
                                    // : ""
                                  }
                                  className="mx-auto d-block img-fluid"
                                  onClick={(e) => handleImageClickBanner(e)}
                                ></img>
                              </div>
                              <input
                                className="form-control d-none"
                                type="file"
                                name="banner" // Set the name to match the Multer field name
                                onChange={handleBannerChange}
                                ref={fileInputRefBanner}
                              />
                            <p className="imgserve">Note - This image will serve as the marketplace thumbnail banner</p> 
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* <div className="row justify-content-end px-0 mb-4">
           
            <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
              {isSubmit ? (
                <CircularLoader size={40} />
              ) : (
                <button
                  type="button"
                  className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                  onClick={(e) => handleSubmit(e)}
                >
                  {"Save & Next"}
                </button>
              )}
            </div>
          </div> */}
                        {/* <div class="mb-3 col-md-12">
          <div className="sec-gap">
            <h3 className="templatheading">Your Page Code</h3>
            <div class="input-group mb-1">
              <span class="input-group-text" id="basic-addon3">
                {`https://vmycards.com/users/${businessObj?.slug}`}
              </span>
              <input
                value={businessObj?.slug}
                type="text"
                class="form-control"
                disabled={true}
              />
            </div>
            <small className="etidspan">
              *minimum 5 characters required.
            </small>
          </div>
        </div> */}

                        {isLogoShow(businessObj?.theme_color) ? (
                          <div className="mb-3 col-md-4">
                            {themeNumber != 18 && themeNumber != 35 && themeNumber != 41 && themeNumber != 36 && themeNumber != 48 && themeNumber != 53 && themeNumber != 51 ? (
                              <div className="boxbanner detailsdptextlogowrapper">
                                <label className="form-label detailsdptext DetailsSelectLogo">
                                  Select Logo (Size : 150 x 150)
                                </label>
                                <div className="bannerbox2 AdddetailsdpContainer">
                                  <img
                                    src={
                                      logoImageUrl
                                        ? logoImageUrl?.includes("base64")
                                          ? logoImageUrl
                                          : logoImageUrl == "null"
                                            ? ""
                                            : `${baseUrl}uploads/${logoImageUrl}`
                                        : avatar
                                    }
                                    className="mx-auto d-block"
                                    onClick={handleImageClickLogo}
                                  />
                                </div>
                                {/* <p>
                <strong>Size : (150 x 150)</strong>
              </p> */}
                                <input
                                  className="form-control d-none"
                                  type="file"
                                  name="logo" // Set the name to match the Multer field name
                                  onChange={handleLogoChange}
                                  ref={fileInputRefLogo}
                                  accept=".png, .jpg, .jpeg"
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="AddDetailsNameDestiCom_Wrapper">
                        <div className="mb-3 col-md-4 AddDetailswidContainer">
                          <label className="form-label">Name*</label>
                          <input
                            // value={subTitle}
                            className="form-control"
                            type="text"
                            placeholder="Name"
                            // maxLength="40"
                            // onChange={(e) => setSubTitle(e.target.value)}
                            {...formik.getFieldProps("subTitle")}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.subTitle && formik.errors.subTitle && (
                            <ErrorStyle className="error">
                              {formik.errors.subTitle}
                            </ErrorStyle>
                          )}
                        </div>

                        <div className="mb-3 col-md-4 AddDetailswidContainer AddDetailsMarContainer">
                          <label className="form-label">Designation* </label>
                          <input
                            // value={designation}
                            {...formik.getFieldProps("designation")}
                            className="form-control"
                            type="text"
                            placeholder="Designation"
                          // onChange={(e) => setDesignation(e.target.value)}
                          />
                          {formik.touched.designation &&
                            formik.errors.designation && (
                              <ErrorStyle className="error">
                                {formik.errors.designation}
                              </ErrorStyle>
                            )}
                        </div>

                        <div className="mb-3 col-md-4 AddDetailswidContainer">
                          <label className="form-label">Company Name*</label>
                          <input
                            value={title}
                            className="form-control"
                            type="text"
                            placeholder="Company Name"
                            // onChange={(e) => setTitle(e.target.value)}
                            onChange={(e) => handleChangeCompanyName(e)}
                          />
                        </div>
                      </div>

                      <div className="mb-3 col-md-12">
                        <label className="form-label">Description*</label>
                        <textarea
                          // value={description}
                          {...formik.getFieldProps("description")}
                          className="form-control"
                          rows="4"
                          placeholder="Enter Description"
                        // onChange={(e) => setDescription(e.target.value)}
                        >
                          {/* Hi, I'm a graphic designer ! */}
                        </textarea>
                        {formik.touched.description &&
                          formik.errors.description && (
                            <ErrorStyle className="error">
                              {formik.errors.description}
                            </ErrorStyle>
                          )}
                      </div>
                      {/* <div class="mb-3 col-md-12 ">
          <div class="form-group">
            <label class="form-label">Personalized link:</label>
            <div class="input-group">
              <input
                type="text"
                className="form-control"
                readonly=""
                value=" https://vmycards.com/freedom-5"
              />
              <input
                className="input-group-text text-start"
                placeholder="Enter Slug"
                name="slug"
                type="text"
                onChange={(e) => setSlug(e.target.value)}
                value={slug}
              />
            </div>
          </div>
        </div> */}
                      <label className="form-label" style={{ fontSize: "17px" }}>
                        Settings
                      </label>
                      {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                              onClick={(e) => {
                                // setIsEnabledContactInfo(!isEnabledContactInfo);
                                // setOnState({
                                //   ...onState,
                                //   contactInfo: !onState.contactInfo,
                                // });
                              }}
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }} border-0>
                                  Contact Info
                                </h5>
                              </div>
                            </button>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                On/Off
                              </label>
                              <input
                                onClick={(e) => {
                                  setIsEnabledContactInfo(!isEnabledContactInfo);
                                  setOnState({
                                    ...onState,
                                    contactInfo: !onState.contactInfo,
                                  });
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                onChange={() => toggleDiv("div1")}
                                checked={onState?.contactInfo}
                              />
                            </div>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body AddDetailsContactInfoInnerForm">
                              {/* {divStates.div1 && ( */}
                              <div className="row MobileDetailsWrapper">
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">
                                    Mobile Number*
                                  </label>
                                  {/* <input
                      value={contactInfoPhone}
                      type="number"
                      className="form-control"
                      onChange={(e) =>
                        setContactInfoPhone(e.target.value)
                      }
                      placeholder="Phone Number"
                    /> */}
                                  <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    value={formik.values.contactInfoPhone}
                                    onChange={(value, country) => {
                                      if (country.dialCode) {
                                        formik.setFieldValue(
                                          "contactInfoPhone",
                                          `+${country.dialCode}-${value.substring(
                                            country.dialCode.length
                                          )}`
                                        );
                                        setCountryName(country.name);
                                      } else {
                                        formik.setFieldValue(
                                          "contactInfoPhone",
                                          value
                                        );
                                      }
                                    }}
                                    // onBlur={formik.handleBlur("contactInfoPhone")}
                                    onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("contactInfoPhone")}
                                    // {...formik.getFieldProps('contactInfoPhone')}
                                    className="DetailsInfoPhoneInput"
                                    isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                  />
                                  {formik.touched.contactInfoPhone &&
                                    formik.errors.contactInfoPhone && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoPhone}
                                      </ErrorStyle>
                                    )}
                                  {invalidPhoneErr && <ErrorStyle className="error">
                                    {invalidPhoneErr}
                                  </ErrorStyle>}
                                </div>
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">
                                    Alternative Mobile Number
                                  </label>

                                  <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    value={formik.values.optionalContactInfoPhone}
                                    onChange={(value, country) => {
                                      if (country.dialCode) {
                                        formik.setFieldValue(
                                          "optionalContactInfoPhone",
                                          `+${country.dialCode}-${value.substring(
                                            country.dialCode.length
                                          )}`
                                        );
                                        setCountryName(country.name);
                                      } else {
                                        formik.setFieldValue(
                                          "optionalContactInfoPhone",
                                          value
                                        );
                                      }
                                    }}
                                    // onBlur={formik.handleBlur("optionalContactInfoPhone")}
                                    onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("optionalContactInfoPhone")}
                                    // {...formik.getFieldProps('optionalContactInfoPhone')}
                                    className="DetailsInfoPhoneInput"
                                    isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                                  />
                                  {formik.touched.optionalContactInfoPhone &&
                                    formik.errors.optionalContactInfoPhone && (
                                      <ErrorStyle className="error">
                                        {formik.errors.optionalContactInfoPhone}
                                      </ErrorStyle>
                                    )}
                                  {invalidOptPhoneErr && <ErrorStyle className="error">
                                    {invalidOptPhoneErr}
                                  </ErrorStyle>}
                                </div>
                                <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                  <label className="form-label">Email*</label>
                                  <input
                                    {...formik.getFieldProps("contactInfoEmail")}
                                    // value={contactInfoEmail}
                                    // value={formik.values.contactInfoEmail}
                                    className="form-control"
                                    type="text"
                                    placeholder="Email"
                                  // onChange={(e) =>
                                  //   setContactInfoEmail(e.target.value)
                                  // }
                                  // onChange={formik.handleChange('contactInfoEmail')}
                                  />
                                  {formik.touched.contactInfoEmail &&
                                    formik.errors.contactInfoEmail && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoEmail}
                                      </ErrorStyle>
                                    )}
                                </div>
                                <div className="mb-3 col-md-6">
                                  <label className="form-label">Web Url</label>
                                  <input
                                    {...formik.getFieldProps("contactInfoWebUrl")}
                                    // value={contactInfoWebUrl}
                                    className="form-control"
                                    type="text"
                                    placeholder="web url"
                                  // onChange={(e) =>
                                  //   setContactInfoWebUrl(e.target.value)
                                  // }
                                  />
                                  {formik.touched.contactInfoWebUrl &&
                                    formik.errors.contactInfoWebUrl && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoWebUrl}
                                      </ErrorStyle>
                                    )}
                                </div>
                                <div className="mb-3 col-md-12">
                                  <label className="form-label">Address*</label>
                                  <textarea
                                    value={contactInfoAddress}
                                    className="form-control"
                                    rows="3"
                                    maxLength={250}
                                    placeholder="Address"
                                    onChange={(e) =>
                                      handleContactInfoAddressChange(e)
                                    }
                                    // onBlur={handleGetLatLang}
                                    disabled={isAddressDisabled}
                                  ></textarea>
                                  {contactInfoAddressError && <p className="error-mgs text-danger">{contactInfoAddressError}</p>}
                                  {formik.touched.contactInfoAddress &&
                                    formik.errors.contactInfoAddress && (
                                      <ErrorStyle className="error">
                                        {formik.errors.contactInfoAddress}
                                      </ErrorStyle>
                                    )}
                                  <a className="btn btn-info-detail" onClick={handleGetLatLang}>Submit</a>
                                  {latLngError && <p className="text-danger mt-2" style={{fontSize: "12px"}}>{latLngError}</p>}
                                  {isAddressSubmit ? (
                                    <CircularLoader size={40} />
                                  ) : (
                                    addressPlaceId && formik.values.contactInfoAddress && (
                                      <div className="">
                                        <div className="">
                                          <a
                                            href={`https://www.google.com/maps/place/?q=place_id:${addressPlaceId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-link"
                                            style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                          >
                                            Please check & verify your business address on Google Map.
                                          </a>
                                        </div>
                                        <div className="">
                                          {isAddressVerified ? (
                                            <>
                                              <button className="btn btn-success me-2 selectreviewverified" disabled>
                                                Address Verified
                                              </button>
                                              <button className="selectreviewedit" onClick={handleIncorrectAddressData}>
                                                Edit
                                              </button>
                                            </>
                                          ) : (
                                            <button className="btn btn-success me-2 selectreview 
                                              verifybusinesswtd" onClick={handleCorrectAddressData}>
                                              Verify Address
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div className="mb-3 col-md-12">
                                  <label className="form-label">Alternative Address</label>
                                  <textarea
                                    value={contactInfoOptAddress}
                                    className="form-control"
                                    rows="3"
                                    maxLength={250}
                                    placeholder="Address"
                                    onChange={(e) =>
                                      handleContactInfoOptAddressChange(e)
                                    }
                                  ></textarea>
                                  {contactInfoOptAddressError && <p className="error-mgs text-danger">{contactInfoOptAddressError}</p>}
                                </div>
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        {(businessObj?.theme_color == 'theme16' || 'theme1' || 'theme2' || 'theme3' || 'theme4' || 'theme5' || 'theme6' || 'theme7' || 'theme9' || 'theme11' || 'theme15' || 'theme17' || 'theme18') &&
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingZero">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseZero"
                                aria-expanded="false"
                                aria-controls="collapseZero"
                                onClick={(e) => { }}
                              >
                                <div className="d-flex">
                                  <h5 style={{ fontWeight: "400" }} border-0>
                                    Google Review
                                  </h5>
                                </div>
                              </button>

                              <div className="form-check form-switch">
                                <label className="form-check-label">On/Off</label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  onChange={() => toggleDiv("div8")}
                                  checked={onState?.googlereview}
                                  onClick={(e) => {
                                    setIsEnabledGoogleReview(!isEnabledGoogleReview);
                                    setOnState({
                                      ...onState,
                                      googlereview: !onState.googlereview,
                                    });
                                  }}
                                />

                              </div>
                            </h2>
                            <div
                              id="collapseZero"
                              className="accordion-collapse collapse "
                              aria-labelledby="headingZero"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body AddDetailsContactInfoInnerForm">
                                <div className="row MobileDetailsWrapper">
                                  <div className="mb-3 col-md-6 MobilePhoneFieldWrapper">
                                    <input
                                      {...formik.getFieldProps("googlereview")}
                                      className="form-control"
                                      type="text"
                                      placeholder="Business Name"
                                      onBlur={handleBlurReview}
                                      disabled={isVerified}
                                    />
                                    {formik.touched.googlereview && formik.errors.googlereview && (
                                      <ErrorStyle className="error">
                                        {formik.errors.googlereview}
                                      </ErrorStyle>
                                    )}
                                  </div>
                                  <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                                    <input
                                      {...formik.getFieldProps("googleaddress")}
                                      className="form-control"
                                      type="text"
                                      placeholder="City Name"
                                      onChange={handleGoogleAddressChange}
                                      onBlur={handleBlurReview}
                                      disabled={isVerified}
                                    />
                                    {formik.touched.googleaddress && formik.errors.googleaddress && (
                                      <ErrorStyle className="error">
                                        {formik.errors.googleaddress}
                                      </ErrorStyle>
                                    )}
                                  </div>
                                  <div className="mb-3 col-md-3 MobilePhoneFieldWrapper">
                                    <a className="btn selectreviewverifiedbtn" onClick={fetchGoogleRating}>
                                      Submit
                                    </a>
                                  </div>
                                </div>

                                {isAddSubmit ? (
                                  <CircularLoader size={40} />
                                ) : (
                                  tempLink && (
                                    <div className="">
                                      <div className="">
                                        <a
                                          href={`https://www.google.com/maps/place/?q=place_id:${tempLink}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="btn btn-link"
                                          style={{ fontSize: "14px", color: "#00b721", fontWeight: "500", padding: "10px 0px" }}
                                        >
                                          Please check & verify your business on Google Map.
                                        </a>
                                      </div>
                                      <div className="">
                                        {isVerified ? (
                                          <>
                                            <button className="btn btn-success me-2 selectreviewverified" disabled>
                                              Business Verified
                                            </button>
                                            <button className="selectreviewedit" onClick={handleIncorrectData}>
                                              Edit
                                            </button>
                                          </>
                                        ) : (
                                          <button className="btn btn-success me-2 selectreview 
                                          verifybusinesswtd" onClick={handleCorrectData}>
                                            Business Verify
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        }

                        <div className="accordion-item ContactFormPadIssue">
                          <h2 className="accordion-header">
                            <div className="d-flex border-0">
                              <h5 style={{ fontWeight: "400" }} border-0>
                                Contact Form
                              </h5>
                            </div>

                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                On/Off
                              </label>
                              <input
                                onClick={(e) => {
                                  setIsEnabledContactForm(!isEnabledContactForm);
                                  setOnState({
                                    ...onState,
                                    contactForm: !onState.contactForm,
                                  });
                                }}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                // onChange={() => toggleDiv("div1")}
                                checked={onState?.contactForm}
                              />
                            </div>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          ></div>
                        </div>
                        {/* ///SERVICES-----------------services  */}
                        {themeNumber !== 15 || themeNumber == 40 ? (
                        (themeNumber == 7 || themeNumber == 9) ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                onClick={(e) => {
                                }}
                              >
                                <div className="d-flex">
                                  <h5 style={{ fontWeight: "400" }}>Services</h5>
                                </div>
                              </button>
                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                >
                                  On/Off
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.services}
                                  onClick={(e) => {
                                    setIsEnabledServices(!isEnabledServices);
                                    setOnState({
                                      ...onState,
                                      services: !onState.services,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div2")}
                                />
                              </div>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewServicesformwrapper"
                              >
                                <div className="row">
                                  <NewBusinessDetilsCreatesaloon
                                    setServicesArray={setServicesArray}
                                    businessObj={businessObj}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                onClick={(e) => {
                                }}
                              >
                                <div className="d-flex">
                                  <h5 style={{ fontWeight: "400" }}>
                                    {themeNumber == 4 || themeNumber == 18 ? "Specialization" : "Services"}
                                  </h5>
                                </div>
                              </button>
                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                >
                                  On/Off
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.services}
                                  onClick={(e) => {
                                    setIsEnabledServices(!isEnabledServices);
                                    setOnState({
                                      ...onState,
                                      services: !onState.services,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div2")}
                                />
                              </div>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewServicesformwrapper"
                              >
                                <div className="row">
                                  <NewBusinessDetilsCreate
                                    setServicesArray={setServicesArray}
                                    businessObj={businessObj}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        null
                      )}

                        {/* =========testimonial============== */}
                        {themeNumber != 16 && themeNumber != 15 ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                                onClick={() => {
                                  // setOnState({
                                  //   ...onState,
                                  //   testimonials: !onState.testimonials,
                                  // });
                                }}
                              >
                                <div className="d-flex">
                                  <h5 style={{ fontWeight: "400" }}>Testimonials</h5>
                                </div>
                              </button>
                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  On/Off
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.testimonials}
                                  onClick={() => {
                                    setIsTestimonial(!isTestimonial);
                                    setOnState({
                                      ...onState,
                                      testimonials: !onState.testimonials,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div3")}
                                />
                              </div>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewServicesformwrapper"
                              >
                                <div className="row">
                                  <NewTestimonialsCreate
                                    setTestimonialArray={setTestimonialArray}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          null
                        )}

                        {/* =========social=================== */}
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#five"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              onClick={() => {
                                // setIsSocial(!isSocial);
                                // setOnState({
                                //   ...onState,
                                //   socials: !onState.socials,
                                // });
                              }}
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>Social Media</h5>
                              </div>
                            </button>
                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                On/Off
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.socials}
                                onChange={() => toggleDiv("div5")}
                                onClick={() => {
                                  setIsSocial(!isSocial);
                                  setOnState({
                                    ...onState,
                                    socials: !onState.socials,
                                  });
                                }}
                              />
                            </div>
                          </h2>
                          <div
                            id="five"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              id="AddnewServicesformwrapper"
                              style={{ padding: "15px 0" }}
                            >
                              {/* {divStates.div5 && ( */}
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <NewSocialmediaCreate
                                  setSocialMediaData={setSocialMediaData}
                                  socialMediaData={socialMediaData}
                                />
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        {/* =====business hour===== */}
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingsix">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#six"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              onClick={(e) => { }}
                            >
                              <div className="d-flex">
                                <h5 style={{ fontWeight: "400" }}>
                                  Business Hours
                                </h5>
                              </div>
                            </button>
                            <div className="form-check form-switch">
                              <label
                                className="form-check-label"
                              // for="flexSwitchCheckDefault"
                              >
                                On/Off
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={onState?.businessHours}
                                onChange={() => toggleDiv("div6")}
                                onClick={(e) => {
                                  setIsBusinessHourObject(!IsBusinessHourObject);
                                  setIsAppointment(false);
                                  setOnState({
                                    ...onState,
                                    businessHours: !onState.businessHours,
                                    appointment: false,
                                  });
                                }}
                              />
                            </div>
                          </h2>
                          <div
                            id="six"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingsix"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              className="accordion-body"
                              style={{ padding: "10px 0" }}
                            >
                              {/* {divStates.div6 && ( */}
                              <div className="row" style={{ padding: "10px 0" }}>
                                <NewBusinessHour
                                  setAppointmentArray={setAppointmentArray}
                                  appointmentSlotDuration={appointmentSlotDuration}
                                  setAppointmentSlotDuration={
                                    setAppointmentSlotDuration
                                  }
                                  setBusinessHourObject={setBusinessHourObject}
                                />
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>

                        {/* =============appointments============ */}
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFour">
                            <div className="d-flex">
                              <h5 style={{ fontWeight: "400" }}>Appointments</h5>
                              <div class="form-check form-switch">
                                <label
                                  class="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  On/Off
                                </label>
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.appointment}
                                  disabled={!onState?.businessHours}
                                  onChange={() => toggleDiv("div4")}
                                  onClick={() => {
                                    setIsAppointment(!isAppointment);
                                    setOnState({
                                      ...onState,
                                      appointment: !onState?.appointment,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </h2>
                          {/* <div
              id="four"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                {divStates.div4 && (
                <div className="row">
                  <NewAppointmentsCreate
                    businessHourObject={businessHourObject}
                    appointmentSlotDuration={appointmentSlotDuration}
                    businessObj={businessObj}
                    appointmentArray={appointmentArray}
                    setAppointmentArray={setAppointmentArray}
                  />
                </div>
                )}
              </div>
            </div> */}
                        </div>
                        {/* ======gallary=========== */}
                        {themeNumber != 17 ? (
                          <div className="accordion-item">
                            <div className="accordion-header" id="headingseven">
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#seven"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                                onClick={(e) => {
                                  // SetIsGallaryEnable(!isGallaryEnable);
                                  // setOnState({
                                  //   ...onState,
                                  //   gallery: !onState.gallery,
                                  // });
                                }}
                              >
                                <div className="d-flex">
                                  <h5 style={{ fontWeight: "400" }}>Portfolio</h5>
                                </div>
                              </button>
                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                // for="flexSwitchCheckDefault"
                                >
                                  On/Off
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={onState?.gallery}
                                  onClick={(e) => {
                                    SetIsGallaryEnable(!isGallaryEnable);
                                    setOnState({
                                      ...onState,
                                      gallery: !onState.gallery,
                                    });
                                  }}
                                  onChange={() => toggleDiv("div7")}
                                />
                              </div>
                            </div>
                            <div
                              id="seven"
                              className={`accordion-collapse collapse`}
                              aria-labelledby="headingseven"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                id="AddnewGallaryformwrapper"
                              >
                                <div className="row">
                                  <NewGallerycreate
                                    businessId={businessId}
                                    existingGalleryImages={existingGalleryImages}
                                    setExistingGalleryImages={
                                      setExistingGalleryImages
                                    }
                                    gallaryObject={gallaryObject}
                                    galleryData={galleryData}
                                    setGalleryData={setGalleryData}
                                    galleryView={galleryView}
                                    setGalleryView={setGalleryView}
                                    setGallaryObject={setGallaryObject}
                                    isGallaryEnable={isGallaryEnable}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {themeNumber == 53 && (
                           
                      <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => { }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Products</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label className="form-check-label">On/Off</label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.miniproduct}
                            onClick={() => {
                              setIsMinimktProduct(!isMinimktProduct);
                              setOnState((prevState) => ({
                                ...prevState,
                                miniproduct: !prevState.miniproduct,
                              }));
                            }}
                            onChange={() => toggleDiv("div9")}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                        >
                          <div className="row">
                            <NewminimarketProductCreate
                              setMiniMarketArray={setMiniMarketArray}
                              servicesArray={servicesArray}
                              handleFetchById={handleFetchById}
                              handleSubmitWithoutTabChange={handleSubmitWithoutTabChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                        )}


                       

                        {formik.errors.is_allow_in_search && formik.touched.is_allow_in_search && (
                          <div style={{ color: "red", fontSize: "12px" }}>{formik.errors.is_allow_in_search}</div>
                        )}
                      </div>

                      <div className="row Adddetails_saveprev_ButtonWrapper">
                        <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                          <button
                            className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                            id="changeblockprenxtbtn"
                            type="button"
                            onClick={handlePreviousClick}
                          >
                            Previous
                          </button>
                          {/* </div>
            <div className="Changeblock_saveandpreview_buttonWrapper"> */}
                          {isSubmit ? (
                            <CircularLoader size={40} />
                          ) : (
                            <button
                              type="button"
                              className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                              id="changeblockprenxtbtn"
                              onClick={(e) => handleSubmit(e)}
                            >
                              Save & Next
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )
            )}
          </div>

          <div className="ChangeBlockView_Container">
            <ViewThem
              mobilePreviewLoading={mobilePreviewLoading}
              isLoadingSubmit={isLoadingSubmit}
              handleSubmit={handleSubmitWithoutTabChange}
              activeTab={activeTab}
              existingGalleryImages={existingGalleryImages}
              galleryView={galleryView}
              businessObj={businessObj}
              title={title}
              isDine={isDine}
              isShowBusinessName={isShowBusinessName}
              isShowAddress={isShowAddress}
              themeColor={themeColor}
              themeNumber={themeNumber}
              designation={formik.values.designation}
              googlereview={formik.values.googlereview}
              subTitle={formik.values.subTitle}
              description={formik.values.description}
              bannerImageUrl={bannerImageUrl}
              logoImageUrl={logoImageUrl}
              contactInfo={{
                contactInfoOptAddress,
                contactInfoAddress,
                contactInfoEmail: formik.values.contactInfoEmail,
                contactInfoPhone: formik.values.contactInfoPhone,
                optionalContactInfoPhone: formik.values.optionalContactInfoPhone,
                contactInfoWebUrl: formik.values.contactInfoWebUrl,
              }}
              businessHourObject={businessHourObject}
              socialMediaData={socialMediaData}
              termsConditionData={termsConditionData}
              servicesArray={servicesArray}
              testimonialArray={testimonialArray}
              restaurantArray={restaurantArray}
              minimarketArray={minimarketArray}
              cabArray={cabArray}
              gallaryObject={gallaryObject}
              galleryData={galleryData}
              onState={onState}
              appointmentArray={appointmentArray}
              rating={rating}
              reviews={reviews}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
      <Modal sx={modalStyle} open={modalOpen}

      >

        <Box sx={boxStyle}>

          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 15}
            rotate={0}
          />

          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />

          <Box
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
              flexDirection: "column"
            }}
          >
            <div style={{ color: "red", fontWeight: "500", margin: "5px 10px 10px 10px", fontSize: "14px", }}> Please use the zoom in/out option for better visibility of the logo.</div>
            <Button
              size="small"
              sx={{
                marginBottom: "10px",
                color: "white",
                borderColor: "white",
              }}
              variant="outlined"
              onClick={(e) => setModalOpen(false)}
            >
              cancel
            </Button>
            <Button
              sx={{ background: "#5596e6" }}
              size="small"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Details;
